@keyframes popupOpen {
	0% {
		transform: scale(1) translateY(0px);
		opacity: 0;
		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
	1% {
		transform: scale(0.96) translateY(10px);
		opacity: 0;
		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
	100% {
		transform: scale(1) translateY(0px);
		opacity: 1;
		box-shadow: 0 0 500px rgba(241, 241, 241, 0);
	}
}

@keyframes popupClose {
	0% {
		transform: scale(1) translateY(0px);
		opacity: 1;
		box-shadow: 0 0 500px rgba(241, 241, 241, 0);
	}
	1% {
		transform: scale(1) translateY(0px);
		opacity: 1;
		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
	100% {
		transform: scale(0.96) translateY(0px);
		opacity: 0;
		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
}

.popup-content {
	padding: 15px;
	animation: popupOpen 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

	&.is-closing {
		animation: popupClose 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
	}
}

.popup {
	padding: 24px;
	width: 100%;
	border-radius: 6px;
	animation: transition 300ms ease-in-out forwards;
	background-color: var(--Grayscale-Gray-80);
	box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.8);

	@media only screen and (min-width: 576px) {
		width: 414px;
	}

	&--width-480 {
		@media only screen and (min-width: 576px) {
			width: 480px;
		}
	}

	&--width-520 {
		@media only screen and (min-width: 576px) {
			width: 520px;
		}
	}
	&--width-660 {
		width: 100%;

		@media only screen and (min-width: 768px) {
			width: 660px;
		}
	}

	&--support {
		width: 100%;
		border-radius: 16px;
		background: var(--Grayscale-Gray-90);

		@media only screen and (min-width: 768px) {
			width: 656px;
		}
	}

	&__close-btn {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.2224 6.90878C18.5348 6.59636 18.5348 6.08982 18.2224 5.77741C17.9099 5.46499 17.4034 5.46499 17.091 5.77741L11.9999 10.8685L6.90891 5.77743C6.59649 5.46501 6.08996 5.46501 5.77754 5.77743C5.46512 6.08985 5.46512 6.59638 5.77754 6.9088L10.8686 11.9998L5.77728 17.0911C5.46486 17.4035 5.46486 17.9101 5.77728 18.2225C6.0897 18.5349 6.59623 18.5349 6.90865 18.2225L11.9999 13.1312L17.0912 18.2225C17.4037 18.5349 17.9102 18.5349 18.2226 18.2225C18.535 17.9101 18.535 17.4036 18.2226 17.0911L13.1313 11.9998L18.2224 6.90878Z' fill='%23626A85'/%3E%3C/svg%3E");
		background-position: center;
		background-repeat: no-repeat;
		display: block;
		width: 24px;
		height: 24px;
		position: absolute;
		top: 16px;
		right: 16px;
		cursor: pointer;
	}

	.popup-header {
		&--mb-20 {
			margin-bottom: 20px;
		}

		&__title {
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 28px; /* 140% */
			color: var(--Grayscale-White);

			&.red {
				color: var(--additional-error);
			}

			&.success {
				color: var(--Grayscale-White);
				text-align: center;
				font-size: 32px;
				font-style: normal;
				font-weight: 600;
				line-height: 40px; /* 125% */
			}

			+ .popup-header__title {
				color: var(--Grayscale-White);
				text-align: center;
				font-size: 32px;
				font-style: normal;
				font-weight: 600;
				line-height: 40px; /* 125% */
				margin-top: 32px;
			}
		}
	}

	.popup-body {
		margin-top: 24px;
		width: 100%;
	}

	.popup-icon {
		margin: 16px 0;
		display: flex;
		justify-content: center;
		text-align: center;

		&--type2 {
			margin: 0 0 20px 0;
		}

		&--error {
			position: relative;
			margin-bottom: 16px;
		}
	}

	.popup-img {
		margin: 0 0 20px 0;
	}

	.popup-text {
		color: var(--Grayscale-White);
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		display: flex;
		justify-content: center;

		&--gray {
			color: var(--Grayscale-Gray-30);
		}

		&.popup-text--type2 {
			color: var(--Grayscale-White);
			text-align: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 300;
			line-height: 24px;

			p {
				max-width: 380px;
			}
		}

		p {
			max-width: 270px;
		}

		a,
		a.link {
			color: var(--primary-blue-400) !important;
		}

		&--lg {
			color: var(--Grayscale-White);
			text-align: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px; /* 150% */
		}
	}

	.popup-submit {
		margin-top: 16px;
		gap: 10px;

		&--column {
			flex-direction: column;
		}
	}
	.select-block__name {
		color: var(--Grayscale-Gray-20);
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		margin-bottom: 0;
	}

	.loader {
		width: 40px;
		height: 40px;
		border: 5px solid var(--Grayscale-Gray-20);
		border-bottom-color: transparent;
		border-radius: 50%;
		display: inline-block;
		box-sizing: border-box;
		animation: rotation 1s linear infinite;
	}

	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

@keyframes transition {
	0% {
		opacity: 0;
	}
	1% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
