.table {
	color: var(--gray800);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	&--fiat-balance {
		.tr {
			grid-template-columns: 1.3fr 0.9fr 0.9fr 0.9fr 0.9fr 1fr;

			@media only screen and (max-width: 1080px) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				align-items: stretch;
			}
		}

		.td {
			padding: 16px 8px;

			&__wrap {
				padding: 0 10px;
			}

			&__text {
				font-weight: 400;
				font-size: 16px;
				line-height: 150%;
				color: var(--Grayscale-White);
			}

			&__more {
				font-weight: 400;
				font-size: 14px;
				line-height: 143%;
				color: var(--Grayscale-Gray-20);
				margin-top: 2px;
			}

			&--timestamp-date {
				color: var(--gray800);
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			&--timestamp-time {
				color: var(--text-secondary);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-top: 3px;
			}

			p {
				color: var(--gray800);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
		.td-sorting-arrow {
			height: 10px;
			&__item {
				margin-bottom: 0;
			}
		}
		.td-sorting-arrow-button {
			width: 100%;
			display: flex;
			align-items: center;
			color: var(--Grayscale-Gray-20);
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			padding: 8px 0;
		}

		.td-sorting-arrow__item.active svg path {
			fill: var(--Grayscale-White);
		}
		.table-header {
			padding: 0 24px;

			.tr {
				border-bottom: 1px solid var(--Grayscale-Gray-70);
			}
		}

		.table-body {
			padding: 0 24px 16px 24px;
			.td {
				padding: 8px 0px;
			}
			.tr {
				align-items: center;

				@media only screen and (min-width: 1080px) {
					border-bottom: 1px solid var(--Grayscale-Gray-70);
				}

				&:nth-child(2n) {
					background: transparent;
				}
			}
		}
	}

	&--wallet {
		.tr {
			align-items: center;

			.coin {
				margin-top: 0;
			}
		}

		.table-header {
			.tr {
				.td {
					@media only screen and (min-width: 1080px) {
						padding-top: 24px;
						padding-bottom: 6px;
					}
				}
			}
		}
	}

	&--transaction-history-trade,
	&--transaction-history-fiat,
	&--transaction-history-crypto {
		.td__text a {
			color: var(--Blue-Blue-Light);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
		}
		.table-footer {
			padding: 8px 0;
			border-top: 1px solid var(--Grayscale-Gray-70);

			@media only screen and (max-width: 767.98px) {
				flex-direction: column;
				gap: 16px;
			}

			.select-page {
				.select--per-page-wrapper {
					font-weight: 400;
					font-size: 14px;
					line-height: 143%;
					color: var(--Grayscale-Gray-20);

					.select {
						width: 61px;
						height: 32px;
						border-radius: 4px;
						border: 1px solid var(--Grayscale-Gray-80);
						background: var(--Grayscale-Gray-100);

						&.active {
							border: 1px solid var(--Grayscale-Gray-10);

							.select__current-arrow {
								transform: rotate(180deg);
							}
						}
					}

					.select__current {
						padding: 6px 8px;
						font-weight: 400;
						font-size: 14px;
						line-height: 143%;
						color: var(--Grayscale-Gray-20);
					}

					.select__current-arrow {
						margin-left: 0;
					}

					.select__drop {
						top: unset;
						left: 0;
						min-width: 100px;
						bottom: calc(100% + 5px);
						border-radius: 4px;
						border: 1px solid var(--Grayscale-Gray-10);
						background: var(--Grayscale-Gray-100);
						box-shadow: 0 4px 15px 0 rgba(33, 44, 99, 0.09);
						padding: 2px;

						ul > li > button {
							padding: 5px 10px;
							border-radius: 4px;
							font-family: var(--font-family);
							font-weight: 400;
							font-size: 14px;
							line-height: 143%;
							color: var(--Grayscale-Gray-20);
							background: transparent;
							&:hover,
							&.active {
								background: var(--Grayscale-Gray-70);
								font-weight: 500;
							}
						}
					}
				}
			}

			.pagination-block {
				padding: 0;
				border: 0;
			}

			.button-wrap {
				display: flex;
				gap: 8px;

				button {
					margin-right: 0;
				}
			}
		}
	}

	&--transaction-history-trade {
		padding: 16px 24px 16px 24px;

		@media only screen and (max-width: 1080px) {
			padding: 16px 12px;
		}

		.table-header {
			.DatePicker {
				display: flex;
				flex-direction: row;
				align-items: center;

				.select-block__name {
					margin-bottom: 0;
					font-weight: 400;
					font-size: 14px;
					line-height: 143%;
					color: var(--Grayscale-Gray-20);
					position: absolute;
					top: 50%;
					left: 10px;
					transform: translateY(-50%);
					z-index: 1;
				}

				.datepicker-input-wrapper {
					width: 100%;
				}

				.react-datepicker__input-container {
					input {
						height: 32px;
						border-radius: 6px;
						border: 1px solid var(--Grey70);
						background: #0d0f0e;
						background: var(--Black);
						padding: 6px 10px 6px 85px;
						font-weight: 400;
						font-size: 14px !important;
						line-height: 143%;
						color: var(--Grayscale-White);
					}
				}

				.select__current-date-icon {
					position: absolute;
					top: 50%;
					right: 10px;
					transform: translateY(-50%);
					background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 8.833a.5.5 0 100 1h4a.5.5 0 100-1H6zM6 11.167a.5.5 0 100 1h4a.5.5 0 000-1H6z' fill='%23626A85'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.5 1.333a.5.5 0 00-1 0V2.5H3A1.5 1.5 0 001.5 4v10A1.5 1.5 0 003 15.5h10a1.5 1.5 0 001.5-1.5V4A1.5 1.5 0 0013 2.5h-2.5V1.333a.5.5 0 00-1 0V2.5h-3V1.333zM5.528 3.5a.5.5 0 00.943 0h3.057a.5.5 0 00.943 0H13a.5.5 0 01.5.5v2.167h-11V4a.5.5 0 01.5-.5h2.528zM2.5 7.167V14a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V7.167h-11z' fill='%23626A85'/%3E%3C/svg%3E");
					background-position: 50%;
					background-repeat: no-repeat;
					width: 20px;
					min-width: 20px;
					height: 20px;
					min-height: 20px;
				}

				.react-datepicker-popper {
					min-width: 265px;
				}

				.react-datepicker {
					display: flex;
					flex-direction: column-reverse;
				}

				.react-datepicker__navigation {
					top: 42px;
				}
			}
		}

		.tr {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;

			@media only screen and (max-width: 1080px) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				align-items: stretch;
			}
		}

		.td {
			padding: 8px 14px 12px 14px;

			&:first-child {
				@media only screen and (min-width: 1080px) {
					padding-left: 0;
				}
			}

			&:last-child {
				@media only screen and (min-width: 1080px) {
					padding-right: 0;
				}
			}

			&:nth-of-type(5),
			&:nth-of-type(6),
			&:nth-of-type(7),
			&:nth-of-type(8) {
				@media only screen and (min-width: 1080px) {
					text-align: right;
				}

				.td-sorting-arrow-button {
					@media only screen and (min-width: 1080px) {
						justify-content: flex-end;
					}
				}
			}

			&.td-hidden-name {
				color: var(--Grayscale-White);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px; /* 150% */
			}

			&__wrap {
				padding: 0;
			}

			&__text {
				color: var(--Grayscale-White);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px; /* 150% */
			}

			&__more {
				color: var(--Grayscale-Gray-20);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
			}

			&--timestamp-date {
				color: var(--gray800);
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			&--timestamp-time {
				color: var(--text-secondary);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-top: 3px;
			}

			p {
				color: var(--gray800);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}

		.td-sorting-arrow-button {
			width: 100%;
			display: flex;
			align-items: center;
			color: var(--Grayscale-Gray-20);
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 20px; /* 142.857% */
			padding: 0;
			border-radius: 0;
			background: transparent;
		}

		.td-sorting-arrow__item.active svg path {
			fill: var(--blue400);
		}

		.table-header {
			.tr {
				border-bottom: 0;

				.td {
					padding: 6px 14px 14px 14px;
					color: var(--Grayscale-Gray-20);
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					line-height: 20px; /* 142.857% */

					&:first-child {
						@media only screen and (min-width: 1080px) {
							padding-left: 0;
						}
					}

					&:last-child {
						@media only screen and (min-width: 1080px) {
							padding-right: 0;
						}
					}

					> p {
						color: var(--Grayscale-Gray-20);
						font-size: 14px;
						font-style: normal;
						font-weight: 700;
						line-height: 20px; /* 142.857% */
					}
				}
			}
		}

		.table-body {
			.tr {
				align-items: stretch;
				background: transparent;
				border-bottom: 1px solid var(--Grey70);

				/*&:not(:last-of-type) {

					@media only screen and (min-width: 1080px) {
						border-bottom: 1px solid var(--gray400);
					}
				}*/

				&:nth-child(2n) {
					background: transparent;
				}
			}
		}
	}

	&--transaction-history-crypto {
		.tr {
			grid-template-columns: 1.1fr 1fr 1fr 1fr 0.9fr 1.2fr 1.7fr 1fr 1.4fr;

			@media only screen and (max-width: 1080px) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				align-items: stretch;
			}
		}

		.td {
			padding: 16px 8px;

			&:first-child {
				@media only screen and (min-width: 1080px) {
					padding-left: 16px;
				}
			}

			&:last-child {
				@media only screen and (min-width: 1080px) {
					padding-right: 16px;
				}
			}
			&--left {
				text-align: start;
			}

			&__wrap {
				padding: 0 10px;
			}

			&__text {
				font-family: var(--font-family);
				font-weight: 400;
				font-size: 16px;
				line-height: 150%;
				color: var(--Grayscale-White);
			}

			&__more {
				font-weight: 400;
				font-size: 14px;
				line-height: 143%;
				color: var(--Grayscale-Gray-20);
				margin-top: 2px;
			}

			&__link {
				display: inline-block;
				font-weight: 400;
				font-size: 14px;
				line-height: 143%;
				color: var(--Blue-Blue-Light);
				&:hover {
					color: var(--Blue-Blue-Dark);
				}
			}

			&__status {
				padding: 4px 6px;
				font-weight: 400;
				font-size: 16px;
				line-height: 150%;
				text-align: left;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				white-space: nowrap;

				&.yellow {
					color: var(--additional-warning);
				}

				&.red {
					color: var(--additional-error);
				}

				&.green {
					color: var(--additional-green);
				}

				&.blue {
					color: var(--Blue-Blue-Light);
				}
				&.purple {
					color: var(--Purple-Purle-Light);
					text-align: center;
					white-space: normal;
				}
			}

			p {
				color: var(--gray800);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}

		.td-sorting-arrow-button {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-weight: 700;
			font-size: 14px;
			line-height: 143%;
			color: var(--Grayscale-Gray-20);
			padding: 8px 10px;
		}

		.td-sorting-arrow__item.active svg path {
			fill: var(--blue400);
		}

		.table-header {
			.tr {
				border-bottom: 1px solid var(--Grayscale-Gray-70);
				.td {
					font-weight: 700;
					font-size: 14px;
					line-height: 143%;
					color: var(--Grayscale-Gray-20);

					> p {
						padding: 2px 10px;
						height: 34px;
						display: flex;
						align-items: center;
						color: var(--Grayscale-Gray-20);
						font-size: 14px;
						font-style: normal;
						font-weight: 700;
						line-height: 143%;
					}
				}
			}
		}

		.table-body {
			.tr {
				align-items: stretch;

				&:not(:last-of-type) {
					@media only screen and (min-width: 1080px) {
						border-bottom: 1px solid var(--Grayscale-Gray-70);
					}
				}

				&:nth-child(2n) {
					background: transparent;
				}
			}
		}

		.copy-button {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			gap: 2px;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			color: var(--Grayscale-White);
			&-info {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 8px;
			}
			&-wrap {
				display: flex;
				align-items: center;

				flex-direction: row;
				gap: 4px;
				span {
					font-weight: 400;
					font-size: 14px;
					line-height: 143%;
					color: var(--Grayscale-Gray-20);
					&:hover {
						color: var(--Grayscale-Gray-10);
					}
				}
			}
		}
		.table-footer {
			padding: 8px 24px;
		}
	}

	&--transaction-history-fiat {
		.tr {
			grid-template-columns: 0.8fr 1fr 0.8fr 0.8fr 0.8fr 1.4fr 0.7fr 0.8fr;

			@media only screen and (max-width: 1080px) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				align-items: stretch;
			}
		}

		.td {
			padding: 16px 8px;

			&:first-child {
				@media only screen and (min-width: 1080px) {
					padding-left: 16px;
				}
			}

			&:last-child {
				@media only screen and (min-width: 1080px) {
					padding-right: 16px;
				}
			}

			&__wrap {
				padding: 0 10px;
			}

			&__text {
				color: var(--greyscale-grey-800);
				font-weight: 400;
				font-size: 16px;
				line-height: 150%;
				color: var(--Grayscale-White);
			}

			&__more {
				font-weight: 400;
				font-size: 14px;
				line-height: 143%;
				color: var(--Grayscale-Gray-20);
				margin-top: 2px;
			}

			&__link {
				display: inline-block;
				color: var(--primary-blue-400);
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			&__status {
				padding: 4px 2px;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;

				&.yellow {
					color: var(--additional-warning);
				}

				&.red {
					color: var(--additional-error);
				}

				&.green {
					color: var(--additional-green);
				}
			}

			p {
				color: var(--gray800);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}

		.td-sorting-arrow-button {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: var(--Grayscale-Gray-20);
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			padding: 8px 10px;
		}

		.td-sorting-arrow__item.active svg path {
			fill: var(--blue400);
		}

		.table-header {
			.tr {
				border-bottom: 1px solid var(--Grayscale-Gray-70);

				.td {
					font-weight: 700;
					font-size: 14px;
					line-height: 143%;
					color: var(--Grayscale-Gray-20);

					> p {
						border-radius: 6px;
						padding: 2px 10px;
						height: 34px;
						display: flex;
						align-items: center;
						color: var(--Grayscale-Gray-20);
						font-size: 14px;
						font-style: normal;
						font-weight: 700;
						line-height: 143%;
					}
				}
			}
		}

		.table-body {
			.tr {
				align-items: stretch;

				&:not(:last-of-type) {
					@media only screen and (min-width: 1080px) {
						border-bottom: 1px solid var(--Grayscale-Gray-70);
					}
				}

				&:nth-child(2n) {
					background: transparent;
				}
			}
		}

		.copy-button {
			display: flex;
			align-items: center;
			flex-direction: row;
			gap: 8px;
			color: var(--greyscale-grey-800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 160%; /* 22.4px */
		}
		.table-footer {
			padding: 8px 24px;
		}
	}

	.table-body {
		> div:not(:last-child) {
			.tr {
				border-bottom: 1px solid var(--gray100);
			}
		}
	}
	.td__no-data {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		color: #949494;
		margin: 40px 0;
	}
}

