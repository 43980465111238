.referrals-table {
	display: flex;
	flex-direction: column;
	gap: 16px;

	&__item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px 0;
		border-bottom: 1px solid var(--Grayscale-Gray-70);

		&-right {
			display: flex;
			align-items: center;
			gap: 32px;

			p {
				color: var(--Grayscale-White);
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: 24px;
			}
		}
	}
}
