.input-file {
	padding: 32px;
	border-radius: 6px;
	border: 1px dashed var(--Grayscale-Gray-20);
	background: var(--Grayscale-Gray-100);

	margin-bottom: 30px;
	&.active {
		border: 1px dashed var(--Grayscale-Gray-10);

		background: var(--Grayscale-Gray-90);
	}
	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		cursor: pointer;
	}
	&__disabled {
		pointer-events: none;
	}

	&.drop-file-preview__item {
		img {
			width: 32px;
		}
	}
	&__button-content {
		height: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 16px;
		color: var(--Grayscale-White);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%;
		background: transparent;
		border-radius: 0;

		b {
			color: var(--Blue-Blue-Light);
			font-weight: 400;
		}
	}
}

.input-file-preview {
	margin-bottom: 16px;
	display: flex;
	flex-flow: row wrap;
	grid-gap: 8px;
	gap: 8px;
	&__item {
		position: relative;
		margin-right: 0;
		border-radius: 6px;
		border: 1px solid var(--Grayscale-Gray-10);

		width: 72px;
		min-width: 72px;
		height: 91px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		padding: 8px;
		gap: 8px;
		img {
			width: 24px;
		}
		cursor: pointer;
	}

	&__name {
		color: var(--Grayscale-Gray-20);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
		width: 52px;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-top: 8px;
		text-wrap: nowrap;
	}
	&__item__del {
		background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.2224 6.90865C18.5348 6.59623 18.5348 6.0897 18.2224 5.77728C17.9099 5.46486 17.4034 5.46486 17.091 5.77728L11.9999 10.8683L6.90891 5.77731C6.59649 5.46489 6.08996 5.46489 5.77754 5.77731C5.46512 6.08973 5.46512 6.59626 5.77754 6.90868L10.8686 11.9997L5.77728 17.091C5.46486 17.4034 5.46486 17.9099 5.77728 18.2224C6.0897 18.5348 6.59623 18.5348 6.90865 18.2224L11.9999 13.1311L17.0912 18.2224C17.4037 18.5348 17.9102 18.5348 18.2226 18.2224C18.535 17.91 18.535 17.4034 18.2226 17.091L13.1313 11.9997L18.2224 6.90865Z' fill='%23626A85'/%3E%3C/svg%3E%0A");
		width: 24px;
		min-width: 24px;
		height: 24px;
		min-height: 24px;
		transform: translate(0, 0);
		top: 0px;
		right: 0px;
		position: absolute;
		cursor: pointer;
		svg {
			display: none;
		}
	}
	&__download {
		cursor: pointer;
	}
}

