.settings-2fa-app {
	margin-bottom: 0;
	gap: 16px;
	display: flex;
	flex-flow: row wrap;

	&__item {
		max-width: 136px;
		display: block;

		img {
			width: 100%;
			height: auto;
			display: block;
		}
	}
}
