.instant-exchange-confirm-list-note {
	border-radius: 6px;
	background: var(--Grayscale-Gray-30);
	margin-bottom: 20px;
	padding: 8px 16px;
	display: flex;
	align-items: flex-start;
	gap: 8px;
	color: var(--Grayscale-White);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;

	&:before {
		content: '';
		display: block;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.9998 2.70005C6.86356 2.70005 2.6998 6.8638 2.6998 12C2.6998 17.1363 6.86356 21.3 11.9998 21.3C17.1361 21.3 21.2998 17.1363 21.2998 12C21.2998 6.8638 17.1361 2.70005 11.9998 2.70005ZM1.2998 12C1.2998 6.0906 6.09036 1.30005 11.9998 1.30005C17.9093 1.30005 22.6998 6.0906 22.6998 12C22.6998 17.9095 17.9093 22.7001 11.9998 22.7001C6.09036 22.7001 1.2998 17.9095 1.2998 12ZM12.0002 5.20007C12.442 5.20007 12.8002 5.55825 12.8002 6.00007V15.0001C12.8002 15.4419 12.442 15.8001 12.0002 15.8001C11.5584 15.8001 11.2002 15.4419 11.2002 15.0001V6.00007C11.2002 5.55825 11.5584 5.20007 12.0002 5.20007ZM12.8002 18.0001C12.8002 17.5582 12.442 17.2001 12.0002 17.2001C11.5584 17.2001 11.2002 17.5582 11.2002 18.0001V18.5001C11.2002 18.9419 11.5584 19.3001 12.0002 19.3001C12.442 19.3001 12.8002 18.9419 12.8002 18.5001V18.0001Z' fill='%238D99C5'/%3E%3C/svg%3E");
		width: 24px;
		min-width: 24px;
		height: 24px;
		min-height: 24px;
	}

	span {
		font-weight: 700;
	}
}
