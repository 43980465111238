.verification-upload-info {
	p {
		margin-bottom: 20px;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: var(--Grayscale-White);
	}

	ul {
		display: block;
		padding: 0;
		margin: 0;
		list-style-type: none;

		li {
			color: var(--Grayscale-Gray-20);

			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 25.6px */
			position: relative;
			padding-left: 32px;

			&:before {
				content: '';
				display: block;
				background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='4' r='4' fill='%235974FF' /%3E%3C/svg%3E");
				background-repeat: no-repeat;
				background-position: center;
				width: 24px;
				min-width: 24px;
				height: 24px;
				min-height: 24px;
				position: absolute;
				top: 1px;
				left: 0;
			}

			&:not(:last-of-type) {
				margin-bottom: 4px;
			}
		}
	}
}

