.payment-methods-body-plug {
	margin: 52px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
	color: #949494;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-align: center;
}

