.react-datepicker-popper[data-placement^='bottom'] {
	padding-top: 5px;
	top: 100% !important;
	transform: none !important;
	width: 100%;
}

.react-datepicker {
	border-radius: 4px;
	border: 1px solid var(--Grayscale-Gray-10);
	background: var(--Grayscale-Gray-100);
	font-family: var(--font-sans) !important;
	font-size: 12px;
	color: var(--Grayscale-Gray-100);
	box-shadow: 0 0 2px rgba(40, 41, 61, 0.04), 0 4px 8px rgba(96, 97, 112, 0.16);
	position: relative;
	padding: 8px;
	width: 100%;
	display: inline-block;

	&__current-month {
		color: var(--Grayscale-White);
		background: var(--Grayscale-Gray-100);
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 22.4px */
		margin-bottom: 14px;
	}

	&__navigation {
		border-radius: 6px;
		background: var(--Blue-Blue-Brand);
		width: 28px;
		min-width: 28px;
		height: 28px;
		min-height: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 5px;

		&--previous {
			left: 6px;
		}

		&--next {
			right: 6px;
		}
	}

	&__navigation-icon {
		background-size: 20px 20px;
		background-position: center;
		background-repeat: no-repeat;
		background-color: transparent;
		width: 20px;
		min-width: 20px;
		height: 20px;
		min-height: 20px;
		display: block;

		&--previous {
			background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.1399 12.48C7.8199 12.24 7.8199 11.76 8.1399 11.52L12.5399 8.21997C12.9354 7.92331 13.4999 8.20554 13.4999 8.69997L13.4999 15.3C13.4999 15.7944 12.9354 16.0766 12.5399 15.78L8.1399 12.48Z' fill='white' /%3E%3C/svg%3E");
		}

		&--next {
			background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.86 11.52C16.18 11.76 16.18 12.24 15.86 12.48L11.46 15.78C11.0645 16.0767 10.5 15.7945 10.5 15.3L10.5 8.70003C10.5 8.2056 11.0645 7.92337 11.46 8.22003L15.86 11.52Z' fill='white' /%3E%3C/svg%3E");
			position: static;
		}
	}

	&__month-container {
		float: none;
	}

	&__day-names {
		display: none;
	}

	&__month {
		margin: 0;
	}

	&__week {
		display: flex;
		justify-content: space-between;
		//gap: 8px;

		&:not(:last-of-type) {
			margin-bottom: 8px;
		}
	}

	&__day {
		width: 28px;
		height: 28px;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		background: var(--Grayscale-Gray-30);
		color: var(--Grayscale-Gray-100);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 19.2px */

		&:hover {
			border-radius: 4px;
			background: var(--Blue-Blue-Light);
			color: var(--Grayscale-White);
		}

		&--disabled,
		&--outside-month {
			background: var(--Grayscale-Gray-70);
			color: var(--Grayscale-Gray-10);

			&:hover {
				background: var(--Grayscale-Gray-10);
				color: var(--Grayscale-Gray-70);
			}
		}
	}

	&__day--range-start {
		background: var(--Blue-Blue-Brand) !important;
		color: var(--Grayscale-White) !important;

		&.react-datepicker__day--in-range {
			border-radius: 4px !important;
		}
	}

	&__day--range-end {
		background: var(--Blue-Blue-Brand) !important;
		color: var(--Grayscale-White) !important;

		&.react-datepicker__day--in-range {
			border-radius: 4px !important;
		}
	}

	&__day--in-range {
		background: var(--Blue-Blue-Dark) !important;
		color: var(--Grayscale-White) !important;

		+ .react-datepicker__day--in-range:before {
			display: none;
		}
	}

	&__helpers {
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__helper-input {
		width: 108px;
		height: 28px !important;
		border-radius: 6px;
		background: var(--Grayscale-Gray-80);

		border: 1px var(--Grayscale-Gray-10);
		text-align: center;
		color: var(--Grayscale-White);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		&::placeholder {
			opacity: 1;
			color: var(--greyscale-grey-200);
			font-size: 12px;
			font-style: normal;
			font-weight: 300;
			line-height: normal;
		}
	}

	&__helper-arrow {
		display: block;
		background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.25 12.001C3.25 11.5868 3.58579 11.251 4 11.251L19.0309 11.251C18.9655 11.1444 18.8813 11.0261 18.7734 10.8917C18.4413 10.4782 17.9505 9.99011 17.235 9.28098L15.472 7.53367C15.1778 7.24208 15.1757 6.76722 15.4673 6.47302C15.7589 6.17882 16.2338 6.1767 16.528 6.46829L18.3227 8.24707C18.9987 8.91702 19.5511 9.46456 19.9429 9.95244C20.3504 10.4598 20.6453 10.9747 20.7241 11.5928C20.7586 11.8638 20.7586 12.1381 20.7241 12.4091C20.6453 13.0273 20.3504 13.5422 19.9429 14.0495C19.5511 14.5374 18.9987 15.0849 18.3227 15.7549L16.528 17.5337C16.2338 17.8253 15.7589 17.8231 15.4673 17.5289C15.1757 17.2347 15.1778 16.7599 15.472 16.4683L17.235 14.721C17.9505 14.0118 18.4413 13.5238 18.7734 13.1103C18.8813 12.9759 18.9655 12.8576 19.0309 12.751L4 12.751C3.58579 12.751 3.25 12.4152 3.25 12.001Z' fill='%232E2DED' /%3E%3C/svg%3E");
		background-position: center;
		background-repeat: no-repeat;
		width: 20px;
		min-width: 20px;
		height: 20px;
		min-height: 20px;
		background-size: 20px 20px;
	}
}

