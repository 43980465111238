.trade {
	&__row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 24px;
		padding: 0;

		@media only screen and (max-width: 992px) {
			grid-template-columns: repeat(1, 1fr);
			gap: 30px;
		}
	}

	&__box-heading {
		color: var(--Grayscale-White);
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px; /* 150% */
		margin-bottom: 8px;
	}
}
