.wallet-operations-item {
	max-width: 100%;

	+ .wallet-operations-item {
		margin-top: 18px;
	}

	&.relative {
		position: relative;
	}

	&--border-top {
		padding-top: 16px;
		border-top: 1px solid var(--Grayscale-Gray-70);
	}

	&__title {
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: var(--Grayscale-White);
		margin-bottom: 12px;

		span {
			width: 26px;
			height: 26px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 6px;
			background: var(--Grayscale-Gray-30);
			border: 0;
			font-weight: 400;
			font-size: 12px;
			line-height: 133%;
			text-align: center;
			color: var(--Grayscale-Gray-100);
		}
	}

	&:not(.wallet-operations-item--popup) {
		.select-block {
			margin-bottom: 0;
		}

		.select {
			border-radius: 4px;
			border: 0;
			background: var(--Grayscale-Gray-100);
			height: 48px;

			&.active {
				.select__current-arrow {
					transform: rotate(180deg);
				}
			}
			.coin__text-more {
				font-weight: 400;
				font-size: 14px;
				line-height: 143%;
				color: var(--Grayscale-White);
			}
			.coin__text {
				font-weight: 400;
				font-size: 14px;
				line-height: 143%;
				color: var(--Grayscale-Gray-20);
				&--white {
					color: var(--Grayscale-White);
				}
			}
			.coin__text-bold {
				font-weight: 700;
			}
		}
	}

	.input-double-item {
		position: relative;
		display: block;

		.input-item {
			border-radius: 4px;
			height: 48px;
			padding: 20px 16px;
			border: 0;
			background: var(--Grayscale-Gray-100);
		}

		.input-item-info {
			display: flex;
			justify-content: center;
			align-items: center;
			background: var(--Grayscale-Gray-100);
			border: 0;
			border-radius: 6px;
			color: var(--Grayscale-Gray-20);
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			height: calc(100% - 16px);
			position: absolute;
			right: 16px;
			top: 8px;
		}
	}

	.receive-amount {
		&__box {
			border-radius: 4px;
			background: var(--Grayscale-Gray-100);
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 48px;
			padding: 20px 16px;
			margin-bottom: 0;
			&--error {
				border: 1px solid var(--additional-error);
			}
		}

		&__box-text {
			color: var(--Grayscale-White);
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
		}

		&__box-code {
			color: var(--Grayscale-Gray-20);
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			border-radius: 6px;
			background: var(--Grayscale-Gray-100);
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__fees {
			margin-top: 16px;
			display: flex;
			flex-direction: column;
			gap: 8px;
		}

		&__fees-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__fees-text {
			font-weight: 400;
			font-size: 14px;
			line-height: 143%;
			color: var(--Grayscale-Gray-20);
		}

		&__fees-price {
			display: flex;
			align-items: center;
			flex-direction: row;
			gap: 10px;
			font-weight: 700;
			font-size: 14px;
			line-height: 143%;
			text-align: right;
			color: var(--Grayscale-Gray-20);

			span {
				color: var(--Grayscale-Gray-20);
			}
		}
	}

	.error-block {
		margin-bottom: 0;
		padding: 8px;
		display: flex;
		align-items: flex-start;
		gap: 6px;
		border-radius: 6px;
		background: var(--Grayscale-Gray-30);
		position: relative;
		z-index: 2;

		&__wrap {
			margin-top: 12px;

			@media only screen and (min-width: 1024px) {
				margin-top: 0;
				position: absolute;
				top: 50%;
				left: calc(100% + 36px);
				width: 100%;
				max-width: 362px;
				transform: translateY(-50%);
			}

			&:before {
				@media only screen and (min-width: 1024px) {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					left: -8px;
					transform: translateY(-50%);
					z-index: 1;
					background-image: url("data:image/svg+xml,%3Csvg width='14' height='17' viewBox='0 0 14 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.535331 9.37002C-0.140652 8.98697 -0.14065 8.01304 0.535333 7.62998L12.507 0.84604C13.1736 0.46828 14 0.949836 14 1.71606V15.2839C14 16.0502 13.1736 16.5317 12.507 16.154L0.535331 9.37002Z' fill='%235C6277' /%3E%3C/svg%3E");
					width: 12px;
					min-width: 12px;
					height: 14px;
					min-height: 14px;
				}
			}
		}

		&__icon {
			min-width: 16px;
			width: 16px;
			height: 16px;
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.99979 1.79997C4.57562 1.79997 1.79979 4.57581 1.79979 7.99997C1.79979 11.4241 4.57562 14.2 7.99979 14.2C11.424 14.2 14.1998 11.4241 14.1998 7.99997C14.1998 4.57581 11.424 1.79997 7.99979 1.79997ZM0.866455 7.99997C0.866455 4.06034 4.06016 0.866638 7.99979 0.866638C11.9394 0.866638 15.1331 4.06034 15.1331 7.99997C15.1331 11.9396 11.9394 15.1333 7.99979 15.1333C4.06016 15.1333 0.866455 11.9396 0.866455 7.99997ZM8.00005 3.46665C8.2946 3.46665 8.53338 3.70544 8.53338 3.99999V9.99999C8.53338 10.2945 8.2946 10.5333 8.00005 10.5333C7.7055 10.5333 7.46672 10.2945 7.46672 9.99999V3.99999C7.46672 3.70544 7.7055 3.46665 8.00005 3.46665ZM8.53338 12C8.53338 11.7054 8.2946 11.4667 8.00005 11.4667C7.7055 11.4667 7.46672 11.7054 7.46672 12V12.3333C7.46672 12.6279 7.7055 12.8667 8.00005 12.8667C8.2946 12.8667 8.53338 12.6279 8.53338 12.3333V12Z' fill='%238D99C5' /%3E%3C/svg%3E");

			display: block;
		}

		p {
			font-weight: 400;
			font-size: 12px;
			line-height: 133%;
			color: var(--Grayscale-White);
		}
	}

	.deposit-address {
		&__text {
			margin-bottom: 0;
			display: flex;
			align-items: center;
			gap: 0;
			word-break: break-all;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			color: var(--Grayscale-Gray-20);
			padding: 20px 16px;
			height: 48px;
			border-radius: 4px;
			background: var(--Grayscale-Gray-100);
			position: relative;
		}

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			min-width: 30px;
			height: 30px;
			min-height: 30px;
			position: absolute;
			top: calc(50% - 15px);
			right: 8px;

			&:before {
				content: '';
				display: block;
				background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.9998 0.300049C7.50864 0.300049 6.2998 1.50888 6.2998 3.00005V4.30005H2.9998C1.50864 4.30005 0.299805 5.50888 0.299805 7.00005V17C0.299805 18.4912 1.50864 19.7 2.9998 19.7H10.9998C12.491 19.7 13.6998 18.4912 13.6998 17V15.7H16.9998C18.491 15.7 19.6998 14.4912 19.6998 13V3.00005C19.6998 1.50888 18.491 0.300049 16.9998 0.300049H8.9998ZM13.6998 14.3H16.9998C17.7178 14.3 18.2998 13.718 18.2998 13V3.00005C18.2998 2.28208 17.7178 1.70005 16.9998 1.70005H8.9998C8.28183 1.70005 7.6998 2.28208 7.6998 3.00005V4.30005H10.9998C12.491 4.30005 13.6998 5.50888 13.6998 7.00005V14.3ZM1.6998 7.00005C1.6998 6.28208 2.28183 5.70005 2.9998 5.70005H10.9998C11.7178 5.70005 12.2998 6.28208 12.2998 7.00005V17C12.2998 17.718 11.7178 18.3 10.9998 18.3H2.9998C2.28183 18.3 1.6998 17.718 1.6998 17V7.00005Z' fill='%23626A85' /%3E%3C/svg%3E");
				background-repeat: no-repeat;
				background-position: center;
				width: 24px;
				min-width: 24px;
				height: 24px;
				min-height: 24px;
			}
			&:hover::before {
				background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.9998 0.300049C7.50864 0.300049 6.2998 1.50888 6.2998 3.00005V4.30005H2.9998C1.50864 4.30005 0.299805 5.50888 0.299805 7.00005V17C0.299805 18.4912 1.50864 19.7 2.9998 19.7H10.9998C12.491 19.7 13.6998 18.4912 13.6998 17V15.7H16.9998C18.491 15.7 19.6998 14.4912 19.6998 13V3.00005C19.6998 1.50888 18.491 0.300049 16.9998 0.300049H8.9998ZM13.6998 14.3H16.9998C17.7178 14.3 18.2998 13.718 18.2998 13V3.00005C18.2998 2.28208 17.7178 1.70005 16.9998 1.70005H8.9998C8.28183 1.70005 7.6998 2.28208 7.6998 3.00005V4.30005H10.9998C12.491 4.30005 13.6998 5.50888 13.6998 7.00005V14.3ZM1.6998 7.00005C1.6998 6.28208 2.28183 5.70005 2.9998 5.70005H10.9998C11.7178 5.70005 12.2998 6.28208 12.2998 7.00005V17C12.2998 17.718 11.7178 18.3 10.9998 18.3H2.9998C2.28183 18.3 1.6998 17.718 1.6998 17V7.00005Z' fill='%238D99C5' /%3E%3C/svg%3E");
			}
		}
	}

	.input-item-text {
		position: absolute;
		right: 51%;
		top: -36px;
		transform: translateY(0);

		.button {
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			color: var(--Blue-Blue-Light);
			text-align: center;
			font-style: normal;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 8px;
			&:hover {
				color: var(--Blue-Blue-Dark);
			}
		}
	}
}

