.BankAccountForm {
	.select-block {
		margin-bottom: 0;
	}
	.input-notify.input-notify--absolute {
		top: 0;
		margin-top: 5px;
	}
	.input-notify__text {
		color: var(--additional-error);
	}
}

