.payment-methods-header {
	padding: 16px 24px;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	border-radius: 0;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
	background: var(--Grayscale-Gray-80);

	&__title {
		font-family: var(--font-family);
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: var(--Grayscale-White);
	}

	&__actions {
		display: flex;
		gap: 10px;
	}
}

