.tabs-buttons {
	display: inline-flex;
	width: 100%;
	border-radius: 6px 6px 0 0;
	background: var(--Grayscale-Gray-80);

	&--type2 {
		display: inline-flex;
		width: 100%;
		border-radius: 6px 6px 0 0;
		background: var(--Grayscale-Gray-80);
		a,
		button {
			font-weight: 700;
			font-size: 14px;
			line-height: 143%;
			text-align: center;
			color: var(--Grayscale-White);
			display: inline-flex;
			position: relative;
			align-items: center;
			justify-content: center;
			height: 56px;
			padding: 18px 16px;
			width: 100%;
			&:before {
				content: '';
				display: block;
				width: 0;
				height: 2px;
				position: absolute;
				bottom: 0;
				left: 0;
				background-color: var(--Blue-Blue-Brand);
				transition: var(--transition-base);

				@media only screen and (max-width: 1179.98px) {
					display: none;
				}
			}

			&:hover {
				color: var(--Grayscale-Gray-10);
			}

			&.active {
				color: var(--Grayscale-Gray-10);
				font-family: var(--font-sans);

				@media only screen and (min-width: 1180px) {
					border-color: var(--Blue-Blue-Brand);
				}

				&:before {
					width: 100%;
				}
			}
		}
	}

	&.tabs-buttons---border {
		border-radius: 12px;
		border: 1px solid var(--greyscale-grey-100);
		background: var(--additional-white);
		padding: 4px;
	}

	a,
	button {
		font-weight: 700;
		font-size: 14px;
		line-height: 143%;
		text-align: center;
		color: var(--Grayscale-White);
		display: inline-flex;
		position: relative;
		align-items: center;
		justify-content: center;
		height: 56px;
		padding: 18px 16px;
		width: 100%;
		&:before {
			content: '';
			display: block;
			width: 0;
			height: 2px;
			position: absolute;
			bottom: 0;
			left: 0;
			background-color: var(--Blue-Blue-Brand);
			transition: var(--transition-base);

			@media only screen and (max-width: 1179.98px) {
				display: none;
			}
		}

		&:hover {
			color: var(--Grayscale-Gray-10);
		}

		&.active {
			color: var(--Grayscale-Gray-10);
			font-family: var(--font-sans);

			@media only screen and (min-width: 1180px) {
				border-color: var(--Blue-Blue-Brand);
			}

			&:before {
				width: 100%;
			}
		}

		&:disabled {
			background: var(--gray400);
			color: var(--text-secondary);
			font-weight: 500;
			cursor: not-allowed;
			opacity: 0.7;
		}
	}
}

