.select-input {
	color: var(--Grayscale-White);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	&::placeholder {
		color: var(--Grayscale-Gray-20);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		opacity: 1;
	}
}

