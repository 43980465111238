.verification-form {
	margin-top: 16px;

	@media only screen and (min-width: 768px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 24px;
	}

	@media only screen and (max-width: 767.98px) {
		display: flex;
		flex-direction: column;
		gap: 32px;
	}

	&__buttons {
		margin-top: 24px;
		display: flex;
		gap: 10px;

		@media only screen and (min-width: 768px) {
			flex-direction: row;
			justify-content: flex-end;
		}

		@media only screen and (max-width: 767.98px) {
			flex-direction: column;
		}
		.btn-cancel {
			min-width: 88px;
		}
	}
}
