.settings-2fa-qr-code {
	margin-bottom: 0;
	display: grid;
	align-items: center;
	grid-template-columns: 163px 1fr;
	gap: 24px;

	&__photo {
		width: 163px;
		height: 161px;
	}

	&__info {
	}
}

