.select {
	border-radius: 4px;
	background: var(--Grayscale-Gray-100);
	border: 1px solid var(--Grayscale-Gray-100);

	&.active {
		.select__current-arrow {
			transform: rotate(180deg);
		}
	}
	&--error {
		border-color: var(--additional-error);
	}

	.coin--type3 .coin__text {
		color: var(--Grayscale-Gray-20);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;
		padding: 0;
		font-family: var(--font-sans);
	}

	&__custom {
		position: absolute;
		background: var(--Grayscale-Gray-100);
		span {
			color: var(--Grayscale-Gray-20);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
		}
		width: calc(100% - 32px);

		.coin {
			overflow: hidden;
			width: calc(100% - 30px);

			.coin__text {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				color: var(--Grayscale-White);
			}
		}
	}

	&__current {
		padding: 0 8px 0 16px;
		color: var(--Grayscale-White);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 143%;
	}

	&__current-arrow {
		display: flex;
		max-width: 24px;
		margin-left: 10px;
		width: 20px;
		min-width: 20px;
		height: 20px;
		min-height: 20px;
		z-index: 1;
		transition: var(--transition-base);
	}

	&__tabs {
		border-radius: 6px;
		padding: 4px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		margin-bottom: 10px;
	}

	&__tab {
		font-weight: 700;
		font-size: 14px;
		line-height: 143%;
		text-align: center;
		color: var(--Grayscale-White);
		display: inline-flex;
		position: relative;
		align-items: center;
		justify-content: center;
		height: 56px;
		padding: 4px 20px;
		&:before {
			content: '';
			display: block;
			width: 0;
			height: 1px;
			position: absolute;
			bottom: 0;
			left: 0;
			background-color: var(--Blue-Blue-Brand);
			transition: var(--transition-base);

			@media only screen and (max-width: 1179.98px) {
				display: none;
			}
		}
		&:hover {
			color: var(--Grayscale-Gray-10);
		}

		&.active {
			color: var(--Grayscale-Gray-10);
			font-family: var(--font-sans);

			@media only screen and (min-width: 1180px) {
				border-color: var(--Blue-Blue-Brand);
			}

			&:before {
				width: 100%;
			}
		}

		&:disabled {
			background: var(--gray400);
			color: var(--text-secondary);
			font-weight: 500;
			cursor: not-allowed;
			opacity: 0.7;
		}
	}

	&__drop {
		right: 0;
		max-width: 100%;
		border-radius: 6px;
		display: block !important;
		box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.24);
		background: var(--Grayscale-Gray-90);

		&--top {
			top: calc(100% + 8px);
		}

		&.select__drop---top {
			top: calc(100% + 4px);
			padding: 8px;
		}

		.input.input--search {
			input {
				height: 42px;
				border-radius: 4px;
				background: var(--Grayscale-Gray-100);
				padding: 0 16px;
				color: var(--Grayscale-White);
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 160%;
				border: 1px solid var(--Grayscale-Gray-80);

				&::placeholder {
					color: var(--text-secondary);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					opacity: 1;
				}
			}

			.input-icon {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				display: flex;
				left: 0;
				width: 20px;
				min-width: 20px;
				height: 20px;
				min-height: 20px;

				// &--search {
				// 	left: 16px;
				// 	background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.58464 17.4974C13.9569 17.4974 17.5013 13.953 17.5013 9.58073C17.5013 5.20847 13.9569 1.66406 9.58464 1.66406C5.21238 1.66406 1.66797 5.20847 1.66797 9.58073C1.66797 13.953 5.21238 17.4974 9.58464 17.4974Z' stroke='%23BFBFBF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M18.3346 18.3307L16.668 16.6641' stroke='%23BFBFBF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
				// }

				&--clear {
					left: auto;
					right: 16px;
					background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.14824 1.60589C9.35652 1.39761 9.35652 1.05992 9.14824 0.851644C8.93996 0.643365 8.60227 0.643365 8.39399 0.851644L4.99996 4.24568L1.60594 0.85166C1.39766 0.64338 1.05997 0.64338 0.851692 0.85166C0.643413 1.05994 0.643413 1.39763 0.851692 1.60591L4.24571 4.99993L0.851522 8.39411C0.643242 8.60239 0.643243 8.94008 0.851522 9.14836C1.0598 9.35664 1.39749 9.35664 1.60577 9.14836L4.99996 5.75417L8.39416 9.14838C8.60244 9.35666 8.94013 9.35666 9.14841 9.14838C9.35669 8.9401 9.35669 8.60241 9.14841 8.39413L5.75421 4.99993L9.14824 1.60589Z' fill='%23626A85' /%3E%3C/svg%3E");
					background-position: center;
					background-repeat: no-repeat;
					cursor: pointer;
				}
			}
		}

		ul > li > button {
			padding: 12px 16px;
			border-radius: 0;

			&:hover,
			&.active {
				background: var(--Grayscale-Gray-70);
			}
			border-bottom: 1px solid var(--Grayscale-Gray-70);
		}

		.select__drop-scroll {
			max-height: 165px;
			scrollbar-width: 4px;
			scrollbar-color: var(--Grayscale-Gray-30) transparent;

			// &::-webkit-scrollbar {
			// 	width: 4px;
			// }

			// &::-webkit-scrollbar-track {
			// 	border-radius: 40px;
			// 	background-color: transparent;
			// }

			// &::-webkit-scrollbar-thumb {
			// 	background-color: var(--Grayscale-Gray-30);
			// }
		}
	}

	&__drop-item {
		.coin--type3 {
			display: flex;
			align-items: center;
			gap: 10px;

			.coin__icon {
				min-width: 30px;
				width: 30px;
				height: 30px;
			}

			.coin__text {
				color: var(--gray800);
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				padding: 0;
			}

			.coin__text-more--type2 {
				padding: 0;
				color: var(--text-secondary);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}
}

.select-block--error {
	border: 1px solid var(--additional-error);
	border-radius: 4px;
}

