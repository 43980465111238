.input {
	margin-bottom: 16px;

	&--floating {
		position: relative;
	}

	&__notification {
		margin-top: 5px;
		color: var(--additional-error);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		/*&--type2 {
			a {
				font-size: 12px;
				font-weight: 400;
				line-height: 16px; !* 133.333% *!
				color: var(--Blue-Blue-Light);
			}
		}*/

		&--type2 {
			display: flex;
			flex-direction: column;
			gap: 4px;
			background: none;
			margin-top: 0;
			padding: 0;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 133.333% */

			a {
				font-size: 12px;
				font-weight: 400;
				line-height: 16px; /* 133.333% */
				color: var(--Blue-Blue-Light);
			}
		}

		&--type5 {
			margin-top: 0;

			p {
				font-weight: 400;
				font-size: 14px;
				line-height: 143%;
				color: var(--Grayscale-Gray-20);
				display: flex;
				align-items: center;
				justify-content: space-between;

				> span {
					display: flex;
					align-items: center;
					gap: 10px;
					font-weight: 700;
					font-size: 14px;
					line-height: 143%;
					text-align: right;
					color: var(--Grayscale-Gray-20);
				}
			}
		}
	}

	&__name {
		color: var(--Grayscale-Gray-20);
		font-size: 14px;
		font-weight: 400;
		line-height: 20px; /* 142.857% */
		margin-bottom: 0;
	}
}

.totp .input__notification--type2 a {
	font-size: 12px;
	font-weight: 400;
	line-height: 16px; /* 133.333% */
	color: var(--Blue-Blue-Light);
}
