.detailed-view {
	display: flex;
	flex-direction: column;
	//gap: 12px;
	gap: 10px;

	&__title {
		color: var(--greyscale-grey-800);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;
	}

	&__text-wrap {
		display: flex;
		gap: 6px;
		align-items: baseline;
	}

	&__text {
		color: var(--greyscale-grey-800);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;
		overflow-wrap: anywhere;
	}

	&__more {
		color: var(--text-secondary);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-top: 2px;
	}
	.input-country-item {
		height: 44px;
		border-radius: 10px;
		background: var(--additional-white);
		border: 1px solid var(--additional-white);
		padding: 8px 16px;
	}
	.input-file {
		margin-bottom: 8px;
	}
}

.detailed-view-form {
	form {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.select.disabled {
		.coin .coin__text {
			color: var(--Grayscale-White);
		}
	}
	.select-block {
		margin-bottom: 0px;
	}
}

.wallet-operations-header-wrap {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

