.wallet-content-balance-item {
	padding: 16px 24px;
	border-radius: 6px;
	width: calc(33.33333333333333% - 20px);
	margin: 0 10px;
	background: var(--Grayscale-Gray-80);

	@media only screen and (max-width: 1080px) {
		margin: 10px 0;
		width: 100%;
	}

	&__title {
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		color: var(--Grayscale-White);
		margin-bottom: 12px;
	}

	&__value {
		margin-bottom: 12px;

		p {
			font-weight: 600;
			font-size: 24px;
			line-height: 133%;
			color: var(--Grayscale-White);

			span {
				font-weight: 400;
				font-size: 14px;
				line-height: 143%;
				color: var(--Grayscale-Gray-20);
				margin-left: 8px;
			}
		}
	}

	&__user-currensy {
		border-radius: 6px;
		background: var(--Grayscale-Gray-70);
		padding: 4px 8px;
		display: inline-flex;

		p {
			font-weight: 400;
			font-size: 14px;
			line-height: 143%;
			color: var(--Grayscale-Gray-20);
		}
	}
}

