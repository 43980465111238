.table-filter-wrap {
	&--px-16 {
		.table-filter {
			padding-left: 24px;
			padding-right: 24px;
		}
	}
}

.table-filter {
	border-radius: 6px;
	box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.14);
	background: var(--Grayscale-Gray-80);
	padding: 8px 24px;
	margin-bottom: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 24px;
	&--history {
		box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.24);
		background: var(--Grayscale-Gray-90);
		border-radius: 0 0 6px 6px;
	}

	.table-filter-left {
		> div {
			min-width: inherit;
			width: auto;
			margin: 0;
		}

		&__item {
			border-radius: 8px;
			background: var(--gray300);
			padding: 6px 10px;
		}

		> .select-block {
			min-width: inherit;
			display: flex;
			align-items: center;
			flex-direction: row;
			gap: 10px;
			border-radius: 4px;
			background: var(--Grayscale-Gray-100);
			border: 1px solid var(--Grayscale-Gray-80);

			padding: 2px 0 2px 10px;
			height: 32px;

			.select-block__name {
				font-weight: 400;
				font-size: 14px;
				line-height: 143%;
				color: var(--Grayscale-Gray-20);
				margin-bottom: 0;
			}

			.selectedCurrency {
				min-width: 120px;
				height: 28px;

				.form,
				.instant-trade-form-item__field {
					height: 28px;
				}

				.select {
					position: relative;
					display: flex;
					align-content: center;
					z-index: 3;
					height: 28px;
					border-radius: 4px;
					background: var(--Grayscale-Gray-100);

					border: 1px solid var(--Grayscale-Gray-100);
					transition: border-color var(--transition-base);

					&.active {
						border-color: var(--Grayscale-Gray-10);

						.select__current-arrow {
							transform: rotate(180deg);
						}
					}
				}

				.select__current {
					padding: 0 10px;
				}

				.select__current-text {
					display: flex;
					align-items: center;
					flex-direction: row;
					gap: 8px;

					p {
						color: var(--Grayscale-White);

						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}

					img {
						width: 18px;
						min-width: 18px;
						height: 18px;
						min-height: 18px;
					}
				}

				.select__current-arrow {
					display: flex;
					max-width: 20px;
					width: 20px;
					min-width: 20px;
					height: 20px;
					min-height: 20px;
					z-index: 1;
					transition: var(--transition-base);
				}

				.select__drop {
					padding: 2px;
					border-radius: 6px;
					border: 1px solid var(--Grayscale-Gray-10);
					box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
					background: var(--Grayscale-Gray-90);
					overflow: hidden;
					top: calc(100% + 5px);

					.select__drop-scroll {
						max-height: 164px;
					}

					ul > li > button {
						padding: 5px 10px;
						color: var(--Grayscale-Gray-20);
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 160%; /* 22.4px */
						min-height: 32px;
						display: flex;
						align-items: center;
						flex-direction: row;
						gap: 8px;
						border-radius: 4px;

						&:hover {
							background: var(--Grayscale-Gray-70);
						}

						img {
							width: 18px;
							min-width: 18px;
							height: 18px;
							min-height: 18px;
						}
					}
				}
			}

			.select-block {
				min-width: 130px;
			}
		}
	}

	.DatePicker {
		display: flex;
		flex-direction: row;
		align-items: center;

		&.is-active {
			.react-datepicker__input-container input {
				border: 1px solid var(--Grayscale-Gray-10);
				background: var(--Grayscale-Gray-100);
			}
		}

		.select-block__name {
			margin-bottom: 0;
			font-weight: 400;
			font-size: 14px;
			line-height: 143%;
			color: var(--Grayscale-Gray-20);
			position: absolute;
			top: 50%;
			left: 10px;
			transform: translateY(-50%);
			z-index: 1;
		}

		.datepicker-input-wrapper {
			width: 265px;
		}

		.react-datepicker__input-container input {
			height: 32px;
			border-radius: 4px;
			border: 1px solid var(--Grayscale-Gray-80);
			background: var(--Grayscale-Gray-100);

			padding: 6px 10px 6px 56px;
			font-weight: 400;
			font-size: 14px !important;
			line-height: 143%;
			color: var(--Grayscale-White);

			&::placeholder {
				font-size: 12px;
				font-style: normal;
				font-weight: 300;
				line-height: normal;
			}
		}

		.select__current-date-icon {
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.99976 8.83333C5.72361 8.83333 5.49976 9.05719 5.49976 9.33333C5.49976 9.60947 5.72361 9.83333 5.99976 9.83333H9.99976C10.2759 9.83333 10.4998 9.60947 10.4998 9.33333C10.4998 9.05719 10.2759 8.83333 9.99976 8.83333H5.99976Z' fill='%23626A85' /%3E%3Cpath d='M5.99976 11.1667C5.72361 11.1667 5.49976 11.3905 5.49976 11.6667C5.49976 11.9428 5.72361 12.1667 5.99976 12.1667H9.99976C10.2759 12.1667 10.4998 11.9428 10.4998 11.6667C10.4998 11.3905 10.2759 11.1667 9.99976 11.1667H5.99976Z' fill='%23626A85' /%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.49976 1.33333C6.49976 1.05719 6.2759 0.833328 5.99976 0.833328C5.72361 0.833328 5.49976 1.05719 5.49976 1.33333V2.5H3C2.17157 2.5 1.5 3.17157 1.5 4V14C1.5 14.8284 2.17157 15.5 3 15.5H13C13.8284 15.5 14.5 14.8284 14.5 14V4C14.5 3.17157 13.8284 2.5 13 2.5H10.4998V1.33333C10.4998 1.05719 10.2759 0.833328 9.99976 0.833328C9.72362 0.833328 9.49976 1.05719 9.49976 1.33333V2.5H6.49976V1.33333ZM5.52821 3.5C5.59685 3.6942 5.78206 3.83333 5.99976 3.83333C6.21746 3.83333 6.40266 3.6942 6.4713 3.5H9.52821C9.59685 3.6942 9.78206 3.83333 9.99976 3.83333C10.2175 3.83333 10.4027 3.6942 10.4713 3.5H13C13.2761 3.5 13.5 3.72386 13.5 4V6.16666H2.5V4C2.5 3.72386 2.72386 3.5 3 3.5H5.52821ZM2.5 7.16666V14C2.5 14.2761 2.72386 14.5 3 14.5H13C13.2761 14.5 13.5 14.2761 13.5 14V7.16666H2.5Z' fill='%23626A85' /%3E%3C/svg%3E");
			background-position: center;
			background-repeat: no-repeat;
			width: 20px;
			min-width: 20px;
			height: 20px;
			min-height: 20px;
		}

		.react-datepicker {
			display: flex;
			flex-direction: column-reverse;
		}

		.react-datepicker__navigation {
			top: 42px;
		}
	}
	.table-filter-right {
		.select-block {
			min-width: inherit;
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: center;
			gap: 10px;
			border-radius: 4px;
			background: var(--Grayscale-Gray-100);
			border: 1px solid var(--Grayscale-Gray-80);

			padding: 2px 0 2px 10px;
			height: 32px;
			.select-block__name {
				font-weight: 400;
				font-size: 14px;
				line-height: 143%;
				color: var(--Grayscale-Gray-20);
				margin-bottom: 0;
			}
			.select.select--type-choice {
				min-width: 160px;
				border-radius: 4px;
				background: var(--Grayscale-Gray-100);

				border: 1px solid var(--Grayscale-Gray-100);
				height: 28px;

				&.active {
					border-color: var(--Grayscale-Gray-10);

					.select__current-arrow {
						transform: rotate(180deg);
					}
				}

				.select__current {
					padding: 0 10px;
					color: var(--Grayscale-White);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}

				.select__current-arrow {
					display: flex;
					max-width: 20px;
					width: 20px;
					min-width: 20px;
					height: 20px;
					min-height: 20px;
					z-index: 1;
					transition: var(--transition-base);
				}

				.select__drop {
					padding: 2px;
					right: auto;
					left: 0;
					top: calc(100% + 5px);
					max-width: 160px;
					border-radius: 6px;
					border: 1px solid var(--Grayscale-Gray-10);
					box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
					background: var(--Grayscale-Gray-90);

					ul > li > button {
						padding: 5px 10px;
						border-radius: 4px;
						background: transparent;
						color: var(--Grayscale-Gray-20);

						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 160%; /* 22.4px */

						&:hover,
						&.active {
							background: var(--Grayscale-Gray-70);
						}
					}
				}
			}
		}
		.input {
			margin-bottom: 0px;
		}
		.input__name {
			color: var(--primary-blue-600);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
			margin-bottom: 0;
		}

		.input-item {
			border-radius: 4px;
			background: var(--Grayscale-Gray-100);
			border: 1px solid var(--Grayscale-Gray-80);
			padding: 0 10px;
			height: 32px;
			color: var(--Grayscale-White);
			font-size: 14px !important;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			width: 60px;

			&::placeholder {
				font-weight: 400;
				font-size: 14px !important;
				line-height: 143%;
				color: var(--Grayscale-Gray-20);
			}

			&:focus {
				border-color: var(--Grayscale-Gray-10);
			}
		}
	}
}

