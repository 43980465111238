.wallet-operations-header-wrap {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.wallet-operations-header {
		margin-bottom: 0;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: var(--Grayscale-White);
	}
}

.wallet-operations-header-history {
	display: flex;
	flex-direction: column;
}

