.account-form {
	border-radius: 6px;
	padding: 16px 24px 24px 24px;
	box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.14);
	background: var(--Grayscale-Gray-80);
	&__info {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__title {
		color: var(--Grayscale-White);
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-bottom: 8px;
	}

	&__info-text {
		color: var(--greyscale-grey-600);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 22.4px */
	}

	&__verification-status {
		border-radius: 20px;
		border: 0.8px solid var(--additional-green);
		background: var(--transparent-green);
		height: 27px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 2px 24px;
		color: var(--additional-green);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	.verification__input {
		&.green-bg {
			.form-control {
				border-color: var(--additional-green);
				background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.9998 2.69999C6.86356 2.69999 2.6998 6.86374 2.6998 12C2.6998 17.1362 6.86356 21.3 11.9998 21.3C17.1361 21.3 21.2998 17.1362 21.2998 12C21.2998 6.86374 17.1361 2.69999 11.9998 2.69999ZM1.2998 12C1.2998 6.09054 6.09036 1.29999 11.9998 1.29999C17.9093 1.29999 22.6998 6.09054 22.6998 12C22.6998 17.9094 17.9093 22.7 11.9998 22.7C6.09036 22.7 1.2998 17.9094 1.2998 12Z' fill='%235EC45C'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.4336 7.4506C17.737 7.69015 17.7888 8.13033 17.5492 8.43377L11.2334 16.4338C11.1088 16.5917 10.9221 16.6883 10.7212 16.699C10.5203 16.7097 10.3245 16.6334 10.1837 16.4896L6.49951 12.7249C6.22911 12.4486 6.2339 12.0054 6.51021 11.735C6.78651 11.4646 7.2297 11.4694 7.5001 11.7457L10.6277 14.9417L16.4504 7.56627C16.6899 7.26283 17.1301 7.21104 17.4336 7.4506Z' fill='%235EC45C'/%3E%3C/svg%3E");
				background-repeat: no-repeat;
				background-position-x: 98%;
				background-position-y: center;
			}
			background-color: transparent;
			color: transparent;
			padding: 0;
		}

		&.red-bg {
			.form-control {
				border-color: var(--additional-error);
				background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.2224 6.90884C18.5348 6.59642 18.5348 6.08989 18.2224 5.77747C17.9099 5.46505 17.4034 5.46505 17.091 5.77747L11.9999 10.8685L6.90891 5.77749C6.59649 5.46507 6.08996 5.46507 5.77754 5.77749C5.46512 6.08991 5.46512 6.59644 5.77754 6.90886L10.8686 11.9999L5.77728 17.0912C5.46486 17.4036 5.46486 17.9101 5.77728 18.2225C6.0897 18.535 6.59623 18.535 6.90865 18.2225L11.9999 13.1313L17.0912 18.2226C17.4037 18.535 17.9102 18.535 18.2226 18.2226C18.535 17.9101 18.535 17.4036 18.2226 17.0912L13.1313 11.9999L18.2224 6.90884Z' fill='%23EB5757'/%3E%3C/svg%3E%0A");
				background-repeat: no-repeat;
				background-position-x: 98%;
				background-position-y: center;
			}
			background-color: transparent;
			color: transparent;
			padding: 0;
		}

		&.yellow-bg {
			.form-control {
				border-color: var(--additional-warning);
				background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.9998 2.69999C6.86356 2.69999 2.6998 6.86374 2.6998 12C2.6998 17.1362 6.86356 21.3 11.9998 21.3C17.1361 21.3 21.2998 17.1362 21.2998 12C21.2998 6.86374 17.1361 2.69999 11.9998 2.69999ZM1.2998 12C1.2998 6.09054 6.09036 1.29999 11.9998 1.29999C17.9093 1.29999 22.6998 6.09054 22.6998 12C22.6998 17.9094 17.9093 22.7 11.9998 22.7C6.09036 22.7 1.2998 17.9094 1.2998 12ZM12.0002 5.20001C12.442 5.20001 12.8002 5.55818 12.8002 6.00001V15C12.8002 15.4418 12.442 15.8 12.0002 15.8C11.5584 15.8 11.2002 15.4418 11.2002 15V6.00001C11.2002 5.55818 11.5584 5.20001 12.0002 5.20001ZM12.8002 18C12.8002 17.5582 12.442 17.2 12.0002 17.2C11.5584 17.2 11.2002 17.5582 11.2002 18V18.5C11.2002 18.9418 11.5584 19.3 12.0002 19.3C12.442 19.3 12.8002 18.9418 12.8002 18.5V18Z' fill='%23EE9E53'/%3E%3C/svg%3E");
				background-repeat: no-repeat;
				background-position-x: 98%;
				background-position-y: center;
			}
			background-color: transparent;
			color: transparent;
			padding: 0;
		}
	}

	.input__name,
	label {
		color: var(--Grayscale-Gray-20);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 143%;
	}

	.form-row {
		@media only screen and (min-width: 768px) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 16px;
		}
	}

	.form-group {
		display: flex;
		flex-direction: column;
		margin-bottom: 16px;
	}

	.form-control:not(.form-control--phone) {
		border-radius: 4px;
		border: 1px solid var(--Grayscale-Gray-80);
		background: var(--Grayscale-Gray-100);
		height: 48px;
		width: 100%;
		color: var(--Grayscale-White);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%; /* 22.4px */
		padding: 20px 16px;
	}

	.select {
		height: 48px;
		border-radius: 6px;

		&.active {
			.select__current-arrow {
				transform: rotate(180deg);
			}
		}

		.coin--type3 .coin__text {
			color: var(--greyscale-grey-800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 160%; /* 22.4px */
			padding: 0;
			font-family: var(--font-sans);
		}
	}

	.react-tel-input-wrapper {
		.input-item {
			padding-left: 100px;
		}
		.form-control {
			position: absolute;
			z-index: 10;
			left: 45px;
			top: 50%;
			padding: 0;
			width: 50px;
			height: 100%;
			border: none;
			pointer-events: none;
			background: transparent;
			transform: translateY(-50%);
			font-size: 14px;
			font-weight: 700;
			line-height: 180%; /* 25.2px */
			color: var(--Grayscale-Gray-20);
		}

		.react-tel-input {
			font-family: var(--font-sans);
			font-size: 14px;
			font-weight: 400;
			line-height: 180%; /* 25.2px */
			color: var(--Grayscale-White);

			position: absolute;
			top: 4px;
			left: 4px;
			z-index: 5;
			height: 36px;
			width: 117px;
		}

		.selected-flag {
			width: 100%;
			margin-top: 0;
			padding: 0 0 0 8px;
		}

		.flag-dropdown {
			width: 100%;
			border-radius: 4px;
			background: transparent;
			border: none;
			height: 100%;
			top: 0;
			left: 0;
		}

		.react-tel-input .selected-flag {
			&.open {
				&:after {
					transform: rotate(180deg);
				}
			}

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: calc(50% - 11.5px);
				right: 25px;
				width: 24px;
				min-width: 24px;
				height: 24px;
				min-height: 24px;
				background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.4802 15.86C12.2402 16.18 11.7602 16.18 11.5202 15.86L8.22021 11.46C7.92355 11.0645 8.20578 10.5 8.70021 10.5L15.3002 10.5C15.7946 10.5 16.0769 11.0645 15.7802 11.46L12.4802 15.86Z' fill='%23626A85' /%3E%3C/svg%3E");
				transition: var(--transition-base);
			}

			.arrow {
				display: none;
			}

			.flag {
				//position: absolute;
				//top: 50%;
				//margin-top: -12px;
				//border-radius: 50%;
			}
		}

		.input {
			margin-bottom: 0;
		}

		.input--error .input-item,
		.input--error .textarea {
			border: 1px solid var(--greyscale-grey-100);
		}

		.input--success .input-item {
			border: 1px solid var(--greyscale-grey-100);
		}
	}

	.select-block {
		margin-bottom: 0;
	}

	.select__drop .select__drop-scroll {
		max-height: 180px;
	}
}

