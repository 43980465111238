.successful-info {
	border-radius: 6px;
	background: var(--Grayscale-Gray-70);
	margin-bottom: 16px;
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 8px;

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 16px;
		color: var(--text-secondary);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		p {
			color: var(--Grayscale-White);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px; /* 150% */
		}

		span {
			color: var(--Grayscale-White);
			text-align: right;
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: 24px; /* 150% */
		}
	}
}
