.payment-methods-list {
	@media only screen and (min-width: 920px) {
		display: grid;
		grid-template-columns: 1fr 0.8fr 0.8fr 1.4fr;
		gap: 50px;
	}

	&--type3 {
		@media only screen and (min-width: 920px) {
			grid-template-columns: 0.7fr 0.7fr 2.1fr;
		}
	}
}

