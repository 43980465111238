.maintenance-page {
	.content {
		overflow: hidden;
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}
}

.maintenance {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	align-items: center;
	justify-content: center;

	@media only screen and (max-width: 767.98px) {
		padding: 40px 0 0 0;
	}

	&__logo {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-bottom: 60px;

		@media only screen and (max-width: 767.98px) {
		}
	}

	&__content {
		padding: 0 15px;
	}

	&__icon {
		text-align: center;
		margin-bottom: 24px;

		img {
			display: block;
			margin: 0 auto;
		}
	}

	&__title {
		color: var(--Grayscale-White);
		text-align: center;
		font-family: var(--font-jura);
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px;
		letter-spacing: -1.12px;
		margin-bottom: 32px;
	}

	&__text {
		color: var(--Grayscale-White);
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 300;
		line-height: 24px;

		@media only screen and (min-width: 768px) {
			max-width: 425px;
			margin: 0 auto;
		}
	}

	&__footer {
		color: var(--Grayscale-Gray-30);
		text-align: center;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
		padding: 60px 15px;

		@media only screen and (max-width: 767.98px) {
			padding: 40px 15px;
		}
	}
}
