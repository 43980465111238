.td-sorting-arrow {
	height: 8px;
}

.td-sorting-arrow__item {
	cursor: pointer;
}

.upper__text {
	text-transform: uppercase;
}

.coin__text-more {
	text-transform: capitalize;
}

.coin__text-normal {
	.coin__text {
		font-weight: normal;
	}
}

.pagination {
	li {
		cursor: pointer;
	}

	li.disabled {
		cursor: default;
	}
}

.payment-methods-body .payment-methods-list--type2 {
	grid-template-columns: auto;
}

.page-wrap .wallet-operations {
	@media screen and (min-width: 760px) {
		min-height: calc(100vh - 188px);
	}
}

.coin__text.coin__text---upper {
	text-transform: uppercase;
	font-weight: 700;
	font-size: 14px;
	line-height: 143%;
	color: var(--Grayscale-White);
}

.tabs-buttons.tabs-buttons---border {
	border: 1px solid #e6e8ec;
	border-radius: 8px;
	padding: 2px;
}

.deposit-address__text.deposit-address__text--with-warning {
	margin-bottom: 16px;
}

.receive-amount strong {
	font-weight: 500;
	color: var(--text-color-main);
}

/*.table.table--transaction-history-trade .tr {
	grid-template-columns: 1.3fr 0.7fr 0.7fr 1.2fr 1.2fr 2fr 1fr 1fr;
}*/

.deposit-address--memo {
	margin-bottom: 100px;
}

.strong--lighted {
	color: var(--text-color2);
}

.select__custom {
	.coin--type3 {
		> .coin__text {
			padding: 6px 0;
		}
	}
}

/*.wallet-section {
	.wallet-operations-item__title {
		font-weight: 600;
	}
}*/

.error-block {
	color: #e04141;
}
