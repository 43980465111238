.referrals {
	&__heading {
		color: var(--Grayscale-White);
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
	}

	&__box {
		border-radius: 6px;
		background: var(--Grayscale-Gray-80);
		box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.14);
		padding: 16px 24px 24px 24px;

		@media only screen and (min-width: 767.98px) {
			max-width: 660px;
		}

		&-title {
			color: var(--Grayscale-White);
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px;
		}

		&-count {
			color: var(--Blue-Blue-Light);
			font-size: 32px;
			font-style: normal;
			font-weight: 600;
			line-height: 40px;
		}

		&-divider {
			border-top: 1px solid var(--Grayscale-Gray-70);
			width: 100%;
			height: 1px;
			margin: 16px 0;
		}
	}
}
