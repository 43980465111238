.footer-nav {
	margin-top: 8px;
	ul {
		display: flex;
		align-items: flex-start;
		gap: 8px;

		@media only screen and (max-width: 575.98px) {
			flex-direction: column;
			gap: 8px;
		}

		> li > a,
		> li > button {
			color: var(--Grayscale-Gray-20);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
			text-decoration: none;
			transition: all var(--transition-base);

			&:hover {
				color: var(--Grayscale-Gray-10);
			}
		}
	}
}

