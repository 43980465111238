.verification-acount-info {
	margin-top: 16px;
	border-top: 0;
	padding: 8px 16px;
	border-radius: 4px;
	background: var(--Grayscale-Gray-30);

	&__heading {
		color: var(--Grayscale-White);
		display: flex;
		align-items: center;
		gap: 8px;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;

		&:before {
			content: '';
			display: block;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.9998 1.6998C5.86356 1.6998 1.6998 5.86356 1.6998 10.9998C1.6998 16.1361 5.86356 20.2998 10.9998 20.2998C16.1361 20.2998 20.2998 16.1361 20.2998 10.9998C20.2998 5.86356 16.1361 1.6998 10.9998 1.6998ZM0.299805 10.9998C0.299805 5.09036 5.09036 0.299805 10.9998 0.299805C16.9093 0.299805 21.6998 5.09036 21.6998 10.9998C21.6998 16.9093 16.9093 21.6998 10.9998 21.6998C5.09036 21.6998 0.299805 16.9093 0.299805 10.9998ZM11.0002 4.19983C11.442 4.19983 11.8002 4.558 11.8002 4.99983V13.9998C11.8002 14.4417 11.442 14.7998 11.0002 14.7998C10.5584 14.7998 10.2002 14.4417 10.2002 13.9998V4.99983C10.2002 4.558 10.5584 4.19983 11.0002 4.19983ZM11.8002 16.9998C11.8002 16.558 11.442 16.1998 11.0002 16.1998C10.5584 16.1998 10.2002 16.558 10.2002 16.9998V17.4998C10.2002 17.9417 10.5584 18.2998 11.0002 18.2998C11.442 18.2998 11.8002 17.9417 11.8002 17.4998V16.9998Z' fill='%238D99C5' /%3E%3C/svg%3E");
			width: 24px;
			min-width: 24px;
			height: 24px;
			min-height: 24px;
		}
	}

	p {
		color: var(--Grayscale-White);
		font-weight: 400;
		font-size: 12px;
		font-style: normal;
		line-height: 133%;
		padding-left: 32px;
		margin-bottom: 0;
	}

	ul {
		display: block;
		margin: 0;
		padding: 0;
		list-style-type: none;

		li {
			color: var(--Grayscale-White);
			font-weight: 400;
			font-size: 12px;
			font-style: normal;
			line-height: 133%;
			padding-left: 22px;
			position: relative;

			&:before {
				content: '';
				display: block;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 20 20' fill='none'%3E%3Ccircle cx='12' cy='12' r='3' fill='%23ffffff'/%3E%3C/svg%3E");
				width: 12px;
				min-width: 12px;
				height: 12px;
				min-height: 12px;
				position: absolute;
				top: 0;
				left: 0;
			}

			&:not(:last-of-type) {
				// margin-bottom: 10px;
			}
		}
	}
	&__list {
		// margin-left: 26px !important;
		margin: 0 0 0 26px !important;
		max-width: 516px;
	}
}
