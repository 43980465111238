.accordion {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 8px;

	&__item {
		box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.14);
		background: var(--Grayscale-Gray-80);
		border-radius: 6px;
	}

	&__title {
		border-radius: 6px;
		box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.14);
		background: var(--Grayscale-Gray-80);
		min-height: 56px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: var(--Grayscale-White);
		padding: 16px 24px;
		cursor: pointer;

		@media only screen and (max-width: 767.98px) {
		}

		&.is-active {
			box-shadow: none;
			.accordion__title-arrow:before {
				transform: rotate(180deg);
			}
		}
	}

	&__title-arrow {
		border-radius: 6px;
		background: var(--Grayscale-Gray-70);
		width: 24px;
		min-width: 24px;
		height: 24px;
		min-height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:before {
			content: '';
			display: block;
			width: 24px;
			min-width: 24px;
			height: 24px;
			min-height: 24px;
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.32014 10.5733C8.16014 10.7867 7.84014 10.7867 7.68014 10.5733L5.48014 7.64C5.28237 7.37631 5.47052 7 5.80014 7L10.2001 7C10.5298 7 10.7179 7.37631 10.5201 7.64L8.32014 10.5733Z' fill='%23626A85' /%3E%3C/svg%3E");
			background-position: center;
			background-repeat: no-repeat;
			transition: var(--transition-base);
		}
	}

	&__content {
		padding: 0 24px 16px 24px;

		p {
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			color: var(--Grayscale-Gray-20);
			margin-bottom: 20px;
		}

		a {
			color: var(--Blue-Blue-Light);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 25.6px */
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}

		ul {
			padding: 0;
			margin: 0 0 20px 0;
			list-style-type: none;

			li {
				font-weight: 400;
				font-size: 16px;
				line-height: 150%;
				color: var(--Grayscale-Gray-20);
				position: relative;
				padding-left: 32px;

				&:before {
					content: '';
					display: block;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Ccircle cx='12' cy='12' r='3' fill='%23626A85'/%3E%3C/svg%3E");
					width: 24px;
					min-width: 24px;
					height: 24px;
					min-height: 24px;
					position: absolute;
					top: 1px;
					left: 0;
				}

				&:not(:last-of-type) {
					margin-bottom: 10px;
				}
			}
		}
	}
}

