.drop-file-preview {
	margin-top: 8px;
	display: flex;
	flex-flow: row wrap;
	gap: 8px;

	&__item {
		position: relative;
		margin-right: 0;
		border-radius: 6px;
		border: 1px solid var(--Grayscale-Gray-10);
		width: 72px;
		min-width: 72px;
		height: 91px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.24);

		img {
			width: 24px;
			margin-top: 25px;
		}
	}

	&__name {
		font-weight: 400;
		font-size: 12px;
		line-height: 133%;
		color: var(--Grayscale-Gray-20);
		width: 52px;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-top: 18px;
		text-align: center;
		text-wrap: nowrap;
	}

	&__item__del {
		background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.2224 1.90865C13.5348 1.59623 13.5348 1.0897 13.2224 0.777283C12.9099 0.464864 12.4034 0.464864 12.091 0.777283L6.99994 5.86834L1.90891 0.777307C1.59649 0.464887 1.08996 0.464887 0.777539 0.777307C0.465119 1.08973 0.465119 1.59626 0.777539 1.90868L5.86857 6.99971L0.777283 12.091C0.464864 12.4034 0.464864 12.9099 0.777284 13.2224C1.0897 13.5348 1.59623 13.5348 1.90865 13.2224L6.99994 8.13108L12.0912 13.2224C12.4037 13.5348 12.9102 13.5348 13.2226 13.2224C13.535 12.91 13.535 12.4034 13.2226 12.091L8.13131 6.99971L13.2224 1.90865Z' fill='%23626A85' /%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: center;
		width: 14px;
		min-width: 14px;
		height: 14px;
		min-height: 14px;
		transform: translate(0, 0);
		top: 4px;
		right: 4px;

		svg {
			display: none;
		}
	}
}

