.error-block {
	margin-bottom: 20px;
	border-radius: 6px;
	padding: 8px 16px;
	display: flex;
	align-items: flex-start;
	gap: 8px;
	background: var(--Grayscale-Gray-30);
	&__icon {
		min-width: 24px;
		width: 24px;
		height: 24px;
		background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.9998 1.70005C5.86356 1.70005 1.6998 5.8638 1.6998 11C1.6998 16.1363 5.86356 20.3 10.9998 20.3C16.1361 20.3 20.2998 16.1363 20.2998 11C20.2998 5.8638 16.1361 1.70005 10.9998 1.70005ZM0.299805 11C0.299805 5.0906 5.09036 0.300049 10.9998 0.300049C16.9093 0.300049 21.6998 5.0906 21.6998 11C21.6998 16.9095 16.9093 21.7001 10.9998 21.7001C5.09036 21.7001 0.299805 16.9095 0.299805 11ZM11.0002 4.20007C11.442 4.20007 11.8002 4.55825 11.8002 5.00007V14.0001C11.8002 14.4419 11.442 14.8001 11.0002 14.8001C10.5584 14.8001 10.2002 14.4419 10.2002 14.0001V5.00007C10.2002 4.55825 10.5584 4.20007 11.0002 4.20007ZM11.8002 17.0001C11.8002 16.5582 11.442 16.2001 11.0002 16.2001C10.5584 16.2001 10.2002 16.5582 10.2002 17.0001V17.5001C10.2002 17.9419 10.5584 18.3001 11.0002 18.3001C11.442 18.3001 11.8002 17.9419 11.8002 17.5001V17.0001Z' fill='%238D99C5' /%3E%3C/svg%3E");
		background-position: center;
		background-repeat: no-repeat;
		svg {
			display: none;
		}
	}

	p {
		font-weight: 400;
		font-size: 12px;
		line-height: 133%;
		color: var(--Grayscale-White);
	}
}

