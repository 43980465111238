.loading-screen {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background: var(--body-bg);

	&__spinner {
		width: 50px;
		height: 50px;
		border: 5px solid var(--Grayscale-Gray-10);
		border-top: 5px solid var(--Blue-Blue-Brand);
		border-radius: 50%;
		animation: loadingSpin 1s linear infinite;
	}
}

@keyframes loadingSpin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
