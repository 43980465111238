.pagination {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	gap: 4px;

	&__arrow {
		border-radius: 6px;
		background: var(--Grayscale-Gray-80);
		position: relative;

		&.disabled {
			a:before {
				opacity: 0.5;
			}
		}

		&--prev {
			a:before {
				content: '';
				display: block;
				width: 20px;
				height: 20px;
				background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.42668 8.32002C5.21334 8.16002 5.21334 7.84002 5.42668 7.68002L8.36001 5.48002C8.62371 5.28225 9.00001 5.4704 9.00001 5.80002L9.00001 10.2C9.00001 10.5296 8.62371 10.7178 8.36001 10.52L5.42668 8.32002Z' fill='%23626A85' /%3E%3C/svg%3E");
				background-repeat: no-repeat;
				background-position: center;
				background-size: 20px 20px;

				position: absolute;
				top: calc(50% - 10px);
				left: calc(50% - 10px);
			}
			&:not(.disabled):hover :before {
				background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.4266 8.32002C5.21327 8.16002 5.21327 7.84002 5.4266 7.68002L8.35994 5.48002C8.62363 5.28225 8.99994 5.4704 8.99994 5.80002L8.99993 10.2C8.99993 10.5296 8.62363 10.7178 8.35993 10.52L5.4266 8.32002Z' fill='%23FFFFFF' /%3E%3C/svg%3E");
			}
		}

		&--next {
			a:before {
				content: '';
				display: block;
				width: 20px;
				height: 20px;
				background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5733 7.68002C10.7867 7.84002 10.7867 8.16002 10.5733 8.32002L7.63998 10.52C7.37629 10.7178 6.99998 10.5296 6.99998 10.2L6.99998 5.80002C6.99998 5.4704 7.37629 5.28225 7.63998 5.48002L10.5733 7.68002Z' fill='%23626A85' /%3E%3C/svg%3E");
				background-repeat: no-repeat;
				background-position: center;
				background-size: 20px 20px;
				position: absolute;
				top: calc(50% - 10px);
				left: calc(50% - 10.5px);
			}
			&:not(.disabled):hover :before {
				background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5733 7.68002C10.7867 7.84002 10.7867 8.16002 10.5733 8.32002L7.64 10.52C7.37631 10.7178 7 10.5296 7 10.2L7 5.80002C7 5.4704 7.37631 5.28225 7.64 5.48002L10.5733 7.68002Z' fill='%23ffffff' /%3E%3C/svg%3E");
			}
		}
	}

	li {
		padding: 0;
		&.active > a,
		&.active > button,
		&:not(.disabled):hover > a,
		&:not(.disabled):hover > button {
			background: var(--Grayscale-Gray-30) !important;
			color: var(--Grayscale-Gray-100) !important;
		}
		a,
		button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 24px;
			min-width: 24px;
			height: 24px;
			min-height: 24px;
			margin: 0;
			padding: 0;
			border: 0;
			border-radius: 6px;
			background: transparent;
			color: var(--Grayscale-Gray-20);
			text-align: center;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			transition: var(--transition-base);
			background: var(--Grayscale-Gray-70);
		}
	}
	// > li.arrow {
	// 	svg {
	// 		display: none;
	// 	}
	// 	;
	// }
	.pagination__arrow--prev:hover {
		&:before {
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.4266 8.32002C5.21327 8.16002 5.21327 7.84002 5.4266 7.68002L8.35994 5.48002C8.62363 5.28225 8.99994 5.4704 8.99994 5.80002L8.99993 10.2C8.99993 10.5296 8.62363 10.7178 8.35993 10.52L5.4266 8.32002Z' fill='%23FFFFFF' /%3E%3C/svg%3E");
		}
	}
}

