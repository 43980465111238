.verification-item {
	flex-direction: column;
	display: flex;

	.verification-pending {
		display: flex;
		flex-direction: column;

		p {
			font-size: 16px;
			color: var(--Grayscale-Gray-10);
			font-weight: 300;
			line-height: 24px;
			margin-bottom: 12px;
			max-width: 722px;

			&:last-of-type {
				margin-bottom: 24px;
			}

			a {
				color: var(--Blue-Blue-Light);
				font-size: 16px;
				font-weight: 300;
				line-height: 24px;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}

		a {
			color: var(--Blue-Blue-Light);
		}
	}

	.verification-review {
		display: flex;
		flex-direction: column;
		gap: 16px;

		p {
			font-size: 16px;
			color: var(--Grayscale-Gray-10);
			font-weight: 300;
			line-height: 24px;
			max-width: 722px;

			a {
				color: var(--Blue-Blue-Light);
				font-size: 16px;
				font-weight: 300;
				line-height: 24px;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	&__link {
		max-width: fit-content;
	}
}
