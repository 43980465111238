.input-item {
	border-radius: 4px;
	border: 1px solid var(--Grayscale-Gray-80);
	background: var(--Grayscale-Gray-100);
	height: 48px;
	padding: 0 16px;
	font-size: 16px !important;
	font-weight: 400;
	color: var(--Grayscale-White);
	font-family: var(--font-sans);

	&::placeholder {
		font-size: 16px !important;
		font-weight: 400;
		color: var(--Grayscale-Gray-20);
		opacity: 1;
		font-family: var(--font-sans);
	}

	&--floating {
		padding: 0;
		border: 0;
		border-bottom: 1px solid var(--Grayscale-Gray-10);
		border-radius: 0;
		background-color: transparent;
		color: var(--Grayscale-White);
		font-size: 16px;
		font-style: normal;
		font-weight: 300;
		line-height: 24px;

		&::placeholder {
			color: transparent;
		}

		&:focus + label,
		&:not(:placeholder-shown) + label {
			top: -6px;
			font-size: 12px;
		}

		+ label {
			color: var(--Grayscale-Gray-10);
			font-size: 16px;
			font-style: normal;
			font-weight: 300;
			line-height: 1;
			position: absolute;
			top: 15px;
			left: 0;
			transition: var(--transition-base);
		}
	}
}
