.setting-content {
	width: 100%;
	max-width: 100%;
	margin: 0;
	display: grid;
	grid-template-columns: 1fr;
	gap: 24px;
	padding: 24px;
	background-color: var(--Grayscale-Gray-90);
	border-radius: 0 0 6px 6px;

	@media only screen and (max-width: 767.98px) {
		padding: 16px;
	}

	&--rounded {
		border-radius: 6px;
	}

	&--shadow {
		box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.24);
	}

	&__account {
		max-width: 660px;
		padding: 0;
	}

	/*@media only screen and (min-width: 768px) {
		margin: 0 0 250px 0;
	}*/

	&__title {
		color: var(--Grayscale-White);
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-bottom: 26px;
	}

	&__badge {
		border-radius: 20px;
		width: 79px;
		height: 27px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-bottom: 20px;

		&--green {
			color: var(--additional-green);
			border: 0.8px solid var(--additional-green);
			background: var(--transparent-green);
		}
	}
}
