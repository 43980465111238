.verification-upload {
	padding: 16px;
	border-radius: 10px;
	border: 1px dashed var(--Grayscale-Gray-10);
	box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.24);

	@media only screen and (min-width: 768px) {
		height: 100%;
		min-height: 268px;
	}

	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		cursor: pointer;

		@media only screen and (min-width: 768px) {
			height: 100%;
		}

		@media only screen and (max-width: 767.98px) {
			min-height: 200px;
		}
	}

	&__button-content {
		height: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 16px;
		color: var(--Grayscale-Gray-20);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		background: transparent;
		border-radius: 0;

		b {
			color: var(--Blue-Blue-Light);
			font-weight: 600;
		}
	}
}
