.wallet-content {
	background: var(--Grayscale-Gray-100);

	@media only screen and (min-width: 760px) {
		padding: 40px;
	}

	@media only screen and (min-width: 1200px) {
		padding: 18px 48px 0 48px;
	}
	&__setting {
		padding: 0;
	}
}

.wallet {
	.footer-nav ul {
		flex-direction: row;
	}
}

.content {
	min-height: 90vh;
}

