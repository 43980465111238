@charset "UTF-8";
a,
a:hover,
button,
button:hover {
	transition: 0.3s;
}
.input,
.input-wrapper {
	position: relative;
}
.input-action,
.input-icon {
	-ms-transform: translateY(-50%);
}
.input-icon .stroke path,
.show-pass .stroke path {
	stroke: #b6b6b6;
}
.select__drop-scroll,
.textarea textarea {
	scrollbar-color: #b6b6b6 transparent;
	scrollbar-width: thin;
}
.button,
button {
	cursor: pointer;
}
*,
.notification {
	box-sizing: border-box;
}
.pagination > li.arrow.disabled > a,
.pagination > li.arrow.disabled > button,
.slide-btn svg {
	pointer-events: none;
}
a,
abbr,
acronym,
address,
applet,
big,
blockquote,
body,
caption,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
iframe,
img,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
ul,
var {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-weight: inherit;
	font-style: inherit;
	font-size: 100%;
	font-family: inherit;
	vertical-align: baseline;
}
:focus,
a:focus,
button:focus,
img:focus,
input:focus,
li:focus {
	outline: 0;
}
body {
	color: #000;
}
ol,
ul {
	list-style: none;
}
table {
	border-collapse: separate;
	border-spacing: 0;
}
caption,
td,
th {
	text-align: left;
	font-weight: 400;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
	content: '';
}
blockquote,
q {
	quotes: '' '';
}
button {
	display: block;
	-webkit-appearance: none;
	appearance: none;
}
a {
	display: inline-block;
	text-decoration: none;
}
.input-item,
.input__name {
	display: block;
}
.checkbox__text a,
.text-block p a {
	text-decoration: underline !important;
}
input::-webkit-input-placeholder {
	opacity: 1;
}
input::-moz-placeholder {
	opacity: 1;
}
input:-moz-placeholder {
	opacity: 1;
}
input:-ms-input-placeholder {
	opacity: 1;
}
textarea::-webkit-input-placeholder {
	opacity: 1;
}
textarea::-moz-placeholder {
	opacity: 1;
}
textarea:-moz-placeholder {
	opacity: 1;
}
textarea:-ms-input-placeholder {
	opacity: 1;
}
input,
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
option,
select,
select:focus,
textarea {
	font-size: 16px;
	box-shadow: none;
}
:root {
	--ui-main-color: #d81159;
	--ui-button-bg: #d81159;
	--ui-button-bg-hover: #ee0259;
	--ui-color-grey: #a6b0c3;
	--ui-block-bg: #fff;
	--ui-border-color: #c9c9c9;
	--ui-border-light-color: #eeeeee;
	--ui-input-border-color: #b6b6b6;
	--ui-checkbox-border: #b6b6b6;
	--ui-checkbox-bg: #d81159;
	--ui-swith-color: #e1e0e0;
	--ui-text-color: #000;
	--ui-text-color-light: #8f9bba;
	--ui-text-color-grey: #9ea2a9;
	--ui-error-color: #eb5757;
	--ui-success-color: #27ae60;
	--ui-select-border: #b6b6b6;
	--ui-popup-bg: #fff;
	--ui-pagination-bg: #fff;
	--ui-pagination-border: #b6b6b6;
	--ui-pagination-color: #000;
	--ui-upload-box-color: #53c48a;
	--ui-progress-bg: #d4f0e2;
	--ui-progress-line: #88d39f;
	--ui-progress-label-bg: #1f2937;
	--ui--tooltip-bg: #1f2937;
	--text-color-main: #173b58;
	--text-color2: #777e90;
	--text-color3: #bbbec7;
	--accent-color: #3b97d3;
	--bg-color-main: #fff;
	--bg-color2: #fcfcfd;
	--button-text-main: #fff;
	--border-main-color: #e6e8ec;
}
svg {
	height: auto;
}
.input {
	width: 100%;
}
.input__notification {
	margin: 8px 0 0;
}
.input--success .input-item {
	border: 2px solid var(--ui-success-color);
}
.input--numb {
	width: 40px;
	margin: 0 8px;
}
.input--numb .input-item {
	padding: 0;
	border: none;
	border-bottom: 2px solid var(--ui-main-color);
	border-radius: 0;
	font-size: 24px;
	text-align: center;
}
.input--numb .input-item.active {
	border-color: var(--ui-input-border-color);
}
.input-action,
.input-icon,
.show-pass {
	position: absolute;
	top: 50%;
}
.input-item {
	width: 100%;
	outline: 0;
	line-height: normal;
	transition: all 0.15s ease;
}
.input-item.placeholder {
	color: #b6b6b6;
	transition: all 0.15s ease;
	font-size: 14px;
}
.input-item:-moz-placeholder {
	color: #b6b6b6;
	transition: all 0.15s ease;
	font-size: 14px;
}
.input-item::-moz-placeholder {
	color: #b6b6b6;
	transition: all 0.15s ease;
	font-size: 14px;
}
.input-item:-ms-input-placeholder {
	color: #b6b6b6;
	transition: all 0.15s ease;
	font-size: 14px;
}
.input-item::-webkit-input-placeholder {
	color: #b6b6b6;
	transition: all 0.15s ease;
	font-size: 14px;
}
.input-item--right-double-icon {
	padding-right: 60px;
}
.input-item--right-icon-and-action {
	padding-right: 90px;
}
.input-item--bigger {
	height: 52px;
	font-size: 16px;
}
.input-icon svg,
.show-pass svg {
	height: auto;
	width: 100%;
}
.input-icon {
	transform: translateY(-50%);
	display: -ms-flexbox;
	display: flex;
	left: 15px;
}
.input-icon--right {
	left: unset;
	right: 15px;
}
.input-icon--more-right {
	left: unset;
	right: 45px;
}
.input-action {
	transform: translateY(-50%);
	display: -ms-flexbox;
	display: flex;
	left: 15px;
	font-weight: 700;
	color: var(--ui-main-color);
}
.input-action--more-right {
	left: unset;
	right: 45px;
}
.input-action--right {
	left: unset;
	right: 15px;
}
.password-type {
	display: none;
}
input[type='password'] ~ .show-pass .password-type {
	display: block;
}
input[type='password'] ~ .show-pass .text-type {
	display: none;
}
.select,
.show-pass {
	display: -ms-flexbox;
}
.show-pass {
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 15px;
	display: flex;
}
.show-pass .fill path {
	fill: #b6b6b6;
}
.button__icon .fill path,
.checkbox__item-icon .fill path {
	fill: #fff;
}
.textarea {
	font: inherit;
	font-size: 16px;
	font-weight: 400;
}
.textarea textarea {
	display: block;
	width: 100%;
	background: 0 0;
	border: none;
	outline: 0 !important;
	resize: vertical;
}
.textarea textarea.placeholder {
	color: #b6b6b6;
	transition: all 0.15s ease;
}
.textarea textarea:-moz-placeholder {
	color: #b6b6b6;
	transition: all 0.15s ease;
}
.textarea textarea::-moz-placeholder {
	color: #b6b6b6;
	transition: all 0.15s ease;
}
.textarea textarea:-ms-input-placeholder {
	color: #b6b6b6;
	transition: all 0.15s ease;
}
.textarea textarea::-webkit-input-placeholder {
	color: #b6b6b6;
	transition: all 0.15s ease;
}
.textarea textarea::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}
.textarea textarea::-webkit-scrollbar-thumb {
	border-radius: 3px;
	background: #b6b6b6;
	cursor: pointer;
}
.select {
	position: relative;
	display: flex;
	-ms-flex-line-pack: center;
	align-content: center;
	background: #fff;
	z-index: 3;
}
.select__current {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	width: 100%;
}
.select__current-arrow {
	display: -ms-flexbox;
	display: flex;
	-ms-flex: 0 0 8px;
	flex: 0 0 8px;
	max-width: 8px;
	margin-left: 10px;
}
.button__arrow,
.select__img {
	display: -ms-flexbox;
	-ms-flex-align: center;
}
.select__current-arrow .stroke path {
	stroke: #424242;
}
.select__current-text {
	margin-right: auto;
}
.select__img {
	display: flex;
	width: 16px;
	align-items: center;
	margin-right: 12px;
}
.select__drop {
	display: none;
	position: absolute;
	top: calc(100% + 10px);
	width: 100%;
	z-index: 1;
}
.select__drop ul > li > a,
.select__drop ul > li > button {
	display: block;
	width: 100%;
	padding: 10px;
	border-radius: 4px;
	color: #000;
	font-size: 14px;
	transition: all 0.15s ease;
	text-align: left;
}
.authorization__title,
.popup-header__title,
.popup-text--center {
	text-align: center;
}
.select__drop ul > li > a:hover,
.select__drop ul > li > button:hover {
	background: #f4f4f4;
}
.select__drop-scroll {
	max-height: 180px;
	overflow: auto;
}
.select__drop-scroll::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}
.select__drop-scroll::-webkit-scrollbar-thumb {
	border-radius: 2px;
	background: #b6b6b6;
}
.select.active {
	z-index: 5;
}
.select.active .select__drop {
	display: block;
}
.select--small {
	padding: 0;
	border: 0;
	width: 62px;
	z-index: auto;
}
.select--small .select__drop {
	padding: 0;
	min-width: 100px;
}
.select--grey {
	background: #dadada;
	padding: 0 15px;
	width: 74px;
}
.select--light .select__current {
	color: #b6b6b6;
	font-weight: 300;
	font-size: 16px;
	line-height: 18px;
}
.select--big {
	height: 52px;
	font-size: 16px;
}
button {
	background: 0 0;
	border: none;
	outline: 0;
	padding: 0;
}
.button {
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-pack: center;
	justify-content: center;
	width: auto;
	padding: 0 15px;
	color: #fff;
}
.button--second-type {
	background: 0 0;
	border: 1px solid var(--ui-button-bg);
	color: #000;
}
.button--second-type:hover {
	background: var(--ui-button-bg);
	color: #fff;
}
.button--rounded {
	border-radius: 22px;
}
.button--less-height {
	height: 36px;
}
.button--bigger-height {
	height: 60px;
	font-size: 18px;
}
.button--full-width {
	width: 100%;
}
.button--small {
	width: 120px;
}
.button--regular {
	width: 160px;
}
.button--wide {
	width: 180px;
}
.button--wider {
	width: 200px;
}
.button--big {
	width: 240px;
}
.button:disabled {
	opacity: 0.5;
	cursor: default;
}
.button__text {
	display: block;
	padding-left: 16px;
}
.button__text--padding-none {
	padding-right: 0;
	padding-left: 0;
}
.button__arrow {
	display: flex;
	align-items: center;
	margin: 0 9px;
	width: 6px;
}
.button__icon {
	display: -ms-flexbox;
	display: flex;
	width: 12px;
	margin-left: 10px;
}
.checkbox {
	margin: 0 0 10px;
}
.checkbox__item {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex: 0 0 16px;
	flex: 0 0 16px;
	border-radius: 3px;
	cursor: pointer;
}
.checkbox__item-icon {
	display: -ms-flexbox;
	display: flex;
	width: 10px;
	opacity: 0;
}
.checkbox__label {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
.radio__item,
.radio__label {
	display: -ms-flexbox;
	-ms-flex-align: center;
}
.checkbox__text {
	margin-left: 10px;
	cursor: pointer;
}
.checkbox__text a {
	color: #00f;
}
.checkbox input:checked ~ .checkbox__item .checkbox__item-icon {
	opacity: 1;
}
.checkbox input:disabled ~ .checkbox__item {
	cursor: default;
	background: #b6b6b6;
}
.checkbox input:disabled ~ .checkbox__item + .checkbox__text {
	cursor: default;
}
.radio__item,
.radio__text {
	cursor: pointer;
}
.checkbox--no-mb {
	margin-bottom: 0;
}
.radio {
	margin: 0 0 10px;
}
.switch__label + .switch__text,
.switch__text + .switch__label {
	margin-left: 10px;
}
.radio__item {
	position: relative;
	display: flex;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex: 0 0 16px;
	flex: 0 0 16px;
}
.radio__item:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #fff;
	z-index: 2;
	opacity: 0;
}
.radio__label {
	display: flex;
	align-items: center;
}
.radio__text a {
	color: #00f;
}
.radio input:checked ~ .radio__item:after {
	opacity: 1;
}
.radio input:disabled ~ .radio__item {
	cursor: default;
	background: #b6b6b6;
}
.radio input:disabled ~ .radio__item + .radio__text {
	cursor: default;
}
.switch {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
.switch__toggler {
	position: relative;
	display: block;
	width: 50px;
	height: 26px;
	border: 1px solid var(--ui-swith-color);
	border-radius: 13px;
	cursor: pointer;
}
.switch__toggler:before {
	content: '';
	position: absolute;
	left: 2px;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 18px;
	height: 18px;
	background: var(--ui-swith-color);
	border-radius: 100%;
	transition: 0.3s;
}
.progress-bar-label:after,
.tooltip:after {
	content: '';
}
.switch input:checked ~ .switch__toggler:before {
	left: 26px;
	background: var(--ui-main-color);
}
.switch__text {
	font-size: 14px;
	font-weight: 500;
}
.switch--type2 .switch__toggler {
	background: var(--ui-swith-color);
}
.switch--type2 .switch__toggler:before {
	background: #fff;
}
.switch--type2 input:checked ~ .switch__toggler {
	background: var(--ui-main-color);
}
.switch--type2 input:checked ~ .switch__toggler:before {
	background: #fff;
}
.popup-open {
	position: relative;
	overflow: hidden;
}
.popup-window {
	position: fixed;
	display: block;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	overflow: auto;
	z-index: 201;
}
.popup-window__inside {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	min-height: 100%;
	padding: 20px;
}
.popup {
	position: relative;
	max-width: 100%;
	background: var(--ui-popup-bg);
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.popup__close {
	position: absolute;
	top: 15px;
	right: 15px;
	width: 16px;
}
.popup__close svg {
	width: 100%;
	height: auto;
}
.popup__close .fill path {
	fill: #000;
}
.popup-text {
	margin: 0 0 15px;
}
.popup-submit {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	margin-top: 30px;
}
.popup-submit--less-mt {
	margin-top: 15px;
}
.popup-submit--more-mt {
	margin-top: 45px;
}
.popup-submit__col {
	width: calc(50% - 10px);
}
.popup-submit--sb {
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media screen and (max-width: 767px) {
	.popup {
		padding: 30px 15px 20px;
	}
	.popup-header__title {
		font-size: 21px;
	}
}
.authorization-section {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	min-height: 100vh;
	padding: 30px 0;
}
.authorization-section__container {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
}
.authorization {
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
}
.authorization__photo img,
.notification {
	max-width: 100%;
}
.authorization__title {
	font-weight: 700;
	font-size: 24px;
}
.authorization__field + .authorization__field {
	margin-top: 20px;
}
.authorization__field--numb {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	margin-bottom: 40px;
}
.authorization__details {
	margin-top: 16px;
	font-size: 14px;
	text-align: center;
}
.authorization__details a,
.authorization__details button {
	color: var(--ui-main-color);
	font-weight: 700;
}
.authorization__form {
	margin-top: 34px;
}
.authorization__options {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-top: 10px;
}
.authorization__options-full {
	width: 100%;
}
.authorization__forgot-link {
	color: var(--ui-color-grey);
	font-size: 14px;
}
.authorization__photo {
	margin: 25px 0;
}
.form-submit {
	margin-top: 34px;
}
.notification-wrapper {
	position: absolute;
	right: 25px;
	top: 25px;
	z-index: -100;
	visibility: hidden;
	opacity: 0;
	transition: 0.3s;
}
.notification-wrapper.active {
	visibility: visible;
	opacity: 1;
	z-index: 10000;
}
.notification {
	position: relative;
	padding: 15px 20px;
	text-align: left;
	border-radius: 18px;
	box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 25px rgba(0, 0, 0, 0.15);
	background: #fff;
	-ms-transform: translateX(150%);
	transform: translateX(150%);
	transition: 0.5s;
}
.avatar__text,
.capthca-slide,
.section-title--centered {
	text-align: center;
}
.notification.active {
	-ms-transform: translateX(0);
	transform: translateX(0);
}
.notification + .notification {
	margin-top: 15px;
}
.notification--error .notification__text,
.notification--error .notification__title,
.notification--successful .notification__text,
.notification--successful .notification__title {
	color: #fff;
}
.notification--error .notification__close svg path,
.notification--successful .notification__close svg path {
	fill: #fff;
}
.notification--error {
	background: #eb5757;
}
.notification--icon-successful .notification__icon,
.notification--successful {
	background: #4bc0b1;
}
.notification--icon-error {
	display: -ms-flexbox;
	display: flex;
}
.notification--icon-successful {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: stretch;
	align-items: stretch;
	padding: 0;
}
.notification--icon-successful .notification__info {
	padding: 15px;
}
.notification__close {
	position: absolute;
	top: 10px;
	right: 10px;
}
.notification__title {
	font-weight: 600;
	font-size: 16px;
	color: #4c4c4c;
}
.notification__text {
	margin-top: 5px;
	font-size: 14px;
	line-height: 150%;
	color: #4c4c4c;
}
.block-title,
.captcha-text,
.h1,
.h2,
.h3,
.h4,
.h5,
.section-title,
.user-avatar__info-name {
	line-height: 1.4;
	font-weight: 700;
}
.notification__icon {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
	width: 62px;
	min-width: 62px;
	border-radius: 18px 0 0 18px;
}
.notification__icon svg {
	width: 30px;
	margin: 0 auto;
}
.pagination-block {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	margin-top: 60px;
}
.pagination-block--end {
	-ms-flex-pack: end;
	justify-content: flex-end;
}
.pagination-block--start {
	-ms-flex-pack: start;
	justify-content: flex-start;
}
.pagination {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: 0 -5px;
}
.pagination > li {
	padding: 0 5px;
}
.pagination > li > a,
.pagination > li > button {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-top: 10px;
	padding: 0 2px;
	border-radius: 3px;
	background: var(--ui-pagination-bg);
	color: var(--ui-pagination-color);
	transition: all 0.15s ease-in;
}
.pagination > li.arrow svg {
	/* width: 10px;
	height: auto; */
}
.pagination > li.arrow .stroke path {
	stroke: var(--ui-pagination-color);
	transition: all 0.15s ease-in;
}
.pagination > li:not(.disabled).active .stroke path,
.pagination > li:not(.disabled):hover .stroke path,
.slide-btn .stroke path,
.slide-btn.success .stroke path {
	stroke: #fff;
}
.pagination > li.arrow.disabled svg {
	opacity: 0.3;
}
.pagination > li:not(.disabled).active > a,
.pagination > li:not(.disabled).active > button,
.pagination > li:not(.disabled):hover > a,
.pagination > li:not(.disabled):hover > button {
	background: var(--ui-main-color);
	color: #fff;
	border-color: var(--ui-main-color);
}
.captcha-modal {
	position: relative;
	width: 360px;
	background-color: var(--ui-popup-bg);
	box-shadow: 0 0 19.32px 1.68px rgba(0, 0, 0, 0.06);
	border-radius: 4px;
	color: var(--ui-text-color);
	padding: 15px;
}
.captcha-cover {
	position: relative;
	height: 220px;
	padding: 0 15px;
}
.captcha-cover__item {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
}
.captcha-move,
.capthca-default {
	position: absolute;
	bottom: 20px;
	width: 60px;
	margin-left: 10px;
}
.captcha-text {
	position: absolute;
	top: 15px;
	left: 0;
	padding: 0 15px;
	z-index: 2;
	color: #fff;
	font-size: 20px;
}
.captcha-text span {
	color: var(--ui-main-color);
}
.captcha-move {
	left: 0;
	z-index: 2;
}
.capthca-default {
	left: 60%;
}
.capthca-slide-block {
	padding: 20px;
}
.capthca-slide {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 50px;
	padding-left: 40px;
	border-radius: 25px;
	background: #eeefee;
	border: 1px solid var(--ui-border-color);
	font-size: 15px;
	color: rgba(150, 150, 150, 0.5);
}
.avatar--no-photo,
.slide-btn {
	background: var(--ui-main-color);
}
.avatar,
.avatar img,
.slide-btn {
	border-radius: 50%;
}
.slide-btn {
	position: absolute;
	margin-left: -10px;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 64px;
	height: 64px;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	z-index: 2;
}
.slide-btn svg {
	width: 24px;
	height: auto;
}
.slide-btn.error .stroke path {
	stroke: #ed1c24;
}
.capthca-actions {
	display: -ms-flexbox;
	display: flex;
	padding: 15px 15px 0;
	width: calc(100% + 30px);
	margin-left: -15px;
	border-top: 1px solid var(--ui-border-color);
}
.capthca-actions__item,
.user-avatar {
	display: -ms-flexbox;
	-ms-flex-align: center;
}
.capthca-actions__item {
	display: flex;
	align-items: center;
}
.capthca-actions__item + .capthca-actions__item {
	margin-left: 15px;
}
.capthca-actions__item svg {
	width: 20px;
	height: auto;
}
.capthca-actions__item svg path {
	stroke: #121214;
}
.user-avatar {
	display: flex;
	align-items: center;
}
.user-avatar--xl-size .user-avatar__info-name {
	font-size: 16px;
}
.user-avatar--xl-size .user-avatar__info-text {
	font-size: 12px;
}
.user-avatar--m-size .user-avatar__info-name {
	font-size: 14px;
}
.user-avatar--m-size .user-avatar__info-text {
	font-size: 11px;
}
.user-avatar--s-size .user-avatar__info-name {
	font-size: 12px;
}
.user-avatar--s-size .user-avatar__info-text {
	font-size: 9px;
}
.user-avatar__info {
	padding-left: 10px;
}
.user-avatar__info-name {
	color: var(--ui-text-color);
}
.user-avatar__info-text {
	color: var(--ui-text-color-light);
}
.avatar {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.avatar img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.avatar--xl-size {
	width: 48px;
	height: 48px;
}
.avatar--m-size {
	width: 40px;
	height: 40px;
}
.avatar--m-size .avatar__text {
	font-size: 16px;
}
.avatar--s-size {
	width: 32px;
	height: 32px;
}
.avatar--s-size .avatar__text {
	font-size: 14px;
}
.avatar--xs-size {
	width: 24px;
	height: 24px;
}
.avatar--xs-size .avatar__text {
	font-size: 12px;
}
.avatar--xxs-size {
	width: 16px;
	height: 16px;
}
.avatar--xxs-size .avatar__text {
	font-size: 8px;
}
.avatar__text {
	color: #fff;
	font-weight: 700;
	font-size: 20px;
}
.avatars-group {
	display: -ms-flexbox;
	display: flex;
}
.avatars-group .avatar {
	border: 2px solid #fff;
	z-index: 10;
}
.avatars-group .avatar:nth-child(2) {
	z-index: 9;
}
.avatars-group .avatar:nth-child(3) {
	z-index: 7;
}
.avatars-group .avatar:nth-child(4) {
	z-index: 6;
}
.avatars-group .avatar:nth-child(5) {
	z-index: 5;
}
.avatars-group--xl-size .avatar + .avatar {
	margin-left: -20px;
}
.avatars-group--m-size .avatar + .avatar {
	margin-left: -15px;
}
.avatars-group--s-size .avatar + .avatar {
	margin-left: -10px;
}
.blog-section {
	padding: 45px 0;
}
.blog-container {
	margin: 0 auto;
}
.blog {
	padding-top: 20px;
}
.blog__row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: 0 -30px;
}
.blog__col {
	width: 33.3%;
	padding: 0 30px;
}
.blog-card {
	margin-top: 30px;
}
.blog-card__img {
	height: 201px;
}
.blog-card__img img {
	width: 100%;
	height: 100%;
	border-radius: 18px;
	object-fit: cover;
}
.blog-card__title {
	display: block;
	margin-top: 20px;
	font-size: 19px;
	color: var(--ui-text-color);
	font-weight: 700;
	transition: all 0.15s ease-in;
}
.blog-card__title:hover,
.text-block p a {
	color: var(--ui-main-color);
}
@media screen and (max-width: 991px) {
	.blog__row {
		margin: 0 -15px;
	}
	.blog__col {
		padding: 0 15px;
	}
	.blog-card {
		margin-top: 20px;
	}
	.blog-card__title {
		font-size: 17px;
	}
}
@media screen and (max-width: 767px) {
	.blog-section {
		padding: 30px 0;
	}
	.blog {
		padding-top: 10px;
	}
	.blog__col {
		width: 50%;
	}
}
@media screen and (max-width: 475px) {
	.blog__col {
		width: 100%;
	}
}
.section-title {
	font-size: 42px;
}
.section-title--right {
	text-align: right;
}
.block-title {
	font-size: 24px;
}
.block-title--centered {
	text-align: center;
}
.block-title--right {
	text-align: right;
}
.h1 {
	font-size: 42px;
}
.h2 {
	font-size: 36px;
}
.h3 {
	font-size: 30px;
}
.h4 {
	font-size: 24px;
}
.h5 {
	font-size: 18px;
}
.text-block {
	margin-top: 30px;
	line-height: 2;
	font-size: 15px;
}
.text-block--mt15 {
	margin-top: 15px;
}
.text-block--no-mt {
	margin-top: 0;
}
.text-block p + p {
	margin-top: 15px;
}
.text-block p a {
	font-weight: 700;
}
.text-block--centered {
	text-align: center;
}
.text-block--right {
	text-align: right;
}
.upload-file,
.upload-photo {
	text-align: center;
}
.bold {
	font-weight: 700;
}
.loader-modal {
	position: fixed;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.65);
	z-index: 210;
}
.loader-circle,
.upload-file-box {
	position: relative;
	display: -ms-flexbox;
}
.loader-circle {
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.loader-circle svg {
	width: 100px;
	height: 100px;
	overflow: visible;
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
}
.loader-circle__item {
	width: 100px;
	height: 100px;
	stroke-dasharray: 314;
}
.loader-circle__item--type1 {
	fill: none;
	stroke-width: 6px;
	stroke: rgba(255, 255, 255, 0.2);
}
.loader-circle__item--type2 {
	fill: none;
	stroke-width: 8px;
	stroke: var(--ui-main-color);
	stroke-linecap: round;
	-ms-transform-origin: center center;
	transform-origin: center center;
	animation: loader-stroke 3s infinite linear,
		loader-rotation 2s infinite cubic-bezier(0.45, 0.85, 0.72, 0.71);
}
.loader-circle--md svg {
	width: 70px;
	height: 70px;
}
.loader-circle--md .loader-circle__item {
	width: 70px;
	height: 70px;
	stroke-dasharray: 220px;
}
.loader-circle--md .loader-circle__item--type1 {
	stroke-width: 5px;
}
.loader-circle--md .loader-circle__item--type2 {
	stroke-width: 6px;
	animation: loader-stroke-md 2.6s infinite linear,
		loader-rotation 1.8s infinite cubic-bezier(0.45, 0.85, 0.72, 0.71);
}
.loader-circle--sm svg {
	width: 40px;
	height: 40px;
}
.loader-circle--sm .loader-circle__item {
	width: 40px;
	height: 40px;
	stroke-dasharray: 126px;
}
.loader-circle--sm .loader-circle__item--type1 {
	stroke-width: 3px;
}
.loader-circle--sm .loader-circle__item--type2 {
	stroke-width: 4px;
	animation: loader-stroke-sm 2.4s infinite linear,
		loader-rotation 1.6s infinite cubic-bezier(0.45, 0.85, 0.72, 0.71);
}
@keyframes loader-rotation {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes loader-stroke {
	0% {
		stroke-dashoffset: 314;
	}
	100% {
		stroke-dashoffset: -314;
	}
}
@keyframes loader-stroke-md {
	0% {
		stroke-dashoffset: 220;
	}
	100% {
		stroke-dashoffset: -220;
	}
}
@keyframes loader-stroke-sm {
	0% {
		stroke-dashoffset: 126;
	}
	100% {
		stroke-dashoffset: -126;
	}
}
.upload-file-box {
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 220px;
	width: 370px;
	overflow: hidden;
	border: 1px dashed var(--ui-input-border-color);
	border-radius: 10px;
	transition: all 0.15s ease-in;
	cursor: pointer;
}
.upload-file-box:hover {
	border-color: var(--ui-upload-box-color);
}
.upload-file-box:hover .upload-file__label {
	color: var(--ui-upload-box-color);
}
.upload-file-box:focus {
	border-style: solid;
}
.upload-file-box--error {
	border-color: var(--ui-error-color) !important;
}
.upload-file-box--error .upload-file__label {
	color: var(--ui-text-color) !important;
}
.error-text {
	margin-top: 4px;
	font-size: 14px;
	color: var(--ui-error-color);
}
.file-input {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	text-indent: -999px;
	z-index: 5;
}
.upload-photo-box,
.upload-photo-wrap {
	position: relative;
}
.upload-file {
	width: 100%;
	padding: 20px 40px;
}
.upload-file__icon {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	width: 30px;
	margin: 0 auto;
}
.upload-file__icon svg {
	width: 100%;
	height: auto;
}
.upload-file__title {
	display: block;
	margin-top: 15px;
	font-size: 18px;
	font-weight: 500;
	color: var(--ui-text-color);
}
.upload-file__text {
	display: block;
	margin-top: 15px;
	font-size: 14px;
	color: var(--ui-text-color-light);
}
.upload-file__label {
	display: inline-block;
	margin-top: 16px;
	padding: 6px 20px;
	border: 1px solid var(--ui-input-border-color);
	border-radius: 4px;
	box-shadow: 0 1px 2px rgba(6, 25, 56, 0.05);
	font-size: 14px;
	font-weight: 700;
	transition: all 0.15s ease-in;
}
.header-nav-item__drop,
.main-coint-list-item,
.react-datepicker,
.select__drop {
	box-shadow: 0 0 2px rgba(40, 41, 61, 0.04), 0 4px 8px rgba(96, 97, 112, 0.16);
}
.upload-file .progress-bar {
	margin-top: 16px;
}
.upload-photo-box {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 170px;
	width: 260px;
	overflow: hidden;
	border: 1px dashed var(--ui-input-border-color);
	border-radius: 4px;
	transition: all 0.15 ease-in;
	cursor: pointer;
}
.upload-photo-box--error,
.upload-photo-box--preview {
	border-color: transparent;
}
.upload-photo-box .loader-circle__item--type1 {
	stroke: #e0e0e0;
}
.upload-photo-box--error {
	background: #fff4f4;
}
.upload-photo-box--error .upload-photo__info {
	color: var(--ui-error-color);
}
.upload-photo__icon {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin: 0 auto;
	width: 55px;
}
.upload-photo__icon svg {
	width: 100%;
	height: auto;
}
.upload-photo__icon--error {
	height: 55px;
	border: 2px solid #eed5d5;
	border-radius: 50%;
}
.upload-photo__icon--error svg {
	width: 30px;
}
.upload-photo__info {
	display: block;
	margin-top: 16px;
	font-size: 13px;
	color: #9ea2a9;
}
.upload-photo__info-text {
	display: block;
	margin-top: 4px;
	font-weight: 500;
}
.upload-photo-preview {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	height: 100%;
}
.upload-photo-preview img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.upload-photo-hint {
	margin-top: 4px;
	font-size: 12px;
	color: var(--ui-text-color-grey);
}
.upload-photo-action {
	position: absolute;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	right: 10px;
	bottom: 10px;
	z-index: 6;
}
.progress-bar-label,
.tooltip--top {
	bottom: calc(100% + 10px);
}
.upload-photo-action__btn {
	position: relative;
	overflow: hidden;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 3px;
	cursor: pointer;
}
.upload-photo-action__btn + .upload-photo-action__btn {
	margin-left: 10px;
}
.upload-photo-action__btn--edit {
	background: var(--ui-main-color);
}
.upload-photo-action__btn--edit svg {
	width: 15px;
}
.upload-photo-action__btn--edit .fill path {
	fill: #fff;
}
.upload-photo-action__btn--remove {
	background: #fff;
}
.upload-photo-action__btn--remove svg {
	width: 11px;
}
.upload-photo-action__btn--remove .stroke path {
	stroke: var(--ui-text-color);
}
.upload-photo-action__btn svg {
	height: auto;
}
.file-attachment {
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin: 15px 0;
}
.file-attachment label {
	cursor: pointer;
}
.file-attachment__title {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 500;
	color: var(--ui-main-color);
}
.progress-bar__line,
.progress-bar__line-active {
	display: block;
	border-radius: 5px;
}
.file-attachment__title svg {
	width: 15px;
	margin-left: 5px;
}
.file-attachment__title .stroke path {
	stroke: var(--ui-main-color);
}
.file-attachment__text {
	margin-top: 6px;
	font-size: 14px;
	color: var(--ui-text-color-light);
}
.progress-bar {
	position: relative;
	display: block;
}
.progress-bar__line {
	display: block;
	position: relative;
	height: 5px;
}
.progress-bar__line-active {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	height: 100%;
	transition: all 0.15s linear;
}
.progress-bar__header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-bottom: 10px;
}
.progress-bar-label {
	position: absolute;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 5px;
	margin-left: -30px;
	height: 30px;
	width: 60px;
	border-radius: 8px;
	text-align: center;
	color: #fff;
	font-size: 12px;
	font-weight: 400;
	background: var(--ui-progress-label-bg);
}
.progress-bar-label:after {
	position: absolute;
	left: 50%;
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	top: 100%;
	border: 7px solid transparent;
	border-top: 6px solid var(--ui-progress-label-bg);
}
.faq-box {
	margin-top: 50px;
	padding: 50px 15px;
	background: #f2f2f2;
	border-radius: 18px;
}
.faq-list {
	max-width: 650px;
	margin: 0 auto;
}
.faq {
	padding: 15px;
	background: #fff;
	border-radius: 10px;
}
.faq__header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
.faq__header-title {
	-ms-flex: 1 1 0px;
	flex: 1 1 0;
}
.faq__header-btn {
	display: -ms-flexbox;
	display: flex;
	-ms-flex: 0 0 16px;
	flex: 0 0 16px;
	max-width: 16px;
}
.faq__text {
	display: none;
	font-size: 14px;
}
.faq.active .faq__header-btn {
	-ms-transform: rotate(-180deg);
	transform: rotate(-180deg);
}
.faq.active .faq__text {
	display: block;
	margin-top: 10px;
	padding-top: 10px;
	border-color: var(--ui-border-light-color);
}
@media screen and (max-width: 767px) {
	.faq-box {
		margin-top: 30px;
		padding: 30px 15px;
	}
}
.tooltip-wrap--right-align {
	text-align: right;
}
.tooltip-wrap--center-align {
	text-align: center;
}
.tooltip-item {
	position: relative;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.tooltip-item__title {
	font-weight: 600;
}
.tooltip-item:hover .tooltip {
	display: block;
}
.tooltip {
	position: absolute;
	display: none;
	width: 310px;
	background: var(--ui--tooltip-bg);
	border-radius: 8px;
	padding: 8px 12px;
	z-index: 10;
}
.tooltip__text {
	display: block;
	text-align: left;
	color: #fff;
	font-size: 12px;
}
.tooltip:after {
	position: absolute;
	border: 6px solid transparent;
	border-bottom: 6px solid var(--ui--tooltip-bg);
}
.tooltip--bottom {
	top: calc(100% + 10px);
}
.tooltip--center {
	left: 50%;
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}
.tooltip--left {
	left: 0;
}
.tooltip--right {
	right: 0;
}
.tooltip--arrow-top-left:after {
	top: -12px;
	left: 20px;
}
.tooltip--arrow-top-right:after {
	top: -12px;
	right: 20px;
}
.tooltip--arrow-top-center:after {
	top: -12px;
	left: 50%;
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}
.tooltip--arrow-bottom-left:after {
	bottom: -12px;
	left: 20px;
	-ms-transform: rotate(-180deg);
	transform: rotate(-180deg);
}
.tooltip--arrow-bottom-right:after {
	bottom: -12px;
	right: 20px;
	-ms-transform: rotate(-180deg);
	transform: rotate(-180deg);
}
.tooltip--arrow-bottom-center:after {
	bottom: -12px;
	left: 50%;
	-ms-transform: translateX(-50%) rotate(-180deg);
	transform: translateX(-50%) rotate(-180deg);
}
@media screen and (max-width: 475px) {
	.tooltip {
		max-width: 280px;
		font-size: 11px;
	}
}
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
	border-color: var(--text-color-main);
	border-style: solid;
	border-width: 3px 3px 0 0;
	content: '';
	display: block;
	height: 7px;
	position: absolute;
	width: 7px;
}
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
	margin-left: -4px;
	position: absolute;
	width: 0;
}
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
	box-sizing: content-box;
	position: absolute;
	border: 8px solid transparent;
	height: 0;
	width: 1px;
	content: '';
	z-index: -1;
	left: -8px;
}
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
	top: 0;
	margin-top: -8px;
}
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
	border-top: none;
	border-bottom-color: #f0f0f0;
}
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
	top: 0;
}
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
	top: -1px;
	border-bottom-color: #aeaeae;
}
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
	bottom: 0;
	margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
	border-bottom: none;
	border-top-color: #fff;
}
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
	bottom: 0;
}
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
	bottom: -1px;
	border-top-color: #aeaeae;
}
.react-datepicker-wrapper {
	display: block;
	padding: 0;
	border: 0;
	width: 100%;
}
.react-datepicker {
	font-family: inherit !important;
	font-size: 0.8rem;
	background-color: #fff;
	color: #000;
	border: 1px solid #e6e8ec;
	border-radius: 4px;
	display: inline-block;
	position: relative;
	padding: 16px;
}
body,
body button,
body input,
body textarea {
	font-family: Lato, sans-serif;
}
.react-datepicker--time-only .react-datepicker__triangle {
	left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
	border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
	border-bottom-left-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}
.react-datepicker__triangle {
	position: absolute;
	left: 50px;
	display: none;
}
.react-datepicker-popper {
	z-index: 1;
}
.react-datepicker-popper[data-placement^='bottom'] {
	padding-top: 10px;
	top: calc(100% + 2px) !important;
	-ms-transform: none !important;
	transform: none !important;
}
.react-datepicker-popper[data-placement='bottom-end'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement='top-end'] .react-datepicker__triangle {
	left: auto;
	right: 50px;
}
.react-datepicker-popper[data-placement^='top'] {
	bottom: calc(100% + 2px) !important;
	top: unset !important;
	-ms-transform: none !important;
	transform: none !important;
	padding-bottom: 10px;
}
.react-datepicker-popper[data-placement^='right'] {
	padding-left: 8px;
}
.react-datepicker-popper[data-placement^='right'] .react-datepicker__triangle {
	left: auto;
	right: 42px;
}
.react-datepicker-popper[data-placement^='left'] {
	padding-right: 8px;
}
.react-datepicker-popper[data-placement^='left'] .react-datepicker__triangle {
	left: 42px;
	right: auto;
}
.react-datepicker__header {
	text-align: center;
	background-color: #fff;
	padding: 0;
	position: relative;
}
.react-datepicker__header--time {
	padding-bottom: 8px;
	padding-left: 5px;
	padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
	border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
	border-top-right-radius: 4px;
}
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__year-dropdown-container--select {
	display: inline-block;
	margin: 0 2px;
}
.react-datepicker__current-month {
	margin-bottom: 8px;
}
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__current-month {
	margin-top: 0;
	color: #363a40;
	font-weight: 700;
	font-size: 16px;
}
.react-datepicker-time__header {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.react-datepicker__navigation {
	-ms-flex-align: center;
	align-items: center;
	background: 0 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
	cursor: pointer;
	position: absolute;
	top: 15px;
	padding: 0;
	border: none;
	z-index: 1;
	height: 20px;
	width: 20px;
	text-indent: -999em;
	overflow: hidden;
}
.react-datepicker__navigation-icon {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	background: #fff;
	top: unset;
	right: unset;
}
.react-datepicker__navigation-icon svg {
	width: 7px;
	height: auto;
}
.react-datepicker__navigation-icon::before {
	display: none !important;
}
.react-datepicker__navigation--previous {
	left: 25px;
}
.react-datepicker__navigation--next {
	right: 25px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
	right: 85px;
}
.react-datepicker__navigation--years {
	position: relative;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.react-datepicker__navigation--years-previous {
	top: 4px;
}
.react-datepicker__navigation--years-upcoming {
	top: -4px;
}
.react-datepicker__navigation:hover ::before {
	border-color: var(--accent-color);
}
.react-datepicker__navigation-icon--next::before {
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	left: -7px;
}
.react-datepicker__navigation-icon--previous::before {
	-ms-transform: rotate(225deg);
	transform: rotate(225deg);
	right: -7px;
}
.react-datepicker__month-container {
	float: left;
}
.react-datepicker__year {
	margin: 0.4rem;
	text-align: center;
}
.react-datepicker__year-wrapper {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
	display: inline-block;
	width: 4rem;
	margin: 2px;
}
.react-datepicker__month {
	margin: 0.4rem;
	text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
	display: inline-block;
	width: 4rem;
	margin: 2px;
}
.react-datepicker__input-time-container {
	clear: both;
	width: 100%;
	float: left;
	margin: 5px 0 10px 15px;
	text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption,
.react-datepicker__input-time-container .react-datepicker-time__input-container {
	display: inline-block;
}
.react-datepicker__input-time-container
	.react-datepicker-time__input-container
	.react-datepicker-time__input {
	display: inline-block;
	margin-left: 10px;
}
.react-datepicker__input-time-container
	.react-datepicker-time__input-container
	.react-datepicker-time__input
	input {
	width: auto;
}
.react-datepicker__input-time-container
	.react-datepicker-time__input-container
	.react-datepicker-time__input
	input[type='time']::-webkit-inner-spin-button,
.react-datepicker__input-time-container
	.react-datepicker-time__input-container
	.react-datepicker-time__input
	input[type='time']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.react-datepicker__input-time-container
	.react-datepicker-time__input-container
	.react-datepicker-time__input
	input[type='time'] {
	-moz-appearance: textfield;
}
.react-datepicker__input-time-container
	.react-datepicker-time__input-container
	.react-datepicker-time__delimiter {
	margin-left: 5px;
	display: inline-block;
}
.react-datepicker__time-container {
	float: right;
	border-left: 1px solid #aeaeae;
	width: 85px;
}
.react-datepicker__time-container--with-today-button {
	display: inline;
	border: 1px solid #aeaeae;
	border-radius: 0.3rem;
	position: absolute;
	right: -72px;
	top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
	position: relative;
	background: #fff;
	border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
	width: 85px;
	overflow-x: hidden;
	margin: 0 auto;
	text-align: center;
	border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list {
	list-style: none;
	margin: 0;
	height: calc(195px + (1.7rem / 2));
	overflow-y: scroll;
	padding-right: 0;
	padding-left: 0;
	width: 100%;
	box-sizing: content-box;
}
.inside,
.toggler,
section {
	box-sizing: border-box;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item {
	height: 30px;
	padding: 5px 10px;
	white-space: nowrap;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item:hover {
	cursor: pointer;
	background-color: #f0f0f0;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected {
	background-color: #216ba5;
	color: #fff;
	font-weight: 700;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected:hover {
	background-color: #216ba5;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--disabled {
	color: #ccc;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--disabled:hover {
	cursor: default;
	background-color: transparent;
}
.react-datepicker__week-number {
	color: #ccc;
	display: inline-block;
	width: 1.7rem;
	line-height: 1.7rem;
	text-align: center;
	margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
	cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
	border-radius: 0.3rem;
	background-color: #f0f0f0;
}
.react-datepicker__day-names,
.react-datepicker__week {
	white-space: nowrap;
}
.react-datepicker__week {
	position: relative;
	z-index: 2;
}
.react-datepicker__day-names {
	margin-bottom: -8px;
}
.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
	color: var(--text-color-main);
	display: inline-block;
	width: 24px;
	line-height: 24px;
	text-align: center;
	margin: 2px;
}
.react-datepicker__day--outside-month {
	color: #777e90;
}
.react-datepicker__day-name {
	font-weight: 500;
	font-size: 10px;
	color: #777e90;
}
.react-datepicker__day--in-range {
	position: relative;
}
.react-datepicker__day--in-range:before {
	content: '';
	position: absolute;
	left: -75%;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 115%;
	height: 100%;
	display: none;
	background: var(--accent-color);
	z-index: -1;
}
.react-datepicker__day--in-range + .react-datepicker__day--in-range:before {
	display: block;
}
.react-datepicker__day--range-start.react-datepicker__day--in-range {
	border-top-left-radius: 50% !important;
	border-bottom-left-radius: 50% !important;
}
.react-datepicker__day--range-end {
	background: #2579ba !important;
}
.react-datepicker__day--range-end:hover {
	background: #226a99 !important;
}
.react-datepicker__day--range-end.react-datepicker__day--in-range {
	border-top-right-radius: 50% !important;
	border-bottom-right-radius: 50% !important;
}
.react-datepicker__month--in-range,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--selected,
.react-datepicker__quarter--in-range,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--selected {
	border-radius: 0.3rem;
	background-color: #216ba5;
	color: #fff;
}
.react-datepicker__month--in-range:hover,
.react-datepicker__month--in-selecting-range:hover,
.react-datepicker__month--selected:hover,
.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--selected:hover {
	background-color: #226a99;
}
.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
	color: #ccc;
	pointer-events: none;
}
.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
	cursor: default;
	background-color: transparent;
}
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
	cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
	border-radius: 50%;
	background-color: #f0f0f0;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
	font-weight: 700;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
	border-radius: 50%;
	background-color: #3dcc4a;
	color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
	background-color: #32be3f;
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
	color: #ff00ff;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
	color: green;
}
.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--selected,
.react-datepicker__month-text--in-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--in-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__year-text--in-range,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--selected {
	border-radius: 50%;
	background-color: var(--accent-color);
	color: #fff;
}
.react-datepicker__day--in-range:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--selected:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--selected:hover {
	background-color: #226a99;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
	border-radius: 50%;
	background-color: #2579ba;
	color: #fff;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
	background-color: #226a99;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
	background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range
	.react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range
	.react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range
	.react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range
	.react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
	background-color: #f0f0f0;
	color: #000;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
	cursor: default;
	color: #ccc;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
	background-color: transparent;
}
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover {
	background-color: #216ba5;
}
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
	background-color: #f0f0f0;
}
.react-datepicker__input-container {
	position: relative;
	display: block;
	width: 100%;
}
.react-datepicker__input-container input {
	width: 100%;
	height: 46px;
	border: 1px solid var(--border-main-color);
	border-radius: 8px;
	padding: 0 16px;
	font-size: 16px;
	font-weight: 500;
	color: var(--text-color-main);
}
.react-datepicker__input-container input::-webkit-input-placeholder {
	font-size: 14px;
	color: var(--text-color3);
	line-height: 150%;
}
.react-datepicker__input-container input:-ms-input-placeholder {
	font-size: 14px;
	color: var(--text-color3);
	line-height: 150%;
}
.react-datepicker__input-container input::placeholder {
	font-size: 14px;
	color: var(--text-color3);
	line-height: 150%;
}
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view,
.react-datepicker__year-read-view {
	border: 1px solid transparent;
	border-radius: 0.3rem;
	position: relative;
}
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover,
.react-datepicker__year-read-view:hover {
	cursor: pointer;
}
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow {
	border-top-color: #b3b3b3;
}
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
	-ms-transform: rotate(135deg);
	transform: rotate(135deg);
	right: -16px;
	top: 0;
}
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown,
.react-datepicker__year-dropdown {
	background-color: #f0f0f0;
	position: absolute;
	width: 50%;
	left: 25%;
	top: 30px;
	z-index: 1;
	text-align: center;
	border-radius: 0.3rem;
	border: 1px solid #aeaeae;
}
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover,
.react-datepicker__year-dropdown:hover {
	cursor: pointer;
}
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable,
.react-datepicker__year-dropdown--scrollable {
	height: 150px;
	overflow-y: scroll;
}
.react-datepicker__month-option,
.react-datepicker__month-year-option,
.react-datepicker__year-option {
	line-height: 20px;
	width: 100%;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type,
.react-datepicker__year-option:first-of-type {
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
}
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type,
.react-datepicker__year-option:last-of-type {
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border-bottom-left-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover,
.react-datepicker__year-option:hover {
	background-color: #ccc;
}
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming {
	border-bottom-color: #b3b3b3;
}
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous {
	border-top-color: #b3b3b3;
}
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected,
.react-datepicker__year-option--selected {
	position: absolute;
	left: 15px;
}
.react-datepicker__close-icon {
	cursor: pointer;
	background-color: transparent;
	border: 0;
	outline: 0;
	padding: 0 6px 0 0;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	display: table-cell;
	vertical-align: middle;
}
.react-datepicker__close-icon::after {
	cursor: pointer;
	background-color: #216ba5;
	color: #fff;
	border-radius: 50%;
	height: 16px;
	width: 16px;
	padding: 2px;
	font-size: 12px;
	line-height: 1;
	text-align: center;
	display: table-cell;
	vertical-align: middle;
	content: '×';
}
.react-datepicker__today-button {
	background: #f0f0f0;
	border-top: 1px solid #aeaeae;
	cursor: pointer;
	text-align: center;
	font-weight: 700;
	padding: 5px 0;
	clear: left;
}
.react-datepicker__portal {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.8);
	left: 0;
	top: 0;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	display: -ms-flexbox;
	display: flex;
	z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__time-name {
	width: 3rem;
	line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
	.react-datepicker__portal .react-datepicker__day,
	.react-datepicker__portal .react-datepicker__day-name,
	.react-datepicker__portal .react-datepicker__time-name {
		width: 2rem;
		line-height: 2rem;
	}
}
.react-datepicker__portal .react-datepicker-time__header,
.react-datepicker__portal .react-datepicker__current-month {
	font-size: 1.44rem;
}
.button {
	min-width: 105px;
	height: 52px;
	-ms-flex-align: center;
	align-items: center;
	background: var(--accent-color);
	font-size: 20px;
	font-weight: 600;
	line-height: 1;
	border-radius: 8px;
	gap: 16px;
	color: var(--button-text-main);
	border: none;
	transition: 0.2s;
}
.button svg {
	min-width: 15px;
}
.button:hover {
	background: #226a99;
}
.button:disabled {
	color: #fff;
	border-color: #e6e8ec;
	background: #e6e8ec;
}
.button--type2,
.button--type2:hover {
	color: var(--accent-color);
	background: 0 0;
}
.button--type2,
.textarea {
	border: 1px solid var(--border-main-color);
}
.button--type2:hover {
	border-color: var(--accent-color);
}
.button--type3 {
	color: var(--text-color2);
	border: none;
	background: 0 0;
}
.button--type3.active,
.button--type3:hover {
	color: #fff;
	background: var(--accent-color);
}
.button--type-icon {
	padding: 10px;
	min-width: 44px;
	width: 44px;
	height: 44px;
	background: #3b97d3;
	border-radius: 8px;
}
.button--type-text,
.button--type-text:hover {
	color: var(--accent-color);
	border: none;
	background: 0 0;
}
.button--size2 {
	font-size: 16px;
	height: 40px;
}
.button--size3 {
	min-width: 125px;
	font-size: 14px;
	height: 48px;
}
.button--size4 {
	min-width: 80px;
	font-size: 16px;
	height: 36px;
}
.button--type-text {
	font-weight: 700;
	font-size: 14px;
	height: auto;
	width: auto;
	min-width: auto;
	padding: 0;
	line-height: 1;
}
.button--width-280 {
	width: 280px;
}
.button--width-320 {
	width: 320px;
}
.input {
	margin-bottom: 24px;
	text-align: left;
}
.input--radio {
	margin: 8px 0;
	padding-left: 32px;
}
.input__name {
	margin-bottom: 8px;
	font-size: 14px;
	color: var(--text-color-main);
	line-height: 1.8;
	font-weight: 400;
}
.input__notification {
	font-weight: 400;
	font-size: 12px;
	line-height: 180%;
	color: #e04141;
}
.input__notification a {
	font-size: 14px;
	color: #4376fe;
}
.input__notification ul {
	padding-left: 10px;
	list-style-type: disc;
	list-style-position: inside;
}
.input__notification--type2 {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-align: center;
	align-items: center;
}
.input__notification--type2 a {
	font-size: 12px;
}
.input__notification--type3 {
	margin-top: 16px;
	display: -ms-flexbox;
	display: flex;
	gap: 4px;
	padding: 6px;
	background: #feeceb;
}
.input__notification--type3 span {
	margin-right: 5px;
	min-width: 20px;
	display: block;
}
.input__notification--type3 span svg {
	display: block;
	width: 100%;
	height: auto;
}
.input__notification--type4 {
	font-weight: 500;
	font-size: 14px;
	color: #777e90;
}
.input__notification--type4 span,
.textarea textarea {
	color: var(--text-color-main);
}
.input--error .input-item,
.input--error .textarea {
	border: 1px solid #e04141;
}
.textarea {
	border-radius: 8px;
	padding: 12px 16px;
}
.textarea textarea {
	min-height: 70px;
	font-size: 16px;
	font-weight: 500;
}
.textarea textarea::-webkit-input-placeholder {
	font-size: 14px;
	color: var(--text-color3);
	line-height: 150%;
}
.textarea textarea:-ms-input-placeholder {
	font-size: 14px;
	color: var(--text-color3);
	line-height: 150%;
}
.textarea textarea::placeholder {
	font-size: 14px;
	color: var(--text-color3);
	line-height: 150%;
}
.input-item {
	height: 46px;
	border: 1px solid var(--border-main-color);
	border-radius: 8px;
	padding: 0 16px;
	font-size: 16px;
	font-weight: 500;
	color: var(--text-color-main);
}
.input-item::-webkit-input-placeholder {
	color: var(--text-color3);
	line-height: 150%;
}
.input-item:-ms-input-placeholder {
	color: var(--text-color3);
	line-height: 150%;
}
.input-item::placeholder {
	font-size: 14px;
	color: var(--text-color3);
	line-height: 150%;
}
.input-item[type='password'] {
	font-size: 14px;
}
.input-item--right-icon {
	padding-right: 45px;
}
.input-item--left-icon {
	padding-left: 45px;
}
.input-item--left-content {
	padding-right: 100px;
}
.input-item--pr25 {
	padding-right: 25px;
}
.input-item--pr50 {
	padding-right: 50px;
}
.input-item--pr75 {
	padding-right: 75px;
}
.input-item--pr100 {
	padding-right: 100px;
}
.input-item--pr125 {
	padding-right: 125px;
}
.input-item--pr150 {
	padding-right: 150px;
}
.input-item--pr175 {
	padding-right: 175px;
}
.input-item--type2 {
	width: 165px;
	min-width: 165px;
	height: 40px;
}
.input-item--type3 {
	border-radius: 8px 0 0 8px;
	border-right: none;
}
.input-item--right {
	text-align: right;
}
.input-icon {
	width: 20px;
}
.input-icon--auto {
	width: auto;
}
.input-icon-text {
	margin-right: 10px;
	position: absolute;
	right: 35px;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 16px;
	font-weight: 500;
	color: var(--accent-color);
}
.input-icon-text--type2 {
	right: 6px;
}
.show-pass {
	width: 24px;
}
.show-pass svg path {
	stroke: var(--text-color2);
}
.select-block {
	margin-bottom: 24px;
}
.select-block__name {
	margin-bottom: 8px;
	font-size: 14px;
	color: var(--text-color-main);
	line-height: 1.8;
	font-weight: 400;
}
.select {
	height: 46px;
	border: 1px solid var(--border-main-color);
	border-radius: 8px;
}
.select--type2 .select__drop {
	max-width: 273px;
}
.select--type2 .select__drop ul > li > button {
	padding: 10px 12px;
}
.select--type2 .select__drop-scroll {
	max-height: 233px;
}
.select--type3 {
	position: static;
	border-radius: 0 8px 8px 0;
	background: #fcfcfd;
}
.select--type3 .select__drop {
	top: 50px;
}
.select--type3 .select__drop ul > li > a,
.select--type3 .select__drop ul > li > button {
	padding: 12px;
	font-size: 14px;
}
.select--type4 {
	height: 40px;
}
.select--drop-width-320 .select__drop {
	max-width: 320px;
}
.select__current {
	padding: 0 12px;
	font-size: 16px;
	font-weight: 500;
	line-height: 1;
	color: var(--text-color-main);
}
.select__current--placeholder {
	color: #bbbec7;
}
.select__drop {
	padding: 16px;
	right: -1px;
	left: auto;
	max-width: 273px;
	border: none;
	border-radius: 8px;
	background: #fff;
}
.select__drop ul > li > button {
	padding: 18px 12px;
	font-weight: 400;
	font-size: 12px;
	color: #777e90;
	border-radius: 4px;
}
.faq,
.faq__text {
	border: none;
}
.select__drop ul > li > button.active,
.select__drop ul > li > button:hover {
	color: #173b58;
	background: #f5f5f5;
}
.select__drop ::-webkit-scrollbar-track {
	background: #e6e8ec;
}
.select__drop-title {
	padding: 0 8px;
	margin-bottom: 16px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	font-weight: 600;
	font-size: 14px;
	gap: 12px;
}
.faq__text ul,
.wrapper {
	-ms-flex-direction: column;
}
.faq + .faq {
	margin-top: 4px;
}
.faq__header-title {
	font-weight: 500;
	font-size: 16px;
	color: var(--text-color-main);
}
.faq__text ul {
	padding-left: 16px;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	gap: 12px;
	list-style-type: disc;
	list-style-position: outside;
	font-size: 14px;
	line-height: 1.4;
	color: #777e90;
}
.progress-bar {
	margin: 16px 0;
}
.progress-bar__header-title {
	font-size: 12px;
	color: var(--text-color-main);
}
.progress-bar__header-value {
	font-size: 12px;
	font-weight: 500;
	color: #359c67;
}
.progress-bar__line {
	background: #f5f5f5;
}
.progress-bar__line-active {
	background: #30c06e;
}
.popup-window {
	background: rgba(119, 126, 144, 0.3);
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
}
.popup {
	width: 370px;
	border-radius: 8px;
}
.popup-header__title {
	font-size: 24px;
	color: var(--text-color-main);
}
.popup-text {
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	color: var(--text-color2);
	text-align: center;
}
.radio-title,
.radio__text {
	color: var(--text-color-main);
}
.radio-title {
	margin-bottom: 8px;
	font-weight: 400;
	font-size: 14px;
	line-height: 180%;
}
.checkbox__text,
.radio__text {
	font-weight: 400;
	font-size: 12px;
}
.radio {
	margin-bottom: 12px;
}
.radio__label {
	gap: 12px;
}
.radio__item {
	min-width: 24px;
	width: 24px;
	height: 24px;
	border: 1px solid var(--border-main-color);
	border-radius: 100px;
}
.radio__text {
	margin-left: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	gap: 8px;
}
.wrapper,
main {
	display: -ms-flexbox;
}
.radio input:checked ~ .radio__item {
	background: 0 0;
	border-color: var(--accent-color);
}
.radio input:checked ~ .radio__item::after {
	width: 12px;
	height: 12px;
	background: var(--accent-color);
}
.checkbox__item {
	min-width: 24px;
	width: 24px;
	height: 24px;
	border: 1px solid var(--border-main-color);
}
.checkbox__text {
	color: #777e90;
}
.checkbox input:checked ~ .checkbox__item {
	background: var(--accent-color);
	border-color: var(--accent-color);
}
.pagination-block {
	margin: 16px 0;
}
.pagination > li > a,
.pagination > li > button {
	min-width: 32px;
	width: 32px;
	height: 32px;
	border: none;
}
.pagination > li.active > a,
.pagination > li.active > button,
.pagination > li:not(.disabled):hover > a,
.pagination > li:not(.disabled):hover > button {
	background: var(--accent-color) !important;
}
.pagination > li.active > a svg path,
.pagination > li.active > button svg path,
.pagination > li:not(.disabled):hover > a svg path,
.pagination > li:not(.disabled):hover > button svg path {
	stroke: #fff;
}
.notification {
	width: 370px;
}
.notification--icon-error {
	padding: 16px 50px 16px 16px;
	gap: 18px;
	-ms-flex-align: start;
	align-items: flex-start;
	border-radius: 10px;
	background: #ffe4e4;
}
.notification--icon-error .notification__close {
	top: 15px;
	right: 17px;
}
.notification--icon-error .notification__icon {
	min-width: 24px;
	width: 24px;
	height: auto;
	background: 0 0;
	border-radius: 0;
}
.notification--icon-error .notification__icon svg {
	width: 100%;
}
.notification--icon-error .notification__info {
	padding: 0;
}
.notification--icon-error .notification__title {
	margin-bottom: 8px;
	font-weight: 500;
	color: #e04141;
}
.notification--icon-error .notification__text {
	font-size: 14px;
	line-height: 150%;
	color: #173b58;
}
.notification--icon-error .notification__text p + p {
	margin-top: 6px;
}
.inside,
body {
	margin: 0 auto;
	max-width: 100%;
}
@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-Black.woff2) format('woff2'), url(../fonts/Lato-Black.woff) format('woff');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-Heavy.woff2) format('woff2'), url(../fonts/Lato-Heavy.woff) format('woff');
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-Bold.woff2) format('woff2'), url(../fonts/Lato-Bold.woff) format('woff');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-Semibold.woff2) format('woff2'),
		url(../fonts/Lato-Semibold.woff) format('woff');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-Medium.woff2) format('woff2'),
		url(../fonts/Lato-Medium.woff) format('woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-Regular.woff2) format('woff2'),
		url(../fonts/Lato-Regular.woff) format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-Light.woff2) format('woff2'), url(../fonts/Lato-Light.woff) format('woff');
	font-weight: 300;
	font-style: normal;
}
* {
	-webkit-font-smoothing: antialiased;
}
body {
	width: 1920px;
	position: relative;
	color: var(--text-color-main);
	background: #fff;
	font-size: 16px;
	line-height: 1;
}
body.no-scroll {
	overflow-y: hidden;
}
body a {
	color: var(--main-color-text);
}
body input::-webkit-input-placeholder {
	font-size: inherit;
	font-family: Lato, sans-serif;
}
body input:-ms-input-placeholder {
	font-size: inherit;
	font-family: Lato, sans-serif;
}
body input::placeholder {
	font-size: inherit;
	font-family: Lato, sans-serif;
}
body textarea::-webkit-input-placeholder {
	font-size: inherit;
	font-family: Lato, sans-serif;
}
body textarea:-ms-input-placeholder {
	font-size: inherit;
	font-family: Lato, sans-serif;
}
body textarea::placeholder {
	font-size: inherit;
	font-family: Lato, sans-serif;
}
.wrapper {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
main {
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}
.usn {
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.inside {
	width: 1200px;
	padding: 0 15px;
	text-align: center;
}
.flex {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}
.mob,
.toggler {
	display: none;
}
.flex-m {
	-ms-flex-align: center;
	align-items: center;
}
.flex-t {
	-ms-flex-align: start;
	align-items: flex-start;
}
.flex-b {
	-ms-flex-align: end;
	align-items: flex-end;
}
.flex-sb {
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.flex-c {
	-ms-flex-pack: center;
	justify-content: center;
}
.flex-sa {
	-ms-flex-pack: distribute;
	justify-content: space-around;
}
.flex-e {
	-ms-flex-pack: end;
	justify-content: flex-end;
}
.flex-ww {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	clip: rect(0 0 0 0);
	overflow: hidden;
	-webkit-appearance: none;
	appearance: none;
}
.footer,
.footer-bottom {
	border-top: 1px solid var(--border-main-color);
}
section {
	padding: 1px 0;
}
.toggler {
	margin-left: 20px;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: justify;
	justify-content: space-between;
	width: 20px;
	height: 16px;
	position: relative;
	cursor: pointer;
	z-index: 99;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	transition: 0.3s;
}
.close-all,
.left-sidebar {
	position: fixed;
	transition: 0.3s;
}
.toggler span {
	width: 100%;
	height: 2px;
	background: var(--accent-color);
}
.left-sidebar {
	width: 280px;
	height: 100%;
	right: -280px;
	top: 0;
	background: #fff;
	border-left: 1px solid var(--border-main-color);
	z-index: 1000;
	overflow-y: auto;
}
.left-sidebar.active {
	right: 0;
}
.mob-nav {
	padding: 25px 15px;
}
.close-all {
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.7);
	z-index: -100;
	opacity: 0;
	cursor: pointer;
	visibility: hidden;
}
.close-all.active {
	z-index: 900;
	transition: 0.3s;
	opacity: 1;
	visibility: visible;
}
.container {
	width: 1200px;
	max-width: 100%;
	margin: 0 auto;
	padding: 0 15px;
}
.container--full-width {
	padding: 0;
	width: 100%;
}
.header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	height: 81px;
	border-bottom: 1px solid var(--border-main-color);
}
.header-container {
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 32px;
}
.footer-top,
.logo {
	display: -ms-flexbox;
}
.logo {
	display: flex;
	width: 167px;
}
.footer {
	background: var(--bg-color-2);
}
.footer-bottom {
	padding: 16px 0;
}
.footer-container {
	padding: 0 32px;
}
.footer-top {
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	gap: 40px;
	padding: 16px 0;
}
.footer-nav ul {
	display: -ms-flexbox;
	display: flex;
	gap: 40px;
}
.footer-nav ul > li > a,
.footer-nav ul > li > button {
	color: var(--text-color2);
	font-size: 14px;
	font-weight: 600;
	transition: all 0.2s;
}
.sidebar-nav-item__button-arrow,
.sidebar-nav-item__button-arrow svg path,
.sidebar-nav-item__icon svg path,
.td-sorting-arrow__item svg path {
	transition: 0.3s;
}
.footer-nav ul > li > a:hover,
.footer-nav ul > li > button:hover {
	color: var(--text-color-main);
}
.footer-support {
	margin-left: auto;
}
.footer-support a,
.footer-support button {
	color: var(--accent-color);
	font-weight: 500;
}
.copyright {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	gap: 16px;
	color: var(--text-color2);
	font-size: 12px;
}
.create-account-section {
	padding: 40px 0 154px;
}
.authorization-form {
	width: 370px;
	max-width: 100%;
	margin: 0 auto;
}
.authorization-form--type2 {
	width: 770px;
}
.form-title {
	font-size: 30px;
	font-weight: 500;
	line-height: 1.07;
	text-align: center;
}
.form-subtitle {
	margin-top: 24px;
	font-weight: 500;
	font-size: 16px;
	line-height: 2;
	color: var(--text-color2);
	text-align: center;
}
.form-footer {
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 24px;
	margin-top: 32px;
	gap: 16px;
}
.form-body--type2,
.form-footer---grid-column {
	-ms-grid-columns: (1fr) [2];
	grid-template-columns: repeat(2, 1fr);
	gap: 30px;
}
.form-footer--column {
	-ms-flex-direction: column;
	flex-direction: column;
}
.form-footer---grid-column {
	display: -ms-grid;
	display: grid;
}
.form-body {
	margin-top: 24px;
}
.form-body--type2 {
	display: -ms-grid;
	display: grid;
}
.form-body--type2 .authoriztion-item {
	margin-top: 0 !important;
}
.authoriztion-item--type2 {
	-ms-grid-column: span 2;
	grid-column: span 2;
}
.authoriztion-item + .authoriztion-item {
	margin-top: 16px;
}
.authoriztion-item .input,
.authoriztion-item .select-block {
	margin-bottom: 0;
}
img,
svg {
	width: auto;
}
svg {
	display: block;
}
.create-account-progress {
	margin: 0 auto 120px;
	width: 560px;
	max-width: 100%;
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: auto 1fr auto;
	grid-template-columns: auto 1fr auto;
	gap: 36px;
}
.create-account-progress--type2 {
	margin-bottom: 36px;
	width: 970px;
	-ms-grid-columns: auto 1fr auto 1fr auto 1fr auto;
	grid-template-columns: auto 1fr auto 1fr auto 1fr auto;
}
.create-account-progress--type3 {
	margin-bottom: 36px;
	width: 1070px;
	-ms-grid-columns: auto 1fr auto 1fr auto 1fr auto 1fr auto;
	grid-template-columns: auto 1fr auto 1fr auto 1fr auto 1fr auto;
	gap: 16px;
}
.create-account-progress__step {
	position: relative;
}
.create-account-progress__step.active .create-account-progress__step-number {
	color: #fff;
	background: var(--accent-color);
}
.create-account-progress__step.active .create-account-progress__step-text {
	color: var(--text-color-main);
}
.create-account-progress__step-number {
	margin: 0 auto 12px;
	width: 27px;
	height: 27px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 14px;
	color: #bbbec7;
	background: #f5f5f5;
	border-radius: 4px;
	transition: 0.3s;
}
.create-account-progress__step-text {
	font-size: 12px;
	color: var(--text-color2);
	text-align: center;
}
.create-account-progress__line.active .fill {
	fill: var(--accent-color);
}
.create-account-progress__line.active .stroke {
	stroke: var(--accent-color);
}
.create-account-progress__line svg {
	width: 100%;
}
.verification-upload {
	padding: 24px;
	border: 1px dashed #e6e8ec;
	border-radius: 8px;
}
.verification-upload__button {
	display: block;
	width: 100%;
	cursor: pointer;
}
.verification-upload__button-content {
	height: 40px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 8px;
	font-weight: 500;
	font-size: 14px;
	color: #fff;
	background: var(--accent-color);
	border-radius: 4px;
}
.verification-upload__drop {
	margin-top: 32px;
	padding: 20px 8px;
	width: 100%;
	font-weight: 500;
	font-size: 14px;
	color: var(--text-color2);
	text-align: center;
}
.verification-upload__load-file {
	margin: 16px 0;
	display: -ms-flexbox;
	display: flex;
	gap: 8px;
}
.verification-upload__load-file-item {
	position: relative;
	width: 42px;
	height: 45px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: end;
	align-items: flex-end;
}
.verification-upload__load-file-item button {
	top: 2px;
	right: 4px;
	position: absolute;
}
.verification-upload__error {
	margin-top: 16px;
	display: block;
	font-size: 12px;
	color: #e04141;
}
.settings-2fa__title {
	margin-bottom: 16px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	gap: 16px;
	font-weight: 500;
	font-size: 18px;
	color: var(--text-color-main);
}
.settings-2fa__title-number {
	min-width: 27px;
	width: 27px;
	height: 27px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-weight: 500;
	font-size: 14px;
	color: var(--accent-color);
	border: 1px solid #e6e8ec;
	border-radius: 4px;
}
.settings-2fa__text {
	margin-bottom: 16px;
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	color: var(--text-color2);
}
.settings-2fa-item--type2 {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: (1fr) [2];
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 16px;
}
.settings-2fa-app {
	margin-bottom: 24px;
	gap: 24px;
}
.settings-2fa-qr-code {
	margin-bottom: 24px;
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 123px 1fr;
	grid-template-columns: 123px 1fr;
	grid-gap: 16px;
}
.settings-2fa-qr-code__photo {
	width: 123px;
	height: 123px;
}
.settings-2fa-qr-code__photo img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.authorization-2fa {
	margin: 24px auto;
	padding: 24px;
	width: 655px;
	max-width: 100%;
}
.login-section {
	display: -ms-flexbox;
	display: flex;
	-ms-flex: 1;
	flex: 1;
	-ms-flex-align: center;
	align-items: center;
}
.login {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 388px 370px;
	grid-template-columns: 388px 370px;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.login__photo p {
	margin-top: 16px;
	font-weight: 600;
	font-size: 24px;
	color: var(--text-color-main);
	text-align: center;
}
.login__photo p span {
	color: var(--accent-color);
}
.login-form {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	gap: 24px;
}
.login-form__title {
	font-weight: 500;
	font-size: 30px;
	color: var(--text-color-main);
	text-align: center;
}
.login-form__text {
	font-weight: 500;
	font-size: 16px;
	text-align: center;
	color: var(--text-color2);
}
.setting-content {
	padding: 36px;
	width: 748px;
	max-width: 100%;
	margin: 0 auto;
}
.setting-content-title-wrap {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.setting-content-title {
	margin-bottom: 32px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	gap: 16px;
	font-weight: 500;
	font-size: 30px;
	text-align: left;
	color: var(--text-color-main);
}
.setting-content-back {
	padding: 8px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	width: 40px;
	height: 40px;
	background: #f5f5f5;
	border-radius: 8px;
}
.setting-content-text {
	margin-bottom: 32px;
	font-weight: 500;
	font-size: 14px;
	color: var(--text-color2);
}
.setting-content-body {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	gap: 32px;
}
.setting-content-body-item {
	border: 1px solid var(--border-main-color);
	border-radius: 8px;
}
.setting-content-body-item--type2 {
	padding: 24px;
}
.setting-content-body-item-header {
	padding: 24px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	gap: 16px;
	font-weight: 500;
	font-size: 18px;
	border-bottom: 1px solid #f5f5f5;
}
.setting-content-body-item-header--type2 {
	padding: 0;
	border: none;
}
.setting-content-body-item-header__icon {
	width: 24px;
	min-width: 24px;
}
.setting-content-body-item-header__icon svg {
	width: 100%;
	height: auto;
}
.setting-user-data-item {
	padding: 14px 24px;
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 200px 1fr;
	grid-template-columns: 200px 1fr;
	gap: 24px;
	border-bottom: 1px solid var(--border-main-color);
	color: var(--text-color-main);
}
.main-coint-list,
.most-trusted-platform {
	-ms-grid-columns: (1fr) [3];
	grid-template-columns: repeat(3, 1fr);
}
.setting-user-data-item:last-child {
	border-bottom: none;
}
.setting-user-data-item__title {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 400;
	font-size: 14px;
	line-height: 180%;
}
.setting-user-data-item__info {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	font-weight: 500;
	font-size: 16px;
	line-height: 180%;
}
.header-nav {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	gap: 24px;
}
.header-nav-button {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	gap: 8px;
	font-weight: 500;
	font-size: 14px;
	color: var(--text-color2);
}
.header-nav-item {
	position: relative;
}
.header-nav-item.active .header-nav-item__drop {
	opacity: 1;
	z-index: 150;
	visibility: visible;
}
.header-nav-item__drop {
	padding: 8px;
	position: absolute;
	right: -15px;
	top: calc(100% + 10px);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	gap: 8px;
	width: 300px;
	background: #fff;
	border-radius: 8px;
	opacity: 0;
	z-index: -10;
	visibility: hidden;
}
.header-nav-item__drop-item {
	padding: 16px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid var(--border-main-color);
	border-radius: 4px;
}
.header-nav-item__drop-item.active,
.header-nav-item__drop-item:hover {
	background: #f5f5f5;
}
.user-header {
	display: -ms-flexbox;
	display: flex;
	gap: 8px;
}
.user-header__avatar {
	min-width: 32px;
	width: 32px;
	height: 32px;
}
.user-header__avatar img {
	width: 100%;
	height: 100%;
}
.user-header__info {
	padding-top: 8px;
}
.user-header__name {
	margin-bottom: 10px;
	font-weight: 600;
	font-size: 16px;
	color: var(--text-color-main);
}
.user-header__mail {
	font-size: 12px;
	color: var(--text-color2);
}
.header-left {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	gap: 24px;
}
.header-site-nav {
	padding-left: 24px;
	height: 40px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	gap: 16px;
	border-left: 1px solid var(--border-main-color);
}
.header-site-nav a {
	font-weight: 500;
	font-size: 14px;
	color: var(--accent-color);
}
.enable-2fa-text {
	margin: 16px 0;
	font-size: 16px;
	line-height: 150%;
	color: #777e90;
}
.first-screne-section {
	padding: 90px 0;
	background: url(../img/first-screne-bg.png) top right no-repeat;
}
.first-screne {
	text-align: left;
}
.first-screne__title {
	margin-bottom: 38px;
	font-weight: 700;
	font-size: 48px;
	line-height: 120%;
	color: var(--text-color-main);
}
.first-screne__text {
	margin-bottom: 42px;
	font-weight: 500;
	font-size: 20px;
	line-height: 150%;
	color: var(--text-color2);
}
.main-coint-list {
	margin-top: 60px;
	display: -ms-grid;
	display: grid;
	gap: 30px;
}
.main-coint-list--type2 {
	margin-top: 0;
}
.main-coint-list--gap-36 {
	gap: 36px;
}
.main-coint-list-item {
	padding: 24px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	gap: 32px;
	background: #fff;
	border-radius: 10px;
}
.main-coint-list-item__line {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.main-coint-list-item__price {
	font-weight: 500;
	font-size: 16px;
	color: var(--text-color2);
}
.main-coint-list-item__change {
	font-weight: 500;
	font-size: 16px;
	color: var(--text-color-main);
}
.coin {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	gap: 16px;
}
.coin--type2 .coin__icon {
	min-width: 25px;
	width: 25px;
	height: 25px;
}
.coin--type3 .coin__icon,
.coin--type4 .coin__icon {
	min-width: 24px;
	width: 24px;
	height: 24px;
}
.coin--type2 .coin__text {
	font-size: 12px;
}
.coin--type3 {
	-ms-flex-align: start;
	align-items: flex-start;
}
.coin--type3 .coin__text {
	padding-top: 6px;
	font-size: 14px;
	color: #173b58;
}
.coin--type3 .coin__text-more {
	padding-left: 6px;
	display: inline-block;
	font-weight: 400;
	font-size: 12px;
	color: #777e90;
}
.coin--type4 .coin__text,
.coin--type5 .coin__text {
	font-size: 16px;
}
.coin--type3 .coin__text-more--type2 {
	padding-top: 8px;
	padding-left: 0;
}
.coin--type5 .coin {
	gap: 12px;
}
.coin--type5 .coin__icon {
	min-width: 16px;
	width: 16px;
	height: 16px;
}
.coin--type5 .coin__text--fz-14 {
	font-size: 14px;
}
.coin__icon {
	position: relative;
	min-width: 36px;
	width: 36px;
	height: 36px;
	display: block;
}
.coin__icon img,
.coin__icon svg {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.coin__text {
	font-weight: 700;
	font-size: 24px;
	line-height: 1;
}
.coin__text-wrap {
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	gap: 8px;
}
.coin__text-balance {
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: justify;
	justify-content: space-between;
	text-align: right;
}
.coin__text-balance-title {
	font-weight: 400;
	font-size: 12px;
	color: var(--text-color2);
}
.coin__text-balance-value {
	font-weight: 400;
	font-size: 14px;
	color: var(--text-color-main);
}
.coin__networks {
	padding: 4px;
	font-weight: 400;
	font-size: 10px;
	color: var(--text-color2);
	background: #f5f5f5;
	border-radius: 4px;
}
.page-title {
	margin-bottom: 24px;
	font-weight: 700;
	font-size: 32px;
	line-height: 1.3;
	color: var(--text-color-main);
}
.page-title--type2-color {
	color: #fff;
}
.page-text {
	margin-bottom: 24px;
	font-size: 24px;
	line-height: 150%;
	color: var(--text-color2);
}
.page-text--type2-color {
	color: #bbbec7;
}
.most-trusted-platform-section {
	padding: 90px 0;
	background: var(--bg-color2);
}
.most-trusted-platform {
	margin-top: 84px;
	display: -ms-grid;
	display: grid;
	gap: 30px;
}
.most-trusted-platform-item {
	padding: 0 24px;
	text-align: center;
}
.most-trusted-platform-item__icon {
	margin-bottom: 32px;
}
.most-trusted-platform-item__icon svg {
	margin: 0 auto;
}
.most-trusted-platform-item__title {
	margin-bottom: 10px;
	font-weight: 600;
	font-size: 24px;
	line-height: 150%;
	color: var(--text-color-main);
}
.most-trusted-platform-item__text {
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	color: var(--text-color2);
}
.above-beyond-section {
	padding: 90px 0;
	background: #072138;
}
.above-beyond {
	margin-top: 60px;
	padding: 0 100px;
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: (1fr) [2];
	grid-template-columns: repeat(2, 1fr);
	gap: 30px;
}
.above-beyond-item__icon {
	margin-bottom: 32px;
}
.above-beyond-item__icon svg {
	margin: 0 auto;
}
.above-beyond-item__title {
	margin-bottom: 10px;
	font-weight: 600;
	font-size: 24px;
	line-height: 150%;
	color: #fff;
}
.above-beyond-item__text {
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	font-style: italic;
	color: #bbbec7;
}
.get-started-section {
	padding: 90px 0;
}
.get-started {
	margin: 84px 0;
	display: -ms-grid;
	display: grid;
	-ms-flex-align: center;
	align-items: center;
	-ms-grid-columns: 1fr 30px 1fr 30px 1fr;
	grid-template-columns: 1fr 30px 1fr 30px 1fr;
	gap: 8px;
}
.get-started-item__icon {
	margin-bottom: 32px;
}
.get-started-item__item-icon {
	width: 11px;
}
.get-started-item__title {
	font-weight: 600;
	font-size: 24px;
	line-height: 150%;
	color: var(--text-color-main);
}
.form-footer-hr {
	margin-top: 40px;
	margin-bottom: 12px;
	width: 100%;
	height: 1px;
	border: none;
	background: var(--border-main-color);
}
.authorization-information {
	margin-top: 40px;
	font-weight: 500;
	font-size: 16px;
	line-height: 150%;
	color: #777e90;
}
.authorization-information p + p {
	margin-top: 16px;
}
.authorization-information strong {
	font-weight: 600;
	color: var(--text-color-main);
}
.authorization-information h3,
.verification-upload-info p {
	font-weight: 700;
	font-size: 20px;
	color: var(--text-color-main);
}
.authorization-information h3 {
	margin-top: 40px;
	margin-bottom: 16px;
}
.authorization-information ul {
	margin: 16px 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	gap: 8px;
	padding-left: 10px;
	list-style-type: disc;
	list-style-position: inside;
}
.create-acount-corporate-info ul,
.verification-upload-info ul {
	display: -ms-flexbox;
	gap: 8px;
	padding-left: 10px;
	list-style-type: disc;
	list-style-position: inside;
}
.verification-upload-info p {
	margin-bottom: 16px;
}
.verification-upload-info ul + p {
	margin-top: 24px;
}
.verification-upload-info ul {
	margin: 16px 0;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}
.verification-upload-info ul li {
	font-weight: 500;
	font-size: 16px;
	line-height: 150%;
	color: var(--text-color2);
}
.verification-upload-info ul li::marker {
	color: #777e90;
}
.authoriztion-item-title,
.create-acount-corporate-info p {
	margin-bottom: 16px;
	font-weight: 700;
	color: var(--text-color-main);
}
.authoriztion-item-title {
	margin-top: 20px;
	font-size: 20px;
}
.create-acount-corporate-info {
	margin-top: 40px;
}
.create-acount-corporate-info p {
	font-size: 16px;
}
.create-acount-corporate-info ul + p {
	margin-top: 24px;
}
.create-acount-corporate-info ul {
	margin: 16px 0;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}
.create-acount-corporate-info ul li {
	font-weight: 500;
	font-size: 14px;
	line-height: 150%;
	color: #777e90;
}
.phone-number-country {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
.phone-number-country__icon {
	width: 25px;
	margin-right: 10px;
}
.phone-number-country__icon img,
.phone-number-country__icon svg {
	display: block;
	width: 100%;
	height: auto;
	object-fit: contain;
}
.phone-number-input-title {
	margin-bottom: 8px;
	font-size: 14px;
	color: var(--text-color-main);
	line-height: 1.8;
	font-weight: 400;
}
.phone-number-input {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 118px 1fr;
	grid-template-columns: 118px 1fr;
}
.phone-number-input .select {
	border-radius: 8px 0 0 8px;
}
.phone-number-input .select__current {
	background: #fcfcfd;
}
.phone-number-input .input {
	margin-bottom: 0;
}
.phone-number-input .input-item {
	border-radius: 0 8px 8px 0;
	border-left: none;
}
.popup {
	padding: 30px 24px;
}
.popup--width-480 {
	width: 480px;
}
.popup--width-520 {
	width: 520px;
}
.popup-header__title {
	font-weight: 600;
}
.popup-header__title + .popup-header__title {
	margin-top: 38px;
}
.popup-text p + p {
	margin-top: 18px;
}
.popup-text--type2 {
	font-size: 12px;
}
.popup-icon {
	margin: 24px 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
}
.popup-icon--type2 {
	margin: 0 0 12px;
}
.popup-body {
	margin-top: 28px;
}
.popup-submit {
	gap: 16px;
}
.popup-submit + .popup-submit {
	margin-top: 16px;
}
.bonus-system-section {
	padding: 36px 0;
}
.bonus-system {
	width: 655px;
	max-width: 100%;
	margin: 0 auto;
}
.bonus-title {
	margin-top: 44px;
	margin-bottom: 36px;
	font-weight: 500;
	font-size: 20px;
	color: var(--text-color-main);
}
.bonus-title--type2 {
	margin-bottom: 24px;
}
.bonus-system-list {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	gap: 16px;
}
.bonus-system-item {
	padding: 29px 24px;
	display: -ms-grid;
	display: grid;
	-ms-flex-align: center;
	align-items: center;
	-ms-grid-columns: 0.7fr 1fr 1fr;
	grid-template-columns: 0.7fr 1fr 1fr;
	gap: 16px;
	border: 1px solid var(--border-main-color);
	border-radius: 8px;
}
.bonus-system-item__currency > p {
	margin-bottom: 14px;
	font-weight: 400;
	font-size: 14px;
	color: #777e90;
}
.bonus-system-item__balance {
	font-weight: 500;
	font-size: 24px;
	color: var(--text-color-main);
}
.bonus-system-item__button {
	text-align: right;
}
.instant-trade-section {
	padding: 57px 0;
}
.instant-trade {
	margin-top: 42px;
	padding: 57px 0 122px;
	text-align: center;
	background: url(../img/instant-trade-bg.png) center 0 no-repeat;
	background-size: cover;
}
.table-body .tr:nth-child(2n),
.table-header {
	background: #fcfcfd;
}
.instant-trade-title {
	margin-bottom: 60px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 40px;
	font-weight: 600;
	font-size: 24px;
	line-height: 150%;
}
.instant-trade-form {
	margin: 0 auto 37px;
	width: 830px;
	max-width: 100%;
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr 44px 1fr;
	grid-template-columns: 1fr 44px 1fr;
	gap: 32px;
}
.instant-trade-form-item__header {
	margin-bottom: 8px;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	text-align: left;
	color: var(--text-color-main);
}
.instant-trade-form-item__field {
	position: relative;
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr 88px;
	grid-template-columns: 1fr 88px;
}
.instant-trade-switch-button {
	padding-top: 31px;
}
.input-button {
	font-weight: 500;
	font-size: 14px;
	color: #3b97d3;
}
.instant-coint-list-section {
	margin-top: -120px;
	padding: 0;
}
.instant-trade-history-section {
	padding: 62px 0;
}
.td-sorting-arrow-button {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	font-size: 14px;
	color: var(--text-color2);
}
.td-sorting-arrow {
	margin-left: 8px;
}
.td-sorting-arrow__item {
	display: block;
	margin-bottom: 3px;
}
.td-sorting-arrow__item:last-child {
	margin-bottom: 0;
}
.td-sorting-arrow__item svg {
	display: block;
}
.td-sorting-arrow__item.active svg path {
	fill: var(--text-color-main);
}
.table-block {
	margin-top: 50px;
	margin-bottom: 32px;
}
.table-wrapper {
	border: 1px solid var(--border-main-color);
	border-radius: 8px;
}
.table {
	font-size: 14px;
	text-align: left;
}
.table--transaction-history .tr {
	-ms-grid-columns: 1.5fr 1.1fr 1.2fr 1fr 0.8fr 0.6fr;
	grid-template-columns: 1.5fr 1.1fr 1.2fr 1fr 0.8fr 0.6fr;
}
.table--wallet .tr {
	-ms-flex-align: start;
	align-items: flex-start;
}
.table--wallet .tr .td-more {
	text-align: right;
	padding-left: 10px;
}
.td-more,
[class*='--ta-l'] {
	text-align: left;
}
.table--wallet .tr .coin {
	margin-top: -6px;
}
.table--wallet .tr .td {
	padding-top: 19px;
	padding-bottom: 19px;
}
.table--crypto-balance .tr,
.table--fiat-balance .tr {
	-ms-grid-columns: 1.1fr 0.8fr 0.8fr 0.8fr 1fr 1.6fr;
	grid-template-columns: 1.1fr 0.8fr 0.8fr 0.8fr 1fr 1.6fr;
}
.table--transaction-history-crypto .tr {
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-grid-columns: 0.7fr 0.65fr 0.5fr 0.4fr 1fr 0.7fr 0.5fr 0.8fr minmax(100px, 0.6fr) 0.8fr;
	grid-template-columns: 0.7fr 0.65fr 0.5fr 0.4fr 1fr 0.7fr 0.5fr 0.8fr minmax(100px, 0.6fr) 0.8fr;
}
.table--transaction-history-fiat .tr {
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-grid-columns: 0.8fr 0.6fr 1fr 1fr 1fr 0.8fr 0.55fr minmax(100px, 0.6fr);
	grid-template-columns: 0.8fr 0.6fr 1fr 1fr 1fr 0.8fr 0.55fr minmax(100px, 0.6fr);
}
.table--transaction-history-trade .tr {
	-ms-grid-columns: 1fr 1.26fr 1.3fr 1.28fr 1.05fr 1.03fr 1.36fr 1.25fr 1.23fr;
	grid-template-columns: 1fr 1.26fr 1.3fr 1.28fr 1.05fr 1.03fr 1.36fr 1.25fr 1.23fr;
}
.table__block-title {
	display: block;
	padding: 18px 25px 10px;
	font-size: 16px;
	line-height: 24px;
}
.td-more {
	margin-top: 8px;
	display: inline-block;
	font-weight: 400;
	font-size: 12px;
	color: var(--text-color2);
}
.table-details {
	display: block;
	font-size: 10px;
	color: #5e5e5e;
}
.table-desc {
	display: block;
	font-weight: 300;
	font-size: 14px;
	line-height: 18px;
	color: #9ea2a9;
}
.tr {
	display: -ms-grid;
	display: grid;
	-ms-flex-align: center;
	align-items: center;
}
.td {
	padding: 22px 10px;
	word-break: break-word;
}
.td:first-child {
	padding-left: 16px;
}
.td:last-child {
	padding-right: 16px;
}
.td--right {
	text-align: right;
}
.table-header {
	border-radius: 8px 8px 0 0;
}
.table-header .tr {
	font-weight: 500;
	color: var(--text-color2);
	border-bottom: 1px solid #f5f5f5;
}
.table-body .tr {
	color: var(--text-color-main);
}
.td-name {
	font-weight: 500;
	font-size: 14px;
	color: #5f5f8b;
}
.td-desc--grey,
.td-title {
	color: rgba(37, 38, 45, 0.5);
}
.table-buttons {
	display: -ms-flexbox;
	display: flex;
}
.table-buttons button {
	-ms-flex: 0 0 15px;
	flex: 0 0 15px;
	max-width: 15px;
}
.table-buttons button .stroke path {
	stroke: var(--grey);
}
.table-buttons button.active .stroke path {
	stroke: var(--main-color);
}
.table-buttons button + button {
	margin-left: 10px;
}
.table-buttons--right {
	-ms-flex-pack: end;
	justify-content: flex-end;
}
.td-hidden-name {
	display: none;
	font-weight: 500;
}
.td-title {
	display: block;
	font-size: 12px;
	line-height: 18px;
}
.td-desc {
	font-size: 14px;
}
.table-button {
	margin: 16px 0;
	text-align: center;
}
.instant-exchange-from-to {
	margin-bottom: 24px;
	padding: 24px 16px;
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr 44px 1fr;
	grid-template-columns: 1fr 44px 1fr;
	-ms-flex-align: center;
	align-items: center;
	gap: 48px;
	border: 1px solid var(--border-main-color);
	border-radius: 8px;
}
.instant-exchange-from-to__item > p {
	margin-bottom: 16px;
	font-weight: 400;
	font-size: 14px;
	color: var(--text-color2);
}
.instant-exchange-from-to__arrow {
	padding: 10px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	background: #f5f5f5;
	border-radius: 8px;
}
.instant-exchange-confirm-list {
	margin-bottom: 24px;
}
.instant-exchange-confirm-list__item {
	padding: 12px 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	gap: 8px;
	border-bottom: 1px solid #f5f5f5;
}
.instant-exchange-confirm-list__item p:first-child {
	color: var(--text-color2);
}
.instant-exchange-confirm-info {
	margin-bottom: 24px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	gap: 12px;
	font-weight: 400;
	font-size: 12px;
	line-height: 180%;
	color: var(--text-color2);
}
.successful-info {
	margin-bottom: 16px;
	padding: 16px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	gap: 16px;
	background: #f5f5f5;
	border-radius: 4px;
}
.successful-info__item {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	gap: 16px;
	font-size: 14px;
	line-height: 150%;
	color: var(--text-color2);
}
.successful-info__item span {
	text-align: right;
	font-weight: 500;
	color: var(--text-color-main);
	word-break: break-all;
}
.wallet-section {
	padding: 0;
}
.page-wrap {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 290px 1fr;
	grid-template-columns: 290px 1fr;
}
.sidebar-nav {
	min-height: calc(100vh - 188px);
	padding: 25px 0;
	border-right: 1px solid var(--border-main-color);
}
.sidebar-nav-item__button {
	padding: 25px 41px;
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	font-weight: 500;
	font-size: 14px;
}
.sidebar-nav-item__button:hover {
	color: var(--main-color2);
}
.sidebar-nav-item__button:hover svg path {
	fill: var(--main-color2);
}
.sidebar-nav-item__button-arrow {
	min-width: 12px;
	width: 12px;
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}
.sidebar-nav-item__button-arrow svg {
	width: 100%;
}
.sidebar-nav-item__list ul li a {
	padding: 16px 40px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	gap: 12px;
	font-weight: 500;
	font-size: 14px;
	color: var(--text-color2);
}
/*.sidebar-nav-item__list ul li a.active,
.sidebar-nav-item__list ul li a:hover {
	color: var(--text-color-main);
}
.sidebar-nav-item__list ul li a.active .sidebar-nav-item__icon svg path,
.sidebar-nav-item__list ul li a:hover .sidebar-nav-item__icon svg path {
	stroke: var(--accent-color);
}*/
.sidebar-nav-item__icon {
	width: 24px;
	min-width: 24px;
	height: 24px;
}
.sidebar-nav-item__icon svg {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.wallet-content {
	padding: 36px;
}
.wallet-content-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.wallet-content-header__title {
	font-weight: 500;
	font-size: 30px;
}
.wallet-content-header__buttons {
	gap: 16px;
}
.wallet-content-balance {
	gap: 60px;
	margin: 32px 0 38px;
	padding: 30px 24px;
	border: 1px solid var(--border-main-color);
	border-radius: 8px;
}
.wallet-content-balance-item__title {
	margin-bottom: 17px;
	font-size: 14px;
	color: var(--text-color2);
}
.wallet-content-balance-item__value {
	margin-bottom: 16px;
	font-weight: 500;
	font-size: 24px;
	color: var(--text-color-main);
}
.wallet-content-balance-item__value span {
	display: inline-block;
	font-weight: 400;
	font-size: 14px;
	color: var(--text-color2);
	margin-left: 24px;
}
.wallet-content-balance-item__user-currensy {
	font-size: 14px;
	color: var(--text-color2);
}
.wallet-content-tables {
	border: 1px solid var(--border-color);
	background: var(--background-color);
}
.wallet-content-tables-header-search {
	padding: 24px;
	display: -ms-grid;
	display: grid;
	-ms-flex-align: center;
	align-items: center;
	-ms-grid-columns: 308px 1fr;
	grid-template-columns: 308px 1fr;
	grid-gap: 24px;
}
.wallet-content-tables-header-search--input-full {
	-ms-grid-columns: 1fr;
	grid-template-columns: 1fr;
}
.table-title {
	margin-bottom: 22px;
	font-size: 18px;
}
.wallet-operations {
	padding: 40px 36px;
}
.wallet-operations-header-wrap {
	margin-bottom: 40px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.wallet-operations-header-wrap .wallet-operations-header {
	margin-bottom: 0;
}
.wallet-operations-header {
	margin-bottom: 40px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	gap: 16px;
	font-weight: 500;
	font-size: 30px;
}
.wallet-operations-header__back {
	min-width: 40px;
	width: 40px;
	height: 40px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	background: #f5f5f5;
	border-radius: 8px;
}
.wallet-operations-header-right {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	gap: 16px;
}
.wallet-operations-item {
	max-width: 432px;
}
.wallet-operations-item + .wallet-operations-item {
	margin-top: 32px;
}
.wallet-operations-item__title {
	margin-bottom: 16px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	gap: 16px;
	font-weight: 700;
	font-size: 18px;
	line-height: 160%;
	color: var(--text-color-main);
}
.wallet-operations-item__title span {
	width: 24px;
	height: 24px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 500;
	font-size: 14px;
	color: var(--accent-color);
	border: 1px solid var(--border-main-color);
	border-radius: 4px;
}
.wallet-operations-item .select-block {
	margin-bottom: 16px;
}
.deposit-address__title {
	margin-bottom: 8px;
	font-weight: 500;
	font-size: 14px;
	color: var(--text-color2);
}
.deposit-address__text {
	margin-bottom: 32px;
	display: -ms-flexbox;
	display: flex;
	gap: 10px;
	font-weight: 500;
	font-size: 16px;
	line-height: 180%;
	color: var(--text-color-main);
	word-break: break-all;
}
.deposit-address-qr {
	margin-top: 32px;
	display: -ms-flexbox;
	display: flex;
	gap: 16px;
}
.select__drop-scroll + .button,
.wallet-operations-network,
.wallet-operations-network__item + .wallet-operations-network__item {
	margin-top: 16px;
}
.deposit-address-qr__photo {
	min-width: 103px;
	width: 103px;
	height: 103px;
}
.deposit-address-qr__photo img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.deposit-address-qr__info-title {
	margin-bottom: 4px;
	font-weight: 400;
	font-size: 14px;
	line-height: 180%;
	color: var(--text-color2);
}
.deposit-address-qr__code {
	margin-bottom: 12px;
	display: -ms-flexbox;
	display: flex;
	gap: 8px;
	font-weight: 500;
	font-size: 20px;
	line-height: 160%;
	color: var(--text-color-main);
}
.deposit-address-qr__info-block,
.error-block {
	margin-bottom: 16px;
	padding: 6px;
	gap: 4px;
	background: #feeceb;
}
.deposit-address-qr__code button {
	display: block;
}
.deposit-address-qr__info {
	width: 100%;
}
.deposit-address-qr__info-block {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 400;
	font-size: 12px;
	color: #eb5757;
}
.error-block,
.fee-pop {
	-ms-flex-align: center;
}
.deposit-address-qr__info-block-icon {
	min-width: 20px;
	width: 20px;
	height: 20px;
}
.deposit-address-qr__info-block-icon svg {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.deposit-address-qr__info-text {
	font-weight: 500;
	font-size: 16px;
	line-height: 180%;
	color: var(--text-color-main);
}
.error-block {
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: 12px;
	line-height: 180%;
	color: #eb5757;
}
.error-block__icon {
	min-width: 20px;
	width: 20px;
	height: 20px;
}
.error-block__icon svg {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.input-double-item {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr 88px;
	grid-template-columns: 1fr 88px;
}
.input-item-info {
	font-weight: 500;
	font-size: 16px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid #e6e8ec;
	color: var(--text-color-main);
	background: #fcfcfd;
	border-radius: 0 8px 8px 0;
}
.input-item-text {
	position: absolute;
	right: 15px;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	display: -ms-flexbox;
	display: flex;
	gap: 8px;
	font-size: 16px;
	font-weight: 400;
	color: var(--text-color2);
}
.input-item-text--fz-16 {
	font-size: 16px;
}
.receive {
	margin-bottom: 32px;
}
.receive__title {
	margin-bottom: 26px;
	font-size: 14px;
	color: var(--text-color2);
}
.receive__price {
	margin-bottom: 26px;
	font-weight: 600;
	font-size: 24px;
	color: var(--text-color-main);
}
.receive__fee {
	font-size: 14px;
	color: var(--text-color2);
}
.receive__fee span {
	padding-left: 24px;
}
.wallet-operations-network__title {
	margin-bottom: 12px;
	font-weight: 500;
	font-size: 14px;
	line-height: 180%;
}
.wallet-operations-network__item .radio__text {
	font-size: 16px;
	font-weight: 600;
}
.withdraw-to {
	margin-bottom: 16px;
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: (1fr) [2];
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 16px 60px;
}
.withdraw-to__item p {
	margin-bottom: 8px;
	font-weight: 400;
	font-size: 14px;
	line-height: 180%;
	color: var(--text-color2);
}
.withdraw-to__item span {
	display: block;
	font-weight: 500;
	font-size: 16px;
	line-height: 180%;
	color: var(--text-color-main);
}
.receive-amount {
	margin-bottom: 38px;
}
.receive-amount p {
	margin-bottom: 8px;
	font-weight: 600;
	font-size: 24px;
	line-height: 150%;
	color: var(--text-color-main);
}
.receive-amount span {
	display: block;
	font-size: 14px;
	color: var(--text-color2);
}
.successful-info-list ul {
	margin: 16px 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	gap: 16px;
	padding-left: 10px;
	list-style-type: disc;
	list-style-position: inside;
}
.successful-info-list ul li {
	font-weight: 500;
	font-size: 14px;
	line-height: 150%;
	color: var(--text-color-main);
}
.successful-info-list ul li::marker {
	color: var(--accent-color);
}
.popup-footer-text {
	margin: 16px 0;
	font-size: 14px;
	line-height: 150%;
	color: var(--text-color-main);
}
.transaction-fee {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	gap: 24px;
}
.transaction-fee__title {
	display: -ms-flexbox;
	display: flex;
	gap: 28px;
	font-weight: 400;
	font-size: 14px;
	color: var(--text-color2);
}
.fee-pop,
.transaction-fee__info {
	display: -ms-flexbox;
	font-weight: 400;
	font-size: 14px;
}
.transaction-fee__info {
	display: flex;
	gap: 16px;
	color: var(--text-color2);
}
.transaction-fee__info span {
	color: var(--text-color-main);
}
.fee-pop {
	margin: 24px 0;
	display: flex;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	gap: 16px;
	color: var(--text-color2);
}
.payment-methods-header,
.receive-pop {
	display: -ms-flexbox;
	-ms-flex-align: center;
	gap: 16px;
}
.fee-pop span {
	font-weight: 500;
	color: var(--text-color-main);
}
.receive-pop {
	margin: 24px 0;
	display: flex;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	font-weight: 400;
	font-size: 14px;
	color: var(--text-color2);
}
.receive-pop span {
	font-weight: 500;
	font-size: 20px;
	color: var(--text-color-main);
}
.payment-methods {
	margin-bottom: 36px;
	border: 1px solid var(--border-main-color);
	border-radius: 8px;
}
.payment-methods-header {
	padding: 16px 24px;
	min-height: 56px;
	display: flex;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-radius: 8px 8px 0 0;
	background: #fcfcfd;
}
.payment-methods-header__title {
	font-weight: 500;
	font-size: 16px;
	color: var(--text-color-main);
}
.payment-methods-header__title p {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	gap: 8px;
}
.payment-methods-header__title span {
	color: var(--text-color2);
}
.payment-methods-header__actions {
	display: -ms-flexbox;
	display: flex;
	gap: 32px;
}
.payment-methods-body {
	border-radius: 0 0 8px 8px;
	padding: 16px 24px;
}
.payment-methods-body-plug {
	margin: 16px 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 16px;
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	color: #bbbec7;
	text-align: center;
}
.payment-methods-list {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 0.8fr 2fr 0.9fr 0.9fr 1.2fr;
	grid-template-columns: 0.8fr 2fr 0.9fr 0.9fr 1.2fr;
	gap: 50px;
}
.payment-methods-list + .payment-methods-list {
	margin-top: 24px;
}
.payment-methods-list--type2 {
	max-width: 680px;
	-ms-grid-columns: (1fr) [1];
	grid-template-columns: repeat(1, 1fr);
}
.payment-methods-list--type3 {
	-ms-grid-columns: 1.5fr 2fr 1fr;
	grid-template-columns: 1.5fr 2fr 1fr;
}
.payment-methods-item p {
	margin-bottom: 12px;
	font-size: 14px;
	color: var(--text-color2);
}
.payment-methods-item > span {
	font-weight: 500;
	font-size: 16px;
	line-height: 150%;
	color: var(--text-color-main);
}
.tabs-buttons-nav {
	margin-bottom: 32px;
	padding: 2px;
	display: -ms-inline-flexbox;
	display: inline-flex;
	gap: 4px;
	border: 1px solid var(--border-main-color);
	border-radius: 8px;
}
.tabs-buttons-nav--mb-0 {
	margin-bottom: 0;
}
.tabs-buttons-nav--center {
	margin-left: auto;
	margin-right: auto;
}
.tabs-buttons-nav .button {
	border-radius: 6px;
}
.table-filter {
	margin-bottom: 16px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: end;
	align-items: flex-end;
	-ms-flex-pack: justify;
	justify-content: space-between;
	gap: 16px;
}
.table-filter-left,
.table-filter-right {
	display: -ms-flexbox;
	-ms-flex-align: center;
	gap: 16px;
}
.table-filter-left {
	display: flex;
	align-items: center;
}
.table-filter-left .select-block {
	min-width: 168px;
}
.table-filter-right {
	display: flex;
	align-items: center;
}
.copy-button {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	gap: 8px;
}
.verification-upload-wrap {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: (1fr) [2];
	grid-template-columns: repeat(2, 1fr);
	gap: 30px;
}
.verification-acount-info {
	margin-top: 32px;
	padding-top: 32px;
	border-top: 1px solid #e6e8ec;
}
.verification-acount-info p {
	margin-bottom: 16px;
	font-weight: 700;
	font-size: 16px;
	color: var(--text-color-main);
}
.verification-acount-info ul + p {
	margin-top: 24px;
}
.verification-acount-info ul {
	margin: 16px 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	gap: 8px;
	padding-left: 10px;
	list-style-type: disc;
	list-style-position: inside;
}
.green-bg,
.red-bg {
	padding: 4px 10px;
	font-weight: 500;
	border-radius: 11px;
}
.verification-acount-info ul li {
	font-weight: 500;
	font-size: 14px;
	line-height: 150%;
	color: #777e90;
}
.green-bg--fz-12,
.red-bg--fz-12 {
	font-size: 12px;
}
.captcha img,
.invoice-generated img {
	max-width: 100%;
}
a.link {
	display: inline-block;
	color: var(--accent-color) !important;
	text-decoration: none !important;
}
a.link--type2 {
	text-decoration: underline !important;
}
.grey {
	color: var(--text-color2);
}
.red {
	color: #e04141;
}
.red-bg {
	color: #fff;
	background: #e04141;
}
.yellow {
	color: #ff9f0a;
}
.green {
	color: #30c06e;
}
.green-bg {
	color: #fff;
	background: #30c06e;
}
[class*='--mt-0'] {
	margin-top: 0;
}
[class*='--mt-5'] {
	margin-top: 5px;
}
[class*='--mt-10'] {
	margin-top: 10px;
}
[class*='--mt-15'] {
	margin-top: 15px;
}
[class*='--mt-20'] {
	margin-top: 20px;
}
[class*='--mt-25'] {
	margin-top: 25px;
}
[class*='--mt-30'] {
	margin-top: 30px;
}
[class*='--mt-35'] {
	margin-top: 35px;
}
[class*='--mt-40'] {
	margin-top: 40px;
}
[class*='--mt-45'] {
	margin-top: 45px;
}
[class*='--mt-50'] {
	margin-top: 50px;
}
[class*='--mt-4'] {
	margin-top: 4px;
}
[class*='--mt-8'] {
	margin-top: 8px;
}
[class*='--mt-12'] {
	margin-top: 12px;
}
[class*='--mt-16'] {
	margin-top: 16px;
}
[class*='--mt-24'] {
	margin-top: 24px;
}
[class*='--mt-32'] {
	margin-top: 32px;
}
[class*='--mt-48'] {
	margin-top: 48px;
}
[class*='--mb-0'] {
	margin-bottom: 0;
}
[class*='--mb-5'] {
	margin-bottom: 5px;
}
[class*='--mb-15'] {
	margin-bottom: 15px;
}
[class*='--mb-20'] {
	margin-bottom: 20px;
}
[class*='--mb-25'] {
	margin-bottom: 25px;
}
[class*='--mb-30'] {
	margin-bottom: 30px;
}
[class*='--mb-35'] {
	margin-bottom: 35px;
}
[class*='--mb-40'] {
	margin-bottom: 40px;
}
[class*='--mb-45'] {
	margin-bottom: 45px;
}
[class*='--mb-50'] {
	margin-bottom: 50px;
}
[class*='--mb-4'] {
	margin-bottom: 4px;
}
[class*='--mb-8'] {
	margin-bottom: 8px;
}
[class*='--mb-12'] {
	margin-bottom: 12px;
}
[class*='--mb-16'] {
	margin-bottom: 16px;
}
[class*='--mb-24'] {
	margin-bottom: 24px;
}
[class*='--mb-32'] {
	margin-bottom: 32px;
}
[class*='--mb-48'] {
	margin-bottom: 48px;
}
[class*='--ml-0'] {
	margin-left: 0;
}
[class*='--mr-0'] {
	margin-right: 0;
}
[class*='--p-0'] {
	padding: 0;
}
[class*='--pt-0'] {
	padding-top: 0;
}
[class*='--pb-0'] {
	padding-bottom: 0;
}
[class*='--pl-0'] {
	padding-left: 0;
}
[class*='--pr-0'] {
	padding-right: 0;
}
[class*='--width-100'] {
	width: 100%;
}
[class*='--width-50'] {
	width: 50%;
}
[class*='--gap-5'] {
	gap: 5px;
}
[class*='--gap-10'] {
	gap: 10px;
}
[class*='--gap-15'] {
	gap: 15px;
}
[class*='--gap-20'] {
	gap: 20px;
}
[class*='--gap-25'] {
	gap: 25px;
}
[class*='--gap-30'] {
	gap: 30px;
}
[class*='--gap-35'] {
	gap: 35px;
}
[class*='--gap-40'] {
	gap: 40px;
}
[class*='--gap-45'] {
	gap: 45px;
}
[class*='--gap-50'] {
	gap: 50px;
}
[class*='--gap-4'] {
	gap: 4px;
}
[class*='--gap-8'] {
	gap: 8px;
}
[class*='--gap-12'] {
	gap: 12px;
}
[class*='--gap-16'] {
	gap: 16px;
}
[class*='--gap-24'] {
	gap: 24px;
}
[class*='--gap-32'] {
	gap: 32px;
}
[class*='--gap-48'] {
	gap: 48px;
}
[class*='--border-none'] {
	border: none;
}
[class*='--border-radius-50'] {
	border-radius: 50%;
}
[class*='--fw-300'] {
	font-weight: 300;
}
[class*='--fw-400'] {
	font-weight: 400;
}
[class*='--fw-500'] {
	font-weight: 500;
}
[class*='--fw-600'] {
	font-weight: 600;
}
[class*='--fw-700'] {
	font-weight: 700;
}
[class*='--fw-800'] {
	font-weight: 800;
}
[class*='--fw-900'] {
	font-weight: 900;
}
[class*='--ta-c'] {
	text-align: center;
}
[class*='--ta-r'] {
	text-align: right;
}
[class*='--red'] {
	color: red;
}
[class*='--green'] {
	color: green;
}
[class*='--yellow'] {
	color: #ff0;
}
@media screen and (max-width: 1220px) {
	.page-wrap {
		display: block;
	}
	.sidebar-nav {
		border-right: none;
		border-bottom: 1px solid var(--border-main-color);
	}
}
@media screen and (max-width: 1080px) {
	.first-screne-section {
		padding: 50px 0;
		background-size: contain;
	}
	.above-beyond-section,
	.most-trusted-platform-section {
		padding: 50px 0;
	}
	.first-screne__title {
		font-size: 34px;
	}
	.main-coint-list {
		margin-left: auto;
		margin-right: auto;
		width: 560px;
		max-width: 100%;
		-ms-grid-columns: (1fr) [1];
		grid-template-columns: repeat(1, 1fr);
	}
	.above-beyond {
		padding: 0;
	}
	.table .tr {
		display: -ms-grid;
		display: grid;
		-ms-grid-columns: (1fr) [2];
		grid-template-columns: repeat(2, 1fr);
		-ms-flex-align: stretch;
		align-items: stretch;
	}
	.td-hidden-name {
		margin-bottom: 14px;
		display: block;
		font-size: 16px;
		font-weight: 600;
		color: #777e90;
	}
	.table-header {
		display: none;
	}
	.td {
		text-align: left;
		padding: 16px 10px;
	}
	.td .flex-e {
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	.td:first-child {
		padding-left: 10px;
	}
	.td:last-child {
		padding-right: 10px;
	}
}
@media screen and (max-width: 920px) {
	.most-trusted-platform,
	.payment-methods-list {
		-ms-grid-columns: (1fr) [1];
		grid-template-columns: repeat(1, 1fr);
	}
	.most-trusted-platform {
		margin-top: 48px;
	}
	.payment-methods-list {
		gap: 24px;
	}
}
@media screen and (max-width: 860px) {
	.login {
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
	}
	.login__photo {
		display: none;
	}
	.instant-trade-form {
		padding: 0 24px;
		-ms-grid-columns: (1fr) [1];
		grid-template-columns: repeat(1, 1fr);
		gap: 12px;
	}
	.instant-trade-switch-button {
		padding-top: 0;
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
	}
}
@media screen and (max-width: 820px) {
	.create-account-progress {
		margin-bottom: 40px;
		-ms-grid-columns: (1fr) [2];
		grid-template-columns: repeat(2, 1fr);
		-ms-flex-pack: center;
		justify-content: center;
	}
	.create-account-progress__line {
		display: none;
	}
	.create-account-progress--type2 {
		-ms-grid-columns: (1fr) [4];
		grid-template-columns: repeat(4, 1fr);
	}
	.create-account-progress--type3 {
		-ms-grid-columns: (1fr) [5];
		grid-template-columns: repeat(5, 1fr);
	}
	.header-container {
		padding: 0 15px;
	}
	.logo {
		width: 80px;
	}
	.logo img {
		width: 100%;
	}
	.first-screne__title {
		font-size: 26px;
	}
	.wallet-content-balance {
		display: -ms-grid;
		display: grid;
		-ms-grid-columns: (1fr) [1];
		grid-template-columns: repeat(1, 1fr);
		gap: 34px;
	}
	.wallet-content-balance-item__value {
		font-size: 20px;
	}
	.wallet-content-balance-item__value span {
		margin-left: 12px;
	}
}
@media screen and (max-width: 760px) {
	.above-beyond,
	.get-started {
		-ms-grid-columns: (1fr) [1];
		grid-template-columns: repeat(1, 1fr);
	}
	.page-title {
		font-size: 24px;
	}
	.page-text {
		font-size: 18px;
	}
	.get-started {
		margin: 24px 0;
	}
	.get-started-item {
		gap: 24px;
	}
	.get-started-item--arrow {
		display: none;
	}
	.get-started-item__icon {
		margin-bottom: 12px;
	}
	.sidebar-nav {
		padding: 8px 0;
	}
	.sidebar-nav-item__list ul li a {
		padding-left: 16px;
		padding-right: 16px;
	}
	.wallet-content,
	.wallet-operations {
		padding: 24px 16px;
	}
	.wallet-operations-header-wrap {
		gap: 12px;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.wallet-operations-header {
		width: 100%;
		font-size: 22px;
	}
	.wallet-operations-header__back {
		padding: 5px;
		min-width: 30px;
		width: 30px;
		height: 30px;
	}
	.wallet-operations-header__back svg {
		max-width: 100%;
	}
	.wallet-operations-header-right {
		width: 100%;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}
	.wallet-content-header {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		gap: 24px;
	}
}
@media screen and (max-width: 720px) {
	.form-body--type2 {
		-ms-grid-columns: (1fr) [1];
		grid-template-columns: repeat(1, 1fr);
		gap: 24px;
	}
	.authoriztion-item--type2 {
		-ms-grid-column: 1;
		grid-column: 1;
	}
	.form-title {
		font-size: 26px;
	}
	.instant-trade-title {
		font-size: 20px;
	}
	.table-filter {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.table-filter-left {
		width: 100%;
	}
	.table-filter-left > div {
		min-width: auto !important;
		width: 100%;
	}
}
@media screen and (max-width: 660px) {
	.footer-top {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		gap: 24px;
		-ms-flex-pack: center;
		justify-content: center;
	}
	.footer-top > div {
		width: 100%;
	}
	.footer-nav ul {
		-ms-flex-pack: center;
		justify-content: center;
	}
	.footer-support {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
	}
}
@media screen and (max-width: 560px) {
	.bonus-system-item,
	.setting-user-data-item,
	.settings-2fa-item--type2,
	.settings-2fa-qr-code,
	.verification-upload-wrap {
		-ms-grid-columns: (1fr) [1];
		grid-template-columns: repeat(1, 1fr);
	}
	.setting-content {
		padding: 25px 0;
	}
	.setting-content-body-item-header,
	.setting-user-data-item {
		padding-left: 12px;
		padding-right: 12px;
	}
	.setting-user-data-item {
		grid-gap: 12px;
	}
	.setting-content-body-item--type2 {
		padding: 12px;
	}
	.setting-content-title {
		font-size: 22px;
	}
	.setting-content-back {
		width: 30px;
		height: 30px;
	}
	.setting-content-back svg {
		max-width: 100%;
	}
	.authorization-2fa {
		padding: 0;
	}
	.bonus-system-item {
		gap: 24px;
	}
	.bonus-system-item__button {
		text-align: left;
	}
}
@media screen and (max-width: 480px) {
	.popup {
		padding: 30px 12px;
	}
	.toggler {
		display: -ms-flexbox;
		display: flex;
	}
	.header-nav {
		display: none;
	}
	.mob-nav .header-nav {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		gap: 16px;
	}
	.mob-nav .header-nav-item {
		width: 100%;
	}
	.mob-nav .header-nav-item__drop {
		margin-top: 10px;
		position: static;
		display: none;
		width: 100%;
	}
	.mob-nav .header-nav-item.active .header-nav-item__drop {
		display: -ms-flexbox;
		display: flex;
	}
	.create-account-progress--type3 {
		-ms-grid-columns: (1fr) [2];
		grid-template-columns: repeat(2, 1fr);
	}
	.instant-exchange-from-to {
		-ms-grid-columns: (1fr) [1];
		grid-template-columns: repeat(1, 1fr);
		gap: 16px;
	}
	.instant-exchange-from-to__arrow svg {
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
	}
	.instant-trade-title {
		-ms-flex-direction: column;
		flex-direction: column;
		gap: 12px;
	}
}

