.login-form {
	width: 100%;
	max-width: 432px;
	gap: 16px;

	&--w-540 {
		max-width: 540px;
	}

	&--w-580 {
		max-width: 580px;
	}

	&--w-660 {
		max-width: 660px;
	}

	&__title {
		line-height: 1;
		text-align: center;

		p {
			font-weight: 600;
			font-size: 32px;
			color: var(--Grayscale-White);
			text-align: left;
			line-height: 40px; /* 125% */

			&.login-form__subtitle {
				color: var(--Grayscale-Gray-10);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 25.6px */
				margin-top: 16px;
			}
		}
	}
}

