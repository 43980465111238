.wallet-operations {
	background: var(--Grayscale-Gray-100);

	@media only screen and (min-width: 760px) {
		padding: 16px 40px;
	}

	@media only screen and (min-width: 1200px) {
		padding: 16px 48px;
	}

	&__form-wrap {
		margin-top: 16px;

		.form {
			max-width: 660px;
			width: 100%;
			padding: 16px 24px 24px 24px;
			border-radius: 6px;
			box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.14);
			background: var(--Grayscale-Gray-80);
		}
	}
	&__form-header {
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: var(--Grayscale-White);
		margin-bottom: 8px;
	}
	.input-notify p {
		font-weight: 400;
		font-size: 12px;
		line-height: 180%;
		color: var(--additional-error);
	}
}
.wallet-operations-title {
	font-family: var(--font-family);
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	color: var(--Grayscale-White);
}

