.instant-exchange-confirm-list {
	border-radius: 6px;
	background: var(--Grayscale-Gray-70);
	margin-bottom: 16px;
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	&__item {
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 8px;
		border-bottom: 0;

		p {
			&:first-child {
				color: var(--Grayscale-White);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px; /* 150% */
			}

			&:last-child {
				color: var(--Grayscale-White);
				text-align: right;
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: 24px; /* 150% */
			}
		}
	}
}
