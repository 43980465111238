.info-section {
	padding: 0;
	&__box {
		width: 100%;
		border-radius: 16px;
		border: 1px solid var(--Grayscale-Gray-100);
		background: var(--Grayscale-Gray-100);
		display: flex;
		flex-direction: column;
		gap: 16px;
		p {
			font-weight: 600;
			font-size: 16px;
			line-height: 150%;
			color: var(--Grayscale-White);
			margin-bottom: 16px;
		}
		span {
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			color: var(--Grayscale-Gray-20);
			white-space: pre-line;
		}
		a {
			color: var(--Blue-Blue-Light);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 25.6px */
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.container {
		width: 100%;
		max-width: 880px;
		padding-bottom: 16px;
		margin: 0px;
	}

	h1,
	h2 {
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: var(--Grayscale-White);
	}

	&__date {
		p {
			font-weight: 400;
			font-size: 14px;
			line-height: 133%;
			color: var(--Grayscale-Gray-20);
			margin: 8px 0;
		}
	}
}

