.trade-wallet-balances {
	border-radius: 6px;
	background: var(--Grayscale-Gray-90);
	border: 1px solid var(--Grayscale-Gray-90);
	box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.24);
	padding: 0;
	text-align: left;
	overflow: hidden;

	&__tabs-wrap {
		padding: 0;
	}

	&__tabs {
		padding: 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		border-radius: 0;
		background: var(--Grayscale-Gray-80);
	}

	&__tab {
		color: var(--Grayscale-White);
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px; /* 142.857% */
		display: flex;
		align-items: center;
		justify-content: center;
		height: 56px;
		padding: 0 16px;
		border-bottom: 2px solid transparent;
		transition: var(--transition-base);

		&.active {
			color: var(--Grayscale-Gray-10);
			border-radius: 0;
			box-shadow: none;
			border-color: var(--Blue-Blue-Brand);
		}
	}
}
