.security-content {
	border-radius: 6px;
	padding: 24px;
	background: var(--Grayscale-Gray-90);
	box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.24);
	width: 100%;
	display: flex;
	gap: 16px;
	flex-direction: column;

	&__wrap {
		padding: 16px 0;
	}
	&--w-660 {
		max-width: 660px;
	}

	&__title {
		color: var(--Grayscale-White);
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-bottom: 8px;
		display: flex;
		gap: 16px;
		align-items: center;
	}
	&__header {
		color: var(--Grayscale-White);
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-bottom: 8px;
		display: flex;
		gap: 16px;
		align-items: center;
	}
	&__input {
		input {
			border-radius: 4px;
			border: 1px solid var(--Grayscale-Gray-80);
			background: var(--Grayscale-Gray-100);
			height: 48px;
			width: 100%;
			color: var(--Grayscale-White);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%; /* 22.4px */
			padding: 20px 16px;
			max-width: 384px;
		}
		&.green-bg {
			input {
				border-color: var(--additional-green);
				background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.9998 2.69999C6.86356 2.69999 2.6998 6.86374 2.6998 12C2.6998 17.1362 6.86356 21.3 11.9998 21.3C17.1361 21.3 21.2998 17.1362 21.2998 12C21.2998 6.86374 17.1361 2.69999 11.9998 2.69999ZM1.2998 12C1.2998 6.09054 6.09036 1.29999 11.9998 1.29999C17.9093 1.29999 22.6998 6.09054 22.6998 12C22.6998 17.9094 17.9093 22.7 11.9998 22.7C6.09036 22.7 1.2998 17.9094 1.2998 12Z' fill='%235EC45C'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.4336 7.4506C17.737 7.69015 17.7888 8.13033 17.5492 8.43377L11.2334 16.4338C11.1088 16.5917 10.9221 16.6883 10.7212 16.699C10.5203 16.7097 10.3245 16.6334 10.1837 16.4896L6.49951 12.7249C6.22911 12.4486 6.2339 12.0054 6.51021 11.735C6.78651 11.4646 7.2297 11.4694 7.5001 11.7457L10.6277 14.9417L16.4504 7.56627C16.6899 7.26283 17.1301 7.21104 17.4336 7.4506Z' fill='%235EC45C'/%3E%3C/svg%3E");
				background-repeat: no-repeat;
				background-position-x: 98%;
				background-position-y: center;
			}
			background-color: transparent;
			color: transparent;
			padding: 0;
		}
	}
	&__enable-2fa-text {
		font-weight: 400;
		font-size: 14px;
		line-height: 143%;
		color: var(--Grayscale-Gray-20);
		max-width: 590px;
	}
	&__body {
		.enable-2fa-text {
			color: var(--Grayscale-White);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-bottom: 16px;
		}
		.enable-2fa-label {
			font-weight: 400;
			font-size: 14px;
			line-height: 143%;
			color: var(--Grayscale-Gray-20);
			margin-top: 16px;
		}
		.input-item {
			&::placeholder {
				font-weight: 400;
				font-size: 16px;
				line-height: 150%;
				color: var(--Grayscale-Gray-20);
			}
		}
	}
	&__body-2fa {
		border-radius: 6px;
		padding: 16px 24px 24px 24px;
		box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.14);
		background: var(--Grayscale-Gray-80);
	}
}

