.wallet-content-header {
	@media only screen and (max-width: 576px) {
		flex-direction: column;
		align-items: flex-start;
	}

	&__title {
		color: var(--Grayscale-White);
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		margin-bottom: 8px;
	}

	&__buttons {
		gap: 10px;

		@media only screen and (max-width: 576px) {
			flex-direction: column;
		}
	}
}

