.progress-bar-container {
	width: 200px;
	height: 3px;
	border-radius: 8px;
	justify-content: flex-start;
	align-items: center;
	display: flex;
	gap: 10px;
	background: var(--Grayscale-Gray-70);
}

.progress-bar {
	height: 100%;
	background: var(--Blue-Blue-Brand);
	border-radius: 4px;
	transition: width 0.3s ease;
	text-align: center;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}

.progress-text {
	margin: 0;
}

.progress-wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	margin-top: 40px;
	gap: 4px;
	p {
		font-weight: 400;
		font-size: 12px;
		line-height: 133%;
		text-align: center;
		color: var(--Grayscale-Gray-20);
	}
}

.progress-bar-wrapper {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: center;
	span {
		font-weight: 400;
		font-size: 12px;
		line-height: 133%;
		text-align: center;
		color: var(--Grayscale-White);
	}
}

