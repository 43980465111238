.wallet-operations-header {
	gap: 20px;

	&__back {
		min-width: 38px;
		width: 38px;
		height: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		border: 1px solid var(--blue200);
		background: var(--blue100);
		box-shadow: 0 2px 6px 0 rgba(33, 44, 99, 0.09);
		background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 19L2 12L9 5L10.05 6.05L4.85 11.25H22V12.75H4.85L10.05 17.95L9 19Z' fill='%230071AB'/%3E%3C/svg%3E%0A");
		background-size: 24px 24px;
		background-repeat: no-repeat;
		background-position: center;

		svg {
			display: none;
		}
	}
}

