.text--center {
	text-align: center;
}

.coin__text.coin__text---bold {
	font-weight: 500;
}

.coin--type3 .coin__text.coin__text---pt-0 {
	padding-top: 0;
}

.yellow-bg {
	color: #fff;
	background: #ff9f0a;
	border-radius: 11px;
	font-weight: 500;
	padding: 4px 10px;
}

// .page-wrap {
// 	.sidebar-nav {
// 		min-height: 90vh;
// 	}
// }

.button.button--size1 {
	padding: 0 72px;
	height: 52px;
}

.popup-soc {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	gap: 16px;
}

// .BankAccountForm { // !!! do not uncomment - on page Payment Methods in NEW BANK ACCOUNT form fields would become wider as needed. Please find another way!!!
// 	input {
// 		width: 1000px;
// 	}
// 	textarea {
// 		width: 1000px;
// 	}
// 	.select {
// 		width: 1000px;
// 	}
// }

/*body {
	.setting-content-body-item {
		border: 1px solid var(--border-main-color) !important;
		border-radius: 8px;
	}
}*/
.selectedCurrency {
	height: 40px;
	.form,
	.instant-trade-form-item__field {
		height: 40px;
	}
	.element---capitalize {
		text-transform: capitalize;
	}
	.select-block {
		margin-bottom: 0;
	}

	.instant-trade-form-item__field {
		grid-template-columns: 0;
	}

	.select {
		position: relative;
		display: flex;
		align-content: center;
		background: #fff;
		z-index: 3;
		height: 40px;
		border: 1px solid var(--border-main-color);
		border-radius: 8px;
	}
}

.instant-trade-form-item__field.instant-trade-form-item__field--exchange {
	grid-template-columns: 1fr 104px;
}

.DatePicker {
	position: relative;
	.datepicker-input-wrapper {
		width: 250px;
	}
	input {
		height: 20px;
	}
	.react-datepicker__input-container input {
		height: 40px;
	}
	.select-block {
		margin-bottom: 0px;
	}
}
.table-filter {
	.select-block {
		.select {
			height: 40px;
		}
	}
	input {
		height: 40px;
	}
}
.table-wrapper {
	.table-footer {
		padding: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.select--per-page-wrapper {
			display: flex;
			align-items: center;
			gap: 16px;
		}
		.select--per-page-wrapper .select__drop {
			top: unset;
			left: 0;
			min-width: 120px;
			bottom: calc(100% + 10px);
		}
	}
	.button-wrap {
		button {
			margin-right: 5px;
		}
	}
}
.td-sorting {
	.active {
		border-top-color: var(--Grayscale-White) !important;
		border-bottom-color: var(--Grayscale-White) !important;
	}
	div {
		width: 0;
		height: 0;
		border: 3.4px solid transparent;
		margin-left: 8px;
	}
	.arrow-top {
		border-top-color: var(--Grayscale-Gray-20);
		border-bottom: 0;
	}
	.arrow-bottom {
		margin-bottom: 3px;
		border-bottom-color: var(--Grayscale-Gray-20);
		border-top: 0;
	}
}

.input-notify.input-notify--absolute {
	position: relative;
	top: -24px;
}

.setting-content-title {
	&.title--centered {
		justify-content: center;
	}
}

.button {
	&:disabled {
		background: #bfbfbf;
		opacity: 1;
		cursor: not-allowed;
	}
	&.button--type-text {
		&:disabled {
			background: transparent;
			opacity: 0.5;
			color: var(--accent-color);
		}
	}
}

.popup-header__title {
	&.bold {
		font-weight: 700;
	}
}

/*@media screen and (min-width: 1081px) {
	.table-wrapper {
		.table--transaction-history-crypto .tr {
			align-items: flex-start;
			grid-template-columns: 1.1fr 1fr 1.3fr 1.65fr 0.9fr 0.8fr 0.8fr 1fr 1fr 1fr 1fr;
		}
		.table--transaction-history-fiat .tr {
			grid-template-columns: 1.25fr 1fr 1.52fr 1.4fr 1.3fr 1.2fr 1.18fr 0.9fr;
		}
	}
}*/

@media screen and (max-width: 1080px) {
	.table-filter,
	.table-filter-left {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
}
