.instant-exchange-confirm-info {
	margin-bottom: 16px;
	display: block;
	color: var(--Grayscale-Gray-20);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	p {
		a {
			color: var(--Blue-Blue-Light);
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
