.popup-overlay {
	position: fixed;
	display: block;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	overflow: auto;
	z-index: 201;
	background: rgba(#0d0f0e, 0.5);
	//background: rgba(119, 126, 144, 0.3);
	//backdrop-filter: blur(10px);
	transition: 1s;
	//animation: transition 200ms cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.popup-content {
}

.popup.popup--big {
	width: 480px;
}

.popup-header__title.popup-header__title--error {
	color: var(--ui-error-color);
}

.popup-img {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 24px 0;
}

.popup-button {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	gap: 24px;
}

.bankAccount.bankAccount--popup input {
	width: 100%;
}
.bankAccount.bankAccount--popup textarea {
	width: 100%;
}
.bankAccount.bankAccount--popup .select {
	width: 100%;
}

.flex-column {
	flex-direction: column;
}

.popup-text {
	word-break: break-word;
}

@keyframes transition {
	from {
		opacity: 0.5;
	}
	to {
		opacity: 1;
	}
}
