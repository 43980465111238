.payment-methods-item {
	&--text-right {
		@media only screen and (min-width: 920px) {
			text-align: right;
		}
	}

	&__curr {
		display: flex;
		align-items: center;
		gap: 8px;

		img,
		svg {
			width: 24px;
			min-width: 24px;
			height: 24px;
			min-height: 24px;
		}
	}

	&__note {
		margin-top: 6px;
		color: var(--greyscale-grey-500);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 22.4px */
	}

	p {
		font-weight: 400;
		font-size: 12px;
		line-height: 133%;
		color: var(--Grayscale-Gray-20);
		margin-bottom: 10px;
	}

	span {
		font-weight: 700;
		font-size: 14px;
		line-height: 143%;
		color: var(--Grayscale-White);
	}
	&--coin-wrap {
		display: flex;
		gap: 4px;
	}
}

