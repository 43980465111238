.footer {
	border-top: 1px solid var(--Grayscale-Gray-80);
	background: var(--Grayscale-Gray-100);
	@media only screen and (min-width: 1179.98px) {
		display: none;
	}

	&__visible {
		display: block;
	}

	&__support {
		color: var(--Grayscale-Gray-30);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px; /* 142.857% */
		text-decoration: none;

		&:hover {
			color: var(--Grayscale-Gray-10);
		}
	}

	.footer-container {
		padding: 14px 40px;
		display: flex;

		@media only screen and (min-width: 576px) {
			align-items: center;
			justify-content: space-between;
		}

		@media only screen and (min-width: 1279.98px) {
			padding: 14px 48px;
		}

		@media only screen and (max-width: 575.98px) {
			flex-direction: column;
			align-items: center;
			gap: 18px;
		}
	}

	.logo {
		width: 158px;

		@media only screen and (max-width: 575.98px) {
			margin-bottom: 30px;
		}

		img {
			max-width: 100%;
			height: auto;
		}
	}
}

.footer-container {
	padding: 0;
}

