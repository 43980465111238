.trade-wallet-balances-table {
	&__scroll-wrap {
		padding-bottom: 12px;
	}

	&__scroll {
		padding: 0;
		max-height: 306px;
		min-height: 306px;
		overflow: auto;
		scrollbar-color: #b6b6b6 transparent;
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			width: 6px;
			height: 6px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 6px;
			background: var(--gray100);
		}

		&::-webkit-scrollbar-track {
			background: transparent;
		}
	}

	&__wrapper {
		padding: 8px 24px;
	}

	&__tr {
		display: grid;
		padding: 16px 0;
		border-bottom: 1px solid var(--Grayscale-Gray-70);
		align-items: center;

		@media only screen and (min-width: 576px) {
			grid-template-columns: 1.5fr 0.7fr 72px;
			gap: 32px;
		}

		@media only screen and (max-width: 575.98px) {
			grid-template-areas:
				'coin coin'
				'count  actions';
			grid-template-columns: 1.5fr 72px;
			column-gap: 32px;
			row-gap: 12px;
		}
	}

	&__td {
		&:nth-of-type(1) {
			@media only screen and (max-width: 575.98px) {
				grid-area: coin;
			}
		}

		&:nth-of-type(2) {
			@media only screen and (max-width: 575.98px) {
				grid-area: count;
			}
		}

		&:nth-of-type(3) {
			@media only screen and (max-width: 575.98px) {
				grid-area: actions;
			}
		}

		p {
			color: var(--Grayscale-White);
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: 24px; /* 150% */
		}
	}

	&__coin {
		display: flex;
		align-items: center;
		gap: 8px;

		img {
			width: 24px;
			min-width: 24px;
			height: 24px;
			min-height: 24px;
		}
	}

	&__text {
		//display: flex;
		//gap: 8px;
		color: var(--Grayscale-White);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px; /* 150% */

		span {
			color: var(--Grayscale-Gray-20);
			text-transform: uppercase;
			margin-left: 8px;
		}
	}

	&__actions {
		display: flex;
		flex-direction: row;
		gap: 8px;
		justify-content: flex-end;
	}
}
