.enable-2fa-text {
	margin: 0 0 24px 0;
	color: var(--greyscale-grey-600);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%; /* 22.4px */
	max-width: 500px;

	&--title {
		color: var(--greyscale-grey-700);
		font-size: 18px;
		font-weight: 500;
		line-height: normal;
	}
}
