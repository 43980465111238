*[class*='--mt-0'] {
	margin-top: 0;
}

*[class*='--mb-40'] {
	margin-bottom: 40px;
}

@for $i from 1 through 20 {
	.mb-#{$i * 5} {
		margin-bottom: #{$i * 5}px !important;
	}
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-8 {
	margin-bottom: 8px !important;
}

.mb-12 {
	margin-bottom: 12px !important;
}

.mb-16 {
	margin-bottom: 16px !important;
}

.mb-32 {
	margin-bottom: 32px !important;
}

@for $i from 1 through 20 {
	.mt-#{$i * 5} {
		margin-top: #{$i * 5}px !important;
	}
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-24 {
	margin-top: 24px !important;
}

.mt-32 {
	margin-top: 32px !important;
}

.min-w-55 {
	min-width: 55px !important;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-sm-right {
	@media only screen and (min-width: 576px) {
		text-align: right !important;
	}
}

.items-center {
	align-items: center !important;
}

.d-flex {
	display: flex !important;
}

.align-items-center {
	align-items: center !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.font-weight-normal {
	font-weight: 400 !important;
}

.font-weight-semibold {
	font-weight: 600 !important;
}

.text-transform-uppercase {
	text-transform: uppercase !important;
}

.text-transform-none {
	text-transform: none !important;
}

.reset-overflow {
	overflow: inherit !important;
}

@for $i from 1 through 20 {
	.gap-#{$i * 5} {
		gap: #{$i * 5}px !important;
	}
}

.gap-0 {
	gap: 0 !important;
}

.gap-16 {
	gap: 16px !important;
}

.text-Grayscale-Gray-20 {
	color: var(--Grayscale-Gray-20) !important;
}

.instant-trade-price {
	display: inline;

	&.updated {
		animation: 1s linear updatedAnimation;
	}
}

@keyframes updatedAnimation {
	0% {
		color: inherit;
	}
	20% {
		color: var(--Blue-Blue-Light);
	}
	100% {
		color: inherit;
	}
}
