.table-block {
	margin-bottom: 40px;

	&--mb-0 {
		margin-bottom: 0;
	}

	.pagination-block {
		margin: 0;
		padding: 0 24px 20px 24px;
		justify-content: flex-end;
	}
}

